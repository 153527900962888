import {useTranslation} from "react-i18next";
import ConfirmationModal from "./ConfirmationModal";
import {useAuthentication} from "../Contexts/Authentication";
import {localDayTimeFromDateString} from "../Helpers/DateHelpers";
import {errorMessageFromServerError, formatUserName} from "../Helpers/Helpers";

const RemoveAppointmentModal = ({open, close, patient, appointment}) => {
  
  // Instance Variables
  
  const {t} = useTranslation();
  const {api, toastMessage} = useAuthentication();
  
  // Render
  
  return (
    <ConfirmationModal open={open}
                       save={() => api.deleteAppointment(appointment ? appointment.id : '').then((newResult) => {
                         toastMessage(t('DELETE_APPOINTMENT_SUCCESS', {aDate:localDayTimeFromDateString(appointment.startAt, false), aName:formatUserName(patient)}));
                         close();
                       }, (newError) => {
                         toastMessage(t('DELETE_APPOINTMENT_ERROR', {aError:errorMessageFromServerError(newError)}));
                       })}
                       close={close}
                       title={'DELETE_APPOINTMENT_TITLE'}
                       description={t('DELETE_APPOINTMENT_DESCRIPTION', {aDate:(appointment ? localDayTimeFromDateString(appointment.startAt, false): ''), aName:formatUserName(patient)})}>
    </ConfirmationModal>
  );
}

export default RemoveAppointmentModal
