import AsyncStorage from "@react-native-async-storage/async-storage";

export const localeKey = 'localeKey';
export const patientKey = 'patientKey';

export const getData = async (aKey) => {
  try{
    const jsonValue = await AsyncStorage.getItem(aKey);
    return jsonValue !== null ? JSON.parse(jsonValue) : null;
  }
  catch(newError){
    console.log('Authentication Provider (getData): Failed to read value for key:', aKey, 'with error:', newError, '.');
  }
}

export const getMultipleData = async (aKeysArray) => {
  let returnValue = [];
  
  if(Array.isArray(aKeysArray)){
    try{
      returnValue = await AsyncStorage.multiGet(aKeysArray);
    }
    catch(newError){
      console.log('Authentication Provider (getMultipleData): Failed to read value for keys in array:', aKeysArray, 'with error:', newError, '.');
    }
  }
  else{
    console.log('Authentication Provider (getMultipleData): The keys array:', aKeysArray, 'is not of type array.');
  }
  return returnValue;
}

export const storeData = async (aKey, aValue) => {
  try{
    await AsyncStorage.setItem(aKey, JSON.stringify(aValue));
  }
  catch(newError){
    console.log('Authentication Provider (storeData): Failed to store value:', aValue, 'for key:', aKey, 'with error:', newError, '.');
  }
}

export const storeMultipleData = async (aKeyValuePairsArray) => {
  if(Array.isArray(aKeyValuePairsArray)){
    let array = [];
  
    for(let keyValuePair of aKeyValuePairsArray){
      array.push([keyValuePair.key, JSON.stringify(keyValuePair.value)]);
    }
    try{
      await AsyncStorage.multiSet(array);
    }
    catch(newError){
      console.log('Authentication Provider (storeMultipleData): Failed to store key/value pairs array:', aKeyValuePairsArray, 'with error:', newError, '.');
    }
  }
  else{
    console.log('Authentication Provider (storeMultipleData): The key/value pairs array:', aKeyValuePairsArray, 'is not of type array.');
  }
}

export const deleteData = async (aKey) => {
  try{
    await AsyncStorage.removeItem(aKey);
  }
  catch(newError){
    console.log('Authentication Provider (deleteData): Failed to delete value for key:', aKey, 'with error:', newError, '.');
  }
}

export const deleteMultipleData = async (aKeysArray) => {
  if(Array.isArray(aKeysArray)){
    try{
      await AsyncStorage.multiRemove(aKeysArray);
    }
    catch(newError){
      console.log('Authentication Provider (deleteMultipleData): Failed to delete key/value pairs array:', aKeysArray, 'with error:', newError, '.');
    }
  }
  else{
    console.log('Authentication Provider (deleteMultipleData): The key/value pairs array:', aKeysArray, 'is not of type array.');
  }
}

// TODO: Make this helper work for both mobile and web.
// import React from 'react';
// import { NativeBaseProvider, StorageManager, ColorMode } from 'native-base';
// import AsyncStorage from '@react-native-async-storage/async-storage';
//
// // Define the colorModeManager,
// // here we are using react-native-async-storage (https://react-native-async-storage.github.io/async-storage/)
// const colorModeManager: StorageManager = {
//   get: async () => {
//     try {
//       let val = await AsyncStorage.getItem('@color-mode');
//       return val === 'dark' ? 'dark' : 'light';
//     } catch (e) {
//       return 'light';
//     }
//   },
//   set: async (value: ColorMode) => {
//     try {
//       await AsyncStorage.setItem('@color-mode', value);
//     } catch (e) {
//       console.log(e);
//     }
//   },
// };
// export default function () {
//   return (
//     // pass it to NativeBaseProvider
//     <NativeBaseProvider colorModeManager={colorModeManager}>
//       // Your components
//     </NativeBaseProvider>
//   );
// }
