import {FlatList, SafeAreaView, ScrollView, StyleSheet, Text, View} from "react-native";
import React, {useState} from "react";
import Shadow from "../Custom UI/Shadow";
import {Themes} from "../Constants/ThemeColours";
import {Divider} from 'native-base';
import {useRouter} from "expo-router";
import AppointmentCell from "../Cells/AppointmentCell";
import {useTranslation} from "react-i18next";
import AppointmentModal from "../Modals/AppointmentModal";
import {useAuthentication} from "../Contexts/Authentication";
import {appointmentsOnDate} from "../Helpers/AppointmentHelpers";
import RemoveAppointmentModal from "../Modals/RemoveAppointmentModal";
import ColouredButton from "../Custom UI/Buttons/ColouredButton";
import {formatUserName} from "../Helpers/Helpers";
import {localTimeFromDateString, localUTCDayFromDateString} from "../Helpers/DateHelpers";

const AppointmentsList = ({style, cancel, reload, clinicId, appointment, appointments, availability, selectedDate}) => {
  
  // Instance Variables
  
  const {t} = useTranslation();
  const router = useRouter();
  const {colorScheme, themedStyle, selectedPatient} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(appointment);
  
  const [menuItemsArray] = useState([
    {title:'PAY_FOR_APPOINTMENT', clickAction:(aAppointment) => {
        router.push({pathname:'/payment', params:{signedId:aAppointment.signedId}})
      }, isValid:(aAppointment) => (aAppointment && aAppointment.id && aAppointment.id.length > 0 && !aAppointment.isPaid)},
    {title:'EDIT_APPOINTMENT', clickAction:(aAppointment) => {
        setSelectedAppointment(aAppointment);
      }},
    {title:'CANCEL_APPOINTMENT', clickAction:(aAppointment) => {
        setAppointmentToDelete(aAppointment);
      }}
  ]);
  
  // Render
  
  return (
    <SafeAreaView style={[styles.container, style]}>
      {selectedAppointment ?
        <Shadow style={styles.shadow}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>
              {t('EDIT_APPOINTMENT')}
            </Text>
  
            <Divider bg={Themes[colorScheme].black}
                     width={125}
                     thickness="1"
                     orientation="horizontal"
            />
          </View>
          
          <View style={styles.info}>
            <Text style={styles.text}>
              {selectedAppointment.patient ? formatUserName(selectedAppointment.patient) : (selectedPatient ? formatUserName(selectedPatient) : null)}
            </Text>
  
            <Text style={styles.text}>
              {localUTCDayFromDateString(selectedAppointment.startAt)}: {localTimeFromDateString(selectedAppointment.startAt, false)} - {localTimeFromDateString(selectedAppointment.endAt, false)}
            </Text>
  
            <Text style={styles.text}>
              {t('EDIT_APPOINTMENT_MESSAGE')}
            </Text>
  
            <ColouredButton title={t('CANCEL')}
                            style={styles.button}
                            colour={Themes[colorScheme].primary}
                            onPress={() => {
                              // TODO: Finish all cases.
                              setSelectedAppointment(null);
                              cancel();
                            }}
                            textColour={Themes[colorScheme].text}
                            pressedColour={Themes[colorScheme].secondary}
                            highlightedColour={Themes[colorScheme].secondary}
                            accessibilityLabel={t('CANCEL_BUTTON_ACCESSIBILITY')}
            />
          </View>
        </Shadow>
        :
        <>
          <View>
            <Text style={styles.title}>
              {t('BOOKED_APPOINTMENTS') + (selectedDate && selectedDate.length > 0 ? (' (' + selectedDate + ')') : '')}
            </Text>
        
            <Divider bg={Themes[colorScheme].black}
                     thickness="1"
                     orientation="horizontal"
            />
          </View>
      
          <ScrollView style={styles.scrollView}>
            <FlatList data={appointmentsOnDate(appointments, selectedDate)}
                      renderItem={({item}) =>
                        <AppointmentCell appointment={item}
                                         menuItemsArray={menuItemsArray}
                        />}
                      keyExtractor={(aItem, aIndex) => {return 'appointment-cell-' + (aItem && aItem.id ? aItem.id : aIndex)}}
            />
          </ScrollView>
        </>
      }
  
      <AppointmentModal open={availability !== null}
                        close={() => {
                          setSelectedAppointment(null);
                          cancel();
                        }}
                        clinicId={clinicId}
                        appointment={selectedAppointment}
                        availability={availability}
      />
      
      <RemoveAppointmentModal open={appointmentToDelete !== null}
                              close={() => {
                                reload();
                                setAppointmentToDelete(null);
                              }}
                              patient={appointmentToDelete ? appointmentToDelete.patient : {}}
                              appointment={appointmentToDelete}
      />
    </SafeAreaView>
  );
}

export default AppointmentsList

const allStyles = StyleSheet.create({
  info:{
    padding:20
  },
  text:{
    fontWeight:400,
    marginBottom:10
  },
  title:{
    fontSize:15,
    textAlign:'center',
    paddingTop:20,
    fontWeight:600,
    paddingBottom:10,
  },
  button:{
    marginTop:10
  },
  container:{
    flex:1,
    alignItems:'center'
  },
  scrollView:{
    width:'100%',
    height:'100%'
  },
  titleContainer:{
    alignItems:'center'
  }
});
