import {StyleSheet, View} from "react-native";
import {Themes} from "../Constants/ThemeColours";
import {useAuthentication} from "../Contexts/Authentication";

// https://ethercreative.github.io/react-native-shadow-generator/

const Shadow = ({style, children, borderRadius, elevation = 24, shadowRadius = 50, shadowOpacity = 0.2}) => {
  
  // Instance Variables
  
  const {colorScheme, themedStyle} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  // Render
  
  return (
    <View style={[
      styles.container,
      {
        paddingTop:shadowRadius - (shadowRadius / 2),
        paddingLeft:shadowRadius,
        paddingRight:shadowRadius,
        paddingBottom:shadowRadius + (shadowRadius / 2)
      },
      style
    ]}>
      <View style={[styles.shadow,
        {
          elevation:elevation,
          borderRadius:(borderRadius !== null && borderRadius !== undefined) ? borderRadius : Themes.styles.borderRadius,
          shadowOffset:{width:0, height:(shadowRadius / 2)},
          shadowRadius:shadowRadius,
          shadowOpacity:shadowOpacity
        }]}>
        {children}
      </View>
    </View>
  );
}

export default Shadow

const allStyles = StyleSheet.create({
  shadow:{
    width:'100%',
    height:'100%',
    display:'block',
    elevation:24,
    shadowColor:Themes.light.shadow,
    borderRadius:Themes.styles.borderRadius,
    shadowOffset:{
      width:0,
      height:25
    },
    shadowRadius:50,
    shadowOpacity:0.2
  },
  container:{
    overflow:'hidden',
    paddingTop:25,
    paddingLeft:50,
    paddingRight:50,
    paddingBottom:75
  }
});
