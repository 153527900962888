import {Text, View, StyleSheet, TouchableOpacity, Dimensions} from 'react-native';
import {useEffect, useState} from 'react';
import Icon from "@expo/vector-icons/MaterialIcons";
import Shadow from "../Files/Custom UI/Shadow";
import {Themes} from "../Files/Constants/ThemeColours";
import {Calendar} from 'react-native-calendars';
import LabeledDropDown from "../Files/Custom UI/Inputs/LabeledDropDown";
import {useTranslation} from "react-i18next";
import AppointmentsList from "../Files/Lists/AppointmentsList";
import AvailabilitiesList from "../Files/Lists/AvailabilitiesList";
import {useAuthentication} from "../Files/Contexts/Authentication";
import {useLocalSearchParams, useRouter} from "expo-router";
import {months, currentDate, dateAfterDate, dateBeforeDate, addWorkDaysToDate, dateOnOrAfterDate, dateOnOrBeforeDate,
  addMinutesToDateString, localUTCDayFromDateString, secondsBetweenDateStrings, dayOfTheWeekFromDateString} from "../Files/Helpers/DateHelpers";

export default function Appointments() {
  
  // Instance Variables
  
  const {t} = useTranslation();
  const params = useLocalSearchParams();
  const {edit, signedId} = params;
  const router = useRouter();
  
  const {api, user, colorScheme, isLoggedIn, themedStyle, selectedPatient, clearSelectedPatient} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  const [maxDate] = useState(addWorkDaysToDate(currentDate(), 120));
  const [minDate] = useState(addWorkDaysToDate(currentDate(), 7));
  const [maxDateString] = useState(localUTCDayFromDateString(maxDate.toString()));
  const [minDateString] = useState(localUTCDayFromDateString(minDate.toString()));
  
  const [appointments, setAppointments] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);
  
  const [clinicId, setClinicId] = useState(null);
  const [clinicsArray, setClinicsArray] = useState([]);
  const [clinicOptionsArray, setClinicOptionsArray] = useState([]);
  
  const [yearOptions, setYearOptions] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);
  const [width, setWidth] = useState(Dimensions.get('window').width);
  const [height, setHeight] = useState(Dimensions.get('window').height);
  const [dayPressed, setDayPressed] = useState(false);
  const [currentYear, setCurrentYear] = useState(minDate.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(minDate.getUTCMonth() + 1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  
  useEffect(() => {
    let listOfYearOptions = [];
    
    for(let year = maxDate.getFullYear(); year >= currentDate().getFullYear(); year--){
      listOfYearOptions.push({label:year.toString(), value:year});
    }
    setYearOptions(listOfYearOptions);
    
    if(clinicsArray.length === 0 && isLoggedIn()){
      api.indexEndoretClinics().then((newResult) => {
        let newClinicsArray = newResult.data.objects;
        setClinicsArray(newClinicsArray);
        
        if(newClinicsArray && newClinicsArray.length > 0){
          setClinicId(newClinicsArray[0].id);
        }
      }, (newError) => {
      
      });
    }
  }, [user, clinicsArray]);
  
  const splitAvailabilities = (aAvailabilities) => {
    let returnValue = [];
    
    for(let availability of aAvailabilities){
      let availabilityGapInMinutes = secondsBetweenDateStrings(availability.startAt, availability.endAt) / 60;
      let date = new Date(availability.startAt);
      
      while(availabilityGapInMinutes > 0){
        let newAvailability = {...availability};
        newAvailability.startAt = date.toString();
        date = addMinutesToDateString(date, 30);
        newAvailability.endAt = date.toString();
        availabilityGapInMinutes -= 30;
        returnValue.push(newAvailability);
      }
    }
    return returnValue;
  }
  
  const reloadArrays = () => {
    let endDate = new Date(currentYear.toString() + '-' + currentMonth.toString() + '-31');
    let appointmentsStartDate = new Date(currentYear.toString() + '-' + currentMonth.toString() + '-01');
    let availabilitiesStartDate = new Date(currentYear.toString() + '-' + currentMonth.toString() + '-01');
    
    if(dateAfterDate(currentDate(), appointmentsStartDate)){
      appointmentsStartDate = currentDate();
    }
    if(dateAfterDate(minDate, availabilitiesStartDate)){
      availabilitiesStartDate = minDate;
    }
    if(dateBeforeDate(maxDate, endDate)){
      endDate = maxDate;
    }
    if(clinicId && isLoggedIn()){
      api.indexAvailabilities(clinicId, null, availabilitiesStartDate.toString(), endDate.toString()).then((newResult) => {
        setAvailabilities(splitAvailabilities(newResult.data.objects));
      }, (newError) => {
      
      });
      
      api.indexAppointments(100, 0, clinicId, appointmentsStartDate.toString(), endDate.toString()).then((newResult) => {
        setAppointments(newResult.data.objects);
      }, (newError) => {
    
      });
    }
    else if(signedId && signedId.length > 0){
      api.indexAvailabilities(clinicId, signedId, availabilitiesStartDate.toString(), endDate.toString()).then((newResult) => {
        setAvailabilities(splitAvailabilities(newResult.data.objects));
      }, (newError) => {
      
      });
    }
  };
  
  useEffect(() => {
    reloadArrays();
  }, [clinicId, currentYear, currentMonth]);
  
  useEffect(() => {
    let disabledMonthsArray = [];
  
    if(currentYear === maxDate.getFullYear()){
      for(let month = 0; month < 12; month++){
        if(month > maxDate.getMonth()){
          disabledMonthsArray.push(month);
        }
      }
    }
    if(currentYear === currentDate().getFullYear()){
      for(let month = 0; month < 12; month++){
        if(month < currentDate().getMonth()){
          disabledMonthsArray.push(month);
        }
      }
    }
  
    let monthOptionsArray = [];
    
    for(let index = 0; index < months.length; index += 1){
      let month = months[index];
      monthOptionsArray.push({label:t(month), value:(index + 1), disabled:disabledMonthsArray.includes(index)});
    }
  
    let month = currentMonth;
    
    if(currentYear === maxDate.getFullYear()){
      if(currentMonth >= disabledMonthsArray[0] + 1){
        month = disabledMonthsArray[0];
      }
    }
    else if(currentYear === minDate.getFullYear()){
      let lastMonthIndex = disabledMonthsArray.length - 1;
    
      if(currentMonth <= disabledMonthsArray[lastMonthIndex] + 1){
        month = disabledMonthsArray[lastMonthIndex] + 2;
      }
    }
    setCurrentMonth(month);
    setMonthOptions(monthOptionsArray);
  }, [currentYear, setCurrentMonth, setMonthOptions]);
  
  useEffect(() => {
    let returnValue = [];
    
    for(let endoretClinic of clinicsArray){
      returnValue.push({label:endoretClinic.name, value:endoretClinic.id});
    }
    setClinicOptionsArray(returnValue);
  }, [clinicsArray, setClinicOptionsArray]);
  
  useEffect(() => {
    let returnValue = [];
    
    for(let endoretClinic of clinicsArray){
      returnValue.push({label:endoretClinic.name, value:endoretClinic.id});
    }
    setClinicOptionsArray(returnValue);
  }, [clinicId, clinicsArray, setClinicOptionsArray]);
  
  const getAppointments = () => {
    let returnValue = {};
    
    for(let availability of availabilities){
      let startAt = localUTCDayFromDateString(availability.startAt);
      
      if(returnValue[startAt]){
        if(returnValue[startAt]['dots'].length < 5){
          returnValue[startAt]['dots'].push({key:'availability-' + availability.startAt, color:'green', selectedDotColor:'green'});
        }
      }
      else{
        returnValue[startAt] = {dots:[{key:'availability-' + availability.startAt, color:'green', selectedDotColor:'green'}]};
      }
    }
    for(let appointment of appointments){
      let startAt = localUTCDayFromDateString(appointment.startAt);

      if(returnValue[startAt]){
        if(returnValue[startAt]['dots'].length < 10){
          returnValue[startAt]['dots'].push({key:'appointment-' + appointment.id, color:'red', selectedDotColor:'red'});
        }
      }
      else{
        returnValue[startAt] = {dots:[{key:'appointment-' + appointment.id, color:'red', selectedDotColor:'red'}]};
      }
    }
    if(selectedDate){
      if(returnValue[selectedDate]){
        returnValue[selectedDate]['selected'] = true;
        returnValue[selectedDate]['selectedColor'] = Themes[colorScheme].primary;
      }
      else{
        returnValue[selectedDate] = {selected:true, selectedColor:Themes[colorScheme].primary};
      }
    }
    return returnValue;
  }
  
  const offsetCurrentMonth = (aMonthOffset) => {
    let date = new Date(currentYear, (currentMonth - 1) + aMonthOffset + 1, 0);
    
    if(aMonthOffset > 0){
      date = new Date(currentYear, (currentMonth - 1) + aMonthOffset, 1);
    }
    if(dateOnOrBeforeDate(date, maxDate) && dateOnOrAfterDate(date, currentDate())){
      setSelectedDate(null);
      setCurrentYear(date.getFullYear());
      setCurrentMonth(date.getMonth() + 1);
    }
  }
  
  const canOffsetCurrentMonth = (aMonthOffset) => {
    let date = new Date(currentYear, (currentMonth - 1) + aMonthOffset + 1, 0);
    
    if(aMonthOffset > 0){
      date = new Date(currentYear, (currentMonth - 1) + aMonthOffset, 1);
    }
    return (dateOnOrBeforeDate(date, maxDate) && dateOnOrAfterDate(date, currentDate()));
  }
  
  const onDayPress = (date) => {
    const day = dayOfTheWeekFromDateString(date.dateString);
    
    if(day !== 'Sunday' && day !== 'Saturday'){
      if(dayPressed && selectedDate === date.dateString){
        setDayPressed(false);
        setSelectedDate(null);
      }
      else{
        setDayPressed(true);
        setSelectedDate(date.dateString);
      }
    }
  };

  const formatMonthToString = (month) => {
    return ('0' + month.toString()).slice(-2);
  }

  // Render
  
  return (
    <View style={styles.container}
          onLayout={() => {
            setWidth(Dimensions.get('window').width);
            setHeight(Dimensions.get('window').height);
          }}>
      <View style={styles.calendarContainer}>
        <View style={styles.calendar}>
          <Shadow>
            <Calendar style={{width:width - 100, height:375, borderRadius:Themes.styles.borderRadius, backgroundColor:Themes[colorScheme].white}}
                      theme={{calendarBackground:'#00000000', dotStyle:{width:7, height:7, borderRadius:3.5}}}
                      maxDate={maxDateString}
                      minDate={minDateString}
                      renderArrow={(aDirection) =>
                        aDirection === 'left' && canOffsetCurrentMonth(-1) ?
                          <Icon name={'arrow-back-ios'}
                                size={25}
                          />
                          :
                          aDirection === 'right' && canOffsetCurrentMonth(1) ?
                            <Icon name={'arrow-forward-ios'}
                                  size={25}
                            />
                            :
                            null
                      }
                      onDayPress={(aDate) => onDayPress(aDate)}
                      initialDate={currentYear + '-' + formatMonthToString(currentMonth) + '-01'}
                      markingType={'multi-dot'}
                      markedDates={getAppointments()}
                      onPressArrowLeft={() => offsetCurrentMonth(-1)}
                      onPressArrowRight={() => offsetCurrentMonth(1)}
                      customHeaderTitle={
                        <TouchableOpacity style={styles.dropdownsContainer}>
                          {isLoggedIn() && clinicOptionsArray.length > 0 ?
                            <LabeledDropDown style={{width:200, marginRight:20, marginBottom:0}}
                                             value={clinicId}
                                             change={(aValue) => setClinicId(aValue)}
                                             optionsArray={clinicOptionsArray}
                            />
                            :
                            null
                          }
                          
                          <LabeledDropDown style={{width:60, marginBottom:0}}
                                           value={currentMonth}
                                           change={(aValue) => setCurrentMonth(Number(aValue))}
                                           optionsArray={monthOptions}
                          />
                
                          <Text style={styles.hyphen}>
                            -
                          </Text>
                
                          <LabeledDropDown style={{width:100, marginBottom:0}}
                                           value={currentYear}
                                           change={(aValue) => setCurrentYear(Number(aValue))}
                                           optionsArray={yearOptions}
                          />
                        </TouchableOpacity>}
            />
          </Shadow>
        </View>
        
        <View style={[styles.listsContainer, {height:height - 430 - Themes.styles.navBarHeight}]}>
          <AvailabilitiesList style={(isLoggedIn() ? {width:'50%'} : {width:'100%', marginLeft:15, marginRight:15})}
                              select={(aAvailability) => setSelectedAvailability(aAvailability)}
                              selectedDate={selectedDate}
                              availabilities={availabilities}
          />
          
          {isLoggedIn() ?
            <AppointmentsList style={{width:'50%'}}
                              cancel={() => {
                                if(edit === 'true'){
                                  clearSelectedPatient();
                                  router.push({pathname:'/patients'});
                                }
                                setSelectedAvailability(null);
                              }}
                              reload={reloadArrays}
                              clinicId={clinicId}
                              appointment={edit && selectedPatient ? selectedPatient.nextAppointment : null}
                              appointments={appointments}
                              availability={selectedAvailability}
                              selectedDate={selectedDate}
            />
            :
            null
          }
        </View>
      </View>
    </View>
  );
}

const allStyles = StyleSheet.create({
  hyphen:{
    color:Themes.light.black,
    fontSize:16,
    fontWeight:'bold',
    marginLeft:10,
    marginRight:10
  },
  calendar:{
    height:400
  },
  container:{
    flex:1,
    backgroundColor:Themes.light.white
  },
  listsContainer:{
    overflow:'hidden',
    paddingTop:10,
    paddingLeft:30,
    paddingRight:30,
    flexDirection:'row'
  },
  calendarContainer:{
    width:'100%',
    height:'100%',
    overflow:'hidden'
  },
  dropdownsContainer:{
    width:500,
    padding:10,
    alignItems:'center',
    flexDirection:'row',
    justifyContent:'center'
  }
});
