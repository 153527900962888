import {SafeAreaView, StyleSheet, useColorScheme} from "react-native";
import Label from "../Label";
import Errors from "../Errors";
import {Themes} from "../../Constants/ThemeColours";
import IconButton from "../Buttons/IconButton";
import {CheckIcon, Select} from "native-base";

const LabeledDropDown = ({error, label, value, change, warning, inputRef, placeholder, addButtonClicked,
                           accessibilityLabel, style = {}, optionsArray = []}) => {
  
  // Instance Variables
  
  const colorScheme = useColorScheme();
  
  // Methods
  
  
  
  // Render
  
  return (
    <SafeAreaView style={[styles.container, {width:'100%'}, style]}
                  accessibilityLabel={accessibilityLabel}>
      <Label style={[styles.inputLabel]}
             label={label}
      />
      <Select style={styles.labeledDropdownSelect}
              ref={inputRef}
              _selectedItem={{bg:"teal.600", endIcon:<CheckIcon size="10px"/>}}
              _customDropdownIconProps={{size:3}}
              mt={1}
              height="38.5"
              minWidth="100"
              borderColor={Themes[colorScheme].black}
              placeholder={placeholder}
              borderRadius={7}
              onValueChange={(itemValue) => change(itemValue)}
              selectedValue={value}
              accessibilityLabel={accessibilityLabel}
              customDropdownIconProps={{height:10, width:10}}>
      {optionsArray.map((aOption) => (
          <Select.Item key={aOption.value}
                       label={aOption.label}
                       value={aOption.value}
                       isDisabled={aOption.disabled}
          />
        ))}
      </Select>
  
      {addButtonClicked ?
        <IconButton name={'add-circle-outline'}
                    size={30}
                    style={styles.labeledInputAddButton}
                    colour={Themes[colorScheme].primary}
                    buttonClicked={addButtonClicked}
        />
        :
        null
      }
      
      <Errors error={error}
              warning={warning}
      />
    </SafeAreaView>
  );
}

export default LabeledDropDown

const styles = StyleSheet.create({
  container:{
    flex:1,
    padding:24,
    marginBottom:20
  },
  labeledDropdownSelect:{
    flex:1,
    width:'100%',
    alignItems:'center',
    borderRadius:7
  }
});
