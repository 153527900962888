import React from "react";
import axios from "axios";
import {camelizeKeys, decamelizeKeys} from "humps";

export function customAxiosInstance(aError){
  let customAxios = axios.create();
  customAxios.interceptors.request.use((config) => {
    const newConfig = {...config};
    
    if(config.params){
      newConfig.params = decamelizeKeys(config.params);
    }
    if(config.data){
      if(typeof config.data === 'string' || config.data instanceof String){
        newConfig.data = decamelizeKeys(JSON.parse(config.data));
      }
      else{
        newConfig.data = decamelizeKeys(config.data);
      }
    }
    return newConfig;
  });
  
  customAxios.interceptors.response.use((response) => {
    if(response.data && response.headers['content-type'].startsWith('application/json')){
      response.data = camelizeKeys(response.data);
    }
    return response;
  }, (newError) => {
    if(newError && newError.response && newError.response.status === 401 && aError){
      aError(newError);
    }
    return Promise.reject(newError.response);
  });
  return customAxios;
}
