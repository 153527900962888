import {useState} from "react";
import GeneralModal from "./GeneralModal";
import LabeledTextArea from "../Custom UI/Inputs/LabeledTextArea";
import {useTranslation} from "react-i18next";
import {useAuthentication} from "../Contexts/Authentication";

const ContactModal = ({open, close}) => {
  
  // Instance Variables
  
  const {t} = useTranslation();
  const {api, toastMessage} = useAuthentication();
  const [message, setMessage] = useState('');
  
  // Render
  
  return (
    <GeneralModal open={open}
                  save={() => {
                    api.createAdminMessage({text:message}).then((newResult) => {
                      toastMessage(t('MESSAGE_SENT'));
                      close();
                      setMessage('');
                    }, (newError) => {
                      toastMessage(t('MESSAGE_SEND_ERROR', {aError:newError}));
                    });
                  }}
                  close={close}
                  title={'CONTACT'}
                  saveButtonTitle={'SEND'}>
      <LabeledTextArea text={message}
                       change={setMessage}
                       marginBottom={0}
                       placeholder={t('ENTER_MESSAGE_HERE')}
      />
    </GeneralModal>
  );
}

export default ContactModal
