import {Platform, SafeAreaView, StyleSheet, View} from "react-native";
import "./LabeledDatePicker.css"
import Label from "../Label";
import Errors from "../Errors";
import {Themes} from "../../Constants/ThemeColours";
import DatePicker from 'react-datepicker';
import IconButton from "../Buttons/IconButton";
import {setHours, setMinutes} from "date-fns";

// React Native Mobile Date Picker:
//
//   https://reactdatepicker.com/
//   https://github.com/mmazzarolo/react-native-modal-datetime-picker
//   https://github.com/react-native-datetimepicker/datetimepicker/issues/137

const LabeledDatePicker = ({date, error, label, change, maxDate, minDate, warning, inputRef, placeholder, timeCaption,
                             addButtonClicked, maxTime = setHours(setMinutes(new Date(), 59), 23),
                             minTime = setHours(setMinutes(new Date(), 0), 0), disabled = false,
                             dateFormat = "yyyy/MM/dd", marginTop = 0, isClearable = true,
                             marginBottom = 20, popperPlacement = 'top', showTimeSelect = false,
                             showPopperArrow = true, showYearDropdown = true, showMonthDropdown = true,
                             accessibilityLabel = 'Date Picker'}) => {
  
  // Render
  
  return (
    <SafeAreaView style={{width:'100%'}}
                  accessibilityLabel={accessibilityLabel}>
      <Label style={[styles.inputLabel, {marginTop:marginTop}]}
             label={label}
      />
      
      <View style={[styles.labeledInputContainer, {marginBottom:marginBottom, zIndex:1}]}>
        {Platform.OS === 'web' ?
          <DatePicker className="labeled-datepicker"
                      ref={inputRef}
                      maxDate={maxDate}
                      maxTime={maxTime}
                      minDate={minDate}
                      minTime={minTime}
                      disabled={disabled}
                      onChange={(aDate) => change(aDate)}
                      selected={date}
                      dateFormat={dateFormat}
                      timeFormat={showTimeSelect ? "HH:mm" : null}
                      isClearable={disabled ? false : isClearable}
                      timeCaption={timeCaption}
                      dropdownMode="select"
                      showTimeSelect={showTimeSelect}
                      placeholderText={placeholder}
                      popperPlacement={popperPlacement}
                      showPopperArrow={showPopperArrow}
                      showYearDropdown={showYearDropdown}
                      showMonthDropdown={showMonthDropdown}
          />
          :
          null
        }
        
        {addButtonClicked ?
          <IconButton name={'add-circle-outline'}
                      size={30}
                      style={styles.labeledInputAddButton}
                      colour={Themes.light.primary}
                      buttonClicked={addButtonClicked}
          />
          :
          null
        }
      </View>
      
      <Errors error={error}
              warning={warning}
      />
    </SafeAreaView>
  );
}

export default LabeledDatePicker

const styles = StyleSheet.create({
  input:{
    flex:1,
    width:'100%',
    border:'1px solid #a8a8a8',
    padding:10,
    alignItems:'center',
    borderRadius:Themes.styles.borderRadius,
  },
  inputLabel:{
    paddingBottom:7
  },
  inputDisabled:{
    backgroundColor:'rgba(235,235,235,1)'
  },
  labeledInputContainer:{
    width:'100%',
    display:'flex',
    flexDirection:'row'
  },
  labeledInputAddButton:{
    marginTop:'7px'
  }
});
