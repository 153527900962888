import GeneralModal from "./GeneralModal";
import {useTranslation} from "react-i18next";

const ConfirmationModal = ({open, save, close, title = 'CONFIRM', description}) => {
  
  // Instance Variables
  
  const {t} = useTranslation();
  
  // Render
  
  return (
    <GeneralModal open={open}
                  save={save}
                  close={close}
                  title={title}
                  description={description ? description : t('CONFIRM_DESCRIPTION')}
                  saveButtonTitle={'CONFIRM'}>
    </GeneralModal>
  );
}

export default ConfirmationModal
