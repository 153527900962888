{
  "ACCEPT":"Accepter",
  "ACCEPT_TERMS_ERROR":"Échec de l'acceptation des conditions avec l'erreur: {{aError}}.",
  "ADDRESS_LINE_1":"Adresse 1",
  "ADDRESS_LINE_2":"Adresse 2",
  "ALLERGIES":"Allergies",
  "APPOINTMENT":"Rendez-vous",
  "APPOINTMENT_ACTIONS":"Actions de rendez-vous",
  "APPOINTMENTS":"Rendez-vous",
  "APPOINTMENT_CREATED":"Rendez-vous créé avec succès!",
  "APPOINTMENT_UPDATED":"Rendez-vous mis à jour avec succès!",
  "APPOINTMENT_CREATED_ERROR":"Échec de la création du rendez-vous avec l'erreur: {{aError}}.",
  "APPOINTMENT_UPDATED_ERROR":"Échec de la mise à jour du rendez-vous avec l'erreur: {{aError}}.",
  "AVAILABLE_TIMES":"Horaires disponibles",
  "BACK":"Dos",
  "BOOKED_APPOINTMENTS":"Rendez-vous réservés",
  "CANCEL":"Annuler",
  "CANCEL_APPOINTMENT":"Annuler rendez-vous",
  "CHANGE":"Changement",
  "CHANGE_PASSWORD":"Changer le mot de passe",
  "CHANGE_PASSWORD_BUTTON_ACCESSIBILITY":"Bouton Changer le mot de passe",
  "CITY":"Ville",
  "CLINIC":"Clinique",
  "CONFIRM":"Confirmer",
  "CONFIRM_DESCRIPTION":"Êtes-vous sur de vouloir continuer?",
  "CONFIRM_EMAIL":"Confirmez votre email",
  "CONFIRM_EMAIL_BUTTON_ACCESSIBILITY":"Bouton Confirmer l'e-mail",
  "CONFIRM_NEW_PASSWORD":"Confirmer le nouveau mot de passe",
  "CONTACT":"Contact",
  "CONTACT_DESCRIPTION":"Connectez-vous avec Labtician - Demander des rendez-vous supplémentaires pour une clinique particulière. Signaler un problème avec le réseau ECP. Fournissez une suggestion d'amélioration. Ou n'importe quoi d'autre!",
  "CONTACT_EMAIL":"Email du contact",
  "CONTACT_US_BUTTON_ACCESSIBILITY":"Bouton Contactez-nous",
  "COPYRIGHT":"© Endoret {{aYear}}",
  "COUNTRY":"Pays",
  "CREATE":"Créer",
  "CREATE_APPOINTMENT":"Créer un rendez-vous",
  "CURRENT_PASSWORD":"Mot de passe actuel",
  "CURRENT_PASSWORD_DOES_NOT_MATCH_ERROR":"Votre mot de passe actuel ne correspond pas au mot de passe de confirmation.",
  "DATE":"Date",
  "DATE_OF_BIRTH":"Date de naissance",
  "DECLINE":"Déclin",
  "DELETE_APPOINTMENT":"Supprimer le rendez-vous",
  "DELETE_APPOINTMENT_DESCRIPTION":"Voulez-vous vraiment supprimer le rendez-vous du {{aDate}} pour {{aName}}?",
  "DELETE_APPOINTMENT_ERROR":"Échec de la suppression du rendez-vous avec l'erreur: {{aError}}.",
  "DELETE_APPOINTMENT_SUCCESS":"Le rendez-vous du {{aDate}} pour {{aName}} a été supprimé avec succès!",
  "DELETE_APPOINTMENT_TITLE":"Supprimer le rendez-vous?",
  "DELETE_PATIENT":"Supprimer le patient",
  "DELETE_PATIENT_DESCRIPTION":"Voulez-vous vraiment supprimer le patient {{aName}}?",
  "DELETE_PATIENT_ERROR":"Échec de la suppression de {{aName}} avec l'erreur: {{aError}}.",
  "DELETE_PATIENT_SUCCESS":"{{aName}} a été supprimé avec succès!",
  "DELETE_PATIENT_TITLE":"Supprimer le patient?",
  "DOES_NOT_IDENTIFY":"Ne s'identifie pas",
  "EDIT":"Modifier",
  "EDIT_APPOINTMENT":"Modifier le rendez-vous",
  "EDIT_APPOINTMENT_MESSAGE":"Sélectionnez une autre date de rendez-vous parmi les options disponibles.",
  "EDIT_PATIENT":"Modifier le patient",
  "EMAIL":"E-mail",
  "EMAIL_ADMIN":"Administrateur de messagerie",
  "EMAIL_CONFIRMATION_ERROR":"Échec de la confirmation de votre adresse e-mail avec l'erreur: {{aError}}.",
  "EMAIL_CONFIRMED":"Votre adresse e-mail a été confirmée avec succès!",
  "EMAIL_SENT":"Email envoyé!",
  "END_AT":"Fin à",
  "ENDORET_CLINIC":"Clinique d'Endoret",
  "ENGLISH":"Anglais",
  "ENTER_MESSAGE_HERE":"Entrez le message ici",
  "FAX_NUMBER":"Numéro de fax",
  "FEMALE":"Femme",
  "FIRST_NAME":"Prénom",
  "FOR_PATIENT":"Pour le patient: {{aName}}",
  "FORGOT_PASSWORD":"Mot de passe oublié",
  "FORGOT_PASSWORD_BUTTON_ACCESSIBILITY":"Bouton Envoyer un e-mail de mot de passe oublié",
  "FORGOT_PASSWORD_EMAIL_SEND_ERROR":"Failed to send email with error: {{aError}}.",
  "FORGOT_PASSWORD_EMAIL_SENT":"Un e-mail a été envoyé à l'adresse ci-dessus. Veuillez suivre les instructions pour réinitialiser votre mot de passe.",
  "FRENCH":"Français",
  "GENDER":"Genre",
  "INVALID_EMAIL":"Adresse e-mail invalide.",
  "INVALID_EMAIL_AND_PASSWORD":"E-mail et mot de passe invalides.",
  "INVALID_PASSWORD":"Mot de passe incorrect.",
  "INVALID_PASSWORD_ERROR":"Votre mot de passe doit comporter au moins 8 caractères et contenir au moins 3 des éléments suivants : 1 majuscule (A-Z), 1 minuscule (a-z), 1 chiffre (0-9) et/ou 1 symbole (par exemple : !, $, #, ou %).",
  "INVALID_PHONE_NUMBER":"Numéro de téléphone invalide.",
  "IS_AUTO_IMMUNE_DISEASE":"Est une maladie auto-immune",
  "IS_BLOOD_DRAW":"Prélèvement sanguin",
  "IS_REPEAT_BLOOD_DRAW":"Répétition du prélèvement sanguin",
  "IS_THERMAL_TREATMENT":"Traitement thermique",
  "IS_USING_ANTI_COAGULANTS":"Prend un anticoagulant",
  "IS_USING_PRIVATE_INSURANCE":"Détient une assurance privée",
  "LAST_NAME":"Nom de famille",
  "LICENSE_NUMBER":"Numéro de licence",
  "LOGIN":"Connexion",
  "LOGIN_BUTTON_ACCESSIBILITY":"Bouton de connexion",
  "LOGOUT":"Se déconnecter",
  "LOGOUT_MESSAGE":"Vous êtes déconnecté.",
  "LOGOUT_SESSION_MESSAGE":"Votre session a expiré. Veuillez vous reconnecter.",
  "MALE":"Mâle",
  "MESSAGE_SEND_ERROR":"Échec de l'envoi du message avec l'erreur: {{aError}}.",
  "MESSAGE_SENT":"Message envoyé! Nous vous répondrons bientôt.",
  "NAME":"Nom",
  "NEW_APPOINTMENT":"Nouveau rendez-vous",
  "NEW_PASSWORD":"Nouveau mot de passe",
  "NEW_PASSWORD_SAME_AS_OLD_PASSWORD_ERROR":"Votre nouveau mot de passe est le même que votre mot de passe actuel.",
  "NEW_PATIENT":"Nouveau patient",
  "NEW_PATIENT_BUTTON_ACCESSIBILITY":"Nouveau patient",
  "NEW_START_AT":"Nouveau départ à",
  "NUMBER_OF_BLOOD_DRAW_REFILLS":"Nombre de prélèvements sanguins à effectuer",
  "ORIGINAL_START_AT":"Début d'origine à",
  "PAID":"Payé",
  "PASSWORD":"Mot de passe",
  "PASSWORD_CHANGE_REQUIRED":"Vous devez changer votre mot de passe.",
  "PASSWORD_CHANGED":"Votre mot de passe a été changé avec succès!",
  "PASSWORD_CHANGED_ERROR":"Échec de la modification de votre mot de passe avec l'erreur: {{aError}}.",
  "PASSWORD_RESET":"Votre mot de passe a été réinitialisé!",
  "PASSWORD_RESET_ERROR":"Échec de la réinitialisation de votre mot de passe avec l'erreur: {{aError}}.",
  "PATIENT":"Patient",
  "PATIENT_ACTIONS":"Gestes du patient",
  "PATIENTS":"Les patients",
  "PATIENT_CREATE_ERROR":"Échec de la création du patient avec l'erreur: {{aError}}.",
  "PATIENT_CREATED":"Le patient {{aName}} a été créé avec succès!",
  "PATIENT_ID":"ID du patient",
  "PATIENT_PROFILE":"Profil du patient",
  "PATIENT_UPDATE_BUTTON_ACCESSIBILITY":"Mettre à jour le patient",
  "PATIENT_UPDATE_ERROR":"Échec de la mise à jour du patient avec l'erreur: {{aError}}.",
  "PATIENT_UPDATED":"Le patient {{aName}} a été mis à jour avec succès!",
  "PAY_FOR_APPOINTMENT":"Payer pour un rendez-vous",
  "PAYMENT":"Paiement",
  "PAYMENT_CANCELLED":"Paiement annulé. Veuillez recharger la page pour recommencer.",
  "PAYMENT_COMPLETED":"Merci pour votre paiement.\nVeuillez cliquer sur le lien ci-dessous pour accéder à plus d'informations sur la PRGF.",
  "PAYMENT_ERROR":"Le paiement a échoué avec l'erreur: {{aError}}.",
  "PAYMENT_FAILED":"Paiement échoué. Veuillez recharger la page pour recommencer.",
  "PAYMENT_SIGNED_ID_ERROR":"Achat introuvable. Veuillez contacter votre administrateur Endoret.",
  "PHONE_NUMBER":"Numéro de téléphone",
  "POSTAL_CODE":"Code Postal",
  "PREFERRED_LANGUAGE":"Langue préférée",
  "PRGF":"PLASMA RICHE EN FACTEURS DE CROISSANCE",
  "PROFILE":"Profil",
  "PROFILE_UPDATE_BUTTON_ACCESSIBILITY":"Mettre à jour le profil",
  "PROVINCE":"Province",
  "REFERRING_PHYSICIAN":"Médecin référent",
  "REFILLS_REMAINING":"Recharges restantes",
  "RESET":"Réinitialiser",
  "RESET_PASSWORD":"Réinitialiser le mot de passe",
  "RESET_PASSWORD_BUTTON_ACCESSIBILITY":"Bouton Réinitialiser le mot de passe",
  "SAVE":"Sauvegarder",
  "SEARCH":"Recherche",
  "SECONDARY_EMAIL":"Email secondaire",
  "SEND":"Envoyer",
  "SENT":"Envoyé",
  "SIGNATURE":"Signature",
  "START_AT":"Date et heure",
  "THERMAL_TREATMENT":"Traitement Thermique",
  "THERMAL_TREATMENT_AVAILABLE":"Le traitement thermique est disponible.",
  "TOTAL":"Total",
  "UPCOMING_APPOINTMENT":"Rendez-vous à venir",
  "UPDATE":"Réviser"
}
