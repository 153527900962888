import {StyleSheet, Text, View} from "react-native";
import {useEffect, useState} from "react";
import Shadow from "../Files/Custom UI/Shadow";
import {Themes} from "../Files/Constants/ThemeColours";
import {useRouter} from "expo-router";
import LabeledInput from "../Files/Custom UI/Inputs/LabeledInput";
import ColouredButton from "../Files/Custom UI/Buttons/ColouredButton";
import {useTranslation} from "react-i18next";
import LabeledPhoneNumber from "../Files/Custom UI/Inputs/LabeledPhoneNumber";
import {useAuthentication} from "../Files/Contexts/Authentication";

export default function Profile(){
  
  // Instance Variables
  
  const {t} = useTranslation();
  const router = useRouter();
  const {user, colorScheme, themedStyle, updateUser, reloadUserFromServer} = useAuthentication();
  
  const styles = themedStyle(allStyles);
  
  const emptyUser = {email:'', faxNumber:'', firstName:'', lastName:'', phoneNumber:'', physicianLicenseNumber:''};
  
  const [newUser, setNewUser] = useState(user ? user : emptyUser);
  
  // Effects
  
  useEffect(() => {
    reloadUserFromServer();
  }, []);
  
  useEffect(() => {
    if(user && (!newUser || user.id !== newUser.id)){
      setNewUser({...user});
    }
  }, [user, newUser, setNewUser]);
  
  // Render
  
  return (
    <View style={styles.container}>
      <Shadow>
        <View style={styles.main}>
          <Text style={styles.title}>
            {t('PROFILE')}
          </Text>
          
          <LabeledInput text={newUser.firstName}
                        label={t('FIRST_NAME')}
                        change={(aText) => setNewUser({...newUser, firstName:aText})}
                        placeholder={t('FIRST_NAME')}
          />
          
          <LabeledInput text={newUser.lastName}
                        label={t('LAST_NAME')}
                        change={(aText) => setNewUser({...newUser, lastName:aText})}
                        placeholder={t('LAST_NAME')}
          />
          
          <LabeledInput text={newUser.email}
                        label={t('EMAIL')}
                        disabled={true}
                        placeholder={t('EMAIL')}
                        keyboardType={'email-address'}
          />
          
          <LabeledPhoneNumber text={newUser.phoneNumber}
                              label={t('PHONE_NUMBER')}
                              change={(aText) => setNewUser({...newUser, phoneNumber:aText})}
                              placeholder={t('PHONE_NUMBER')}
          />
  
          <LabeledPhoneNumber text={newUser.faxNumber}
                              label={t('FAX_NUMBER')}
                              change={(aText) => setNewUser({...newUser, newUser:aText})}
                              placeholder={t('FAX_NUMBER')}
          />
  
          <LabeledInput text={newUser.physicianLicenseNumber}
                        label={t('LICENSE_NUMBER')}
                        change={(aText) => setNewUser({...newUser, physicianLicenseNumber:aText})}
                        placeholder={t('LICENSE_NUMBER')}
          />
          
          <ColouredButton title={t('UPDATE')}
                          colour={Themes[colorScheme].primary}
                          onPress={() => updateUser({user:newUser}).then((newResult) => {
                            router.push({pathname:'/patients'});
                          }, (newError) => {
                            // TODO: Add toast.
                          })}
                          textColour={Themes[colorScheme].text}
                          pressedColour={Themes[colorScheme].secondary}
                          highlightedColour={Themes[colorScheme].secondary}
                          accessibilityLabel={t('PROFILE_UPDATE_BUTTON_ACCESSIBILITY')}
          />
        </View>
      </Shadow>
    </View>
  );
}

const allStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 24,
    backgroundColor: 'white'
  },
  main: {
    width:800,
    padding:40,
    maxWidth:960,
    borderRadius:10,
    justifyContent:'center',
    backgroundColor:'white',
    marginHorizontal:'auto'
  },
  title:{
    fontSize:32,
    textAlign:'center',
    fontWeight:'bold',
    paddingBottom:20
  }
});
