import "expo-router/entry";
import './i18n.config'
import "react-datepicker/dist/react-datepicker.css";
import * as Sentry from 'sentry-expo';

Sentry.init({
  dsn: 'https://6ec446624cd1412c5e27b3f06c05a578@o4505308449079296.ingest.sentry.io/4505778332041216',
  enableInExpoDevelopment: false,
  debug: false,
  release: "1.0.0"
});
