import {StyleSheet, View, Text, Pressable, TouchableOpacity} from "react-native";
import React, {useState} from "react";
import Logo from "../../Files/Assets/Images/Light/Coverdale_Clinics.jpg";
import {Themes} from "../Constants/ThemeColours";
import LogoFrench from "./../Assets/Images/Light/Coverdale_Clinics_Fr.png";
import {useRouter} from "expo-router";
import GeneralPopover from "../Custom UI/GeneralPopover";
import {Avatar, Image} from "native-base";
import {useTranslation} from "react-i18next";
import {useAuthentication} from "../Contexts/Authentication";

const NavBar = () => {

  // Instance Variables

  const {t, i18n} = useTranslation();
  const router = useRouter();
  const {user, signOut, isLoggedIn, themedStyle} = useAuthentication();
  const styles = themedStyle(allStyles);

  const [open, setOpen] = useState(false);

  // Render

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => {
        if(isLoggedIn()){
          router.push({pathname:'/patients'});
        }
        else{
          router.push({pathname:'/'});
        }
      }}>
        <Image style={{width:200, height:64, display:(i18n.language === 'en' ? 'block' : 'none'), visibility:(i18n.language === 'en' ? 'visible' : 'hidden')}}
               source={Logo}
        />
  
        <Image style={{width:190, height:64, display:(i18n.language === 'en' ? 'none' : 'block'), visibility:(i18n.language === 'en' ? 'hidden' : 'visible')}}
               source={LogoFrench}
        />
  
        {/*TODO: Re-enable once the logo's are changes to SVG's.*/}
        {/*{i18n.language === 'en' ?*/}
        {/*  <Logo width={150}*/}
        {/*        height={64}*/}
        {/*  />*/}
        {/*  :*/}
        {/*  <LogoFrench width={150}*/}
        {/*              height={64}*/}
        {/*  />*/}
        {/*}*/}
      </TouchableOpacity>

      {isLoggedIn() ?
        <View style={styles.popoverContainer}>
          <GeneralPopover open={open}
                          close={() => setOpen(false)}
                          width={120}
                          anchorElement={
                            <TouchableOpacity style={{marginLeft:'auto', marginRight:5}}>
                              <Pressable onPress={() => setOpen(true)}>
                                <Avatar source={{uri:user.clinic.logoUrl}}/>
                              </Pressable>
                            </TouchableOpacity>}>
            <TouchableOpacity>
              <Text style={styles.link}
                    onPress={() => {
                      router.push({pathname:'/patients'});
                      setOpen(false);
                    }}>
                {t('PATIENTS')}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity>
              <Text style={styles.link}
                    onPress={() => {
                      router.push({pathname:'/profile'});
                      setOpen(false);
                    }}>
                {t('PROFILE')}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity>
              <Text style={styles.link}
                    onPress={() => {
                      signOut();
                      setOpen(false);
                    }}>
                {t('LOGOUT')}
              </Text>
            </TouchableOpacity>
          </GeneralPopover>
        </View>
        :
        null
      }
    </View>
  );
}

export default NavBar

const allStyles = StyleSheet.create({
  link:{
    color:'#000000',
    marginTop:20,
    fontWeight:'700',
    paddingRight:0,
    marginRight:0,
    marginBottom:20
  },
  image:{

  },
  profile:{
    color:'#FFFFFF',
    fontWeight:'700'
  },
  container:{
    width:'100%',
    height:Themes.styles.navBarHeight,
    display:'flex',
    padding:5,
    alignItems:'center',
    flexDirection:'row',
    backgroundColor:Themes.light.primary
  },
  popoverContainer:{
    width:120,
    marginLeft:'auto'
  }
});
