import {StyleSheet, Text, View} from "react-native";
import {useCallback, useState} from "react";
import Info from "./Info";
import {useTranslation} from "react-i18next";
import Icon from "@expo/vector-icons/MaterialIcons";
import {useAuthentication} from "../Contexts/Authentication";

const DynamicListHeader = ({width, addButtonClicked, titleObjectsArray, onSelectedColumnChanged, menuWidth = 0,
                             showBorder = false, className = '', canSortList = false, clampFirstColumn = false,
                             initialSelectedColumn = 0, borderWidth = 0.5}) => {
  
  // Instance Variables
  
  const {t} = useTranslation();
  const {colorScheme, themedStyle} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  const [ascending, setAscending] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(initialSelectedColumn);
  
  // Methods
  
  const handleSelectedColumnChanged = useCallback((aColumn) => {
    if(aColumn !== selectedColumn){
      setAscending(false);
      setSelectedColumn(aColumn);
      
      if(onSelectedColumnChanged){
        onSelectedColumnChanged(aColumn, false);
      }
    }
    else{
      if(onSelectedColumnChanged){
        onSelectedColumnChanged(aColumn, !ascending);
      }
      setAscending(!ascending);
    }
  }, [ascending, selectedColumn, onSelectedColumnChanged]);
  
  const flexForWidth = (aColumnWidth) => {
    return '0 0 ' + aColumnWidth.toString() + 'px';
  };
  
  // Render
  
  return (
    <View style={styles.container}>
      {titleObjectsArray.map((titleObject, index) => (
        <View style={[
          styles[((index === selectedColumn) ? 'titleSelected' : 'titleView')],
          (clampFirstColumn && (index === 0) ? styles.clamped : {}),
          (showBorder ? styles.border : {}),
          {width:titleObject.width, flex:flexForWidth(titleObject.width)},
          {borderWidth:showBorder ? borderWidth : 0.5},
          {borderTopWidth:showBorder ? borderWidth : 0},
          {borderLeftWidth:index === 0 ? borderWidth : 0},
          {borderRightWidth:showBorder ? (clampFirstColumn && index === 0 ? 1 * borderWidth : borderWidth) : 0}
        ]}
             key={titleObject.key + index}
             onClick={(aEvent) => handleSelectedColumnChanged(index)}>
          <View style={styles.title}>
            {titleObject.customView ?
              <View>
                {titleObject.customView}
              </View>
              :
              <Text style={[styles[((index === selectedColumn) ? 'titleSelectedText' : 'title')]]}>
                {titleObject.customTitle ?
                  titleObject.customTitle
                  :
                  t(titleObject.key)
                }
              </Text>
            }

            {titleObject.description ?
              <Info style={styles.info}
                    message={titleObject.description}
              />
              :
              null
            }
            
            {/*TODO: Add a property to sort columns in the titleObject.*/}
            
            {canSortList ?
              <>
                {ascending && selectedColumn === index ?
                  <Icon name="arrow-drop-up"
                        size={20}
                        color="#3b5998"/>
                  :
                  <Icon name="arrow-drop-down"
                        size={20}
                        color="#3b5998"/>
                }
              </>
              :
              null
            }
          </View>
        </View>
      ))}

      {menuWidth > 0 ?
        (addButtonClicked ?
            <View style={[
              styles[(showBorder ? 'menuBorder' : 'menuSpace')],
              {width:menuWidth + 'px', borderBottomWidth: 0.5},
              {borderWidth:showBorder ? borderWidth : 0.5},
              {borderTopWidth:borderWidth},
              {borderLeftWidth:0},
              {borderRightWidth:borderWidth}
            ]}
                  onClick={(aEvent) => {
                   aEvent.preventDefault();
                   aEvent.stopPropagation();
                   addButtonClicked(aEvent);
                 }}>
              <Icon name="settings"
                    size={20}
                    color="#3b5998"
              />
            </View>
            :
            <View style={[
              styles[(showBorder ? 'menuBorder' : 'menuSpace')],
              {width:menuWidth + 'px', borderWidth:(showBorder ? borderWidth : 0), borderBottomWidth:(showBorder ? borderWidth : 0.5)}
            ]}
            />
        )
        :
        null
      }
    </View>
  );
}

export default DynamicListHeader

const allStyles = StyleSheet.create({
  info:{
    textAlignVertical:'bottom'
  },
  title:{
    cursor:'pointer',
    fontWeight:'inherit',
    userSelect:'none',
    flexDirection:'row'
  },
  border:{
    border:'solid rgba(225,225,225,1)',
    boxSizing:'border-box',
    borderCollapse:'collapse',
    borderLeftWidth:0
  },
  clamped:{
    top:0,
    left:0,
    zIndex:5,
    padding:'10px',
    position:'sticky',
    flexDirection:'row',
    backgroundColor:'rgba(255, 255, 255, 1)'
  },
  container:{
    top:0,
    color:'rgba(100, 100, 100, 1)',
    width:'100%',
    zIndex:4,
    display:'flex',
    flexWrap:'nowrap',
    fontSize:'12px',
    position:'sticky',
    flexDirection:'row'
  },
  menuSpace:{
    border:'solid rgba(160, 160, 160, 1)',
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  titleView:{
    border:'solid rgba(160, 160, 160, 1)',
    zIndex:4,
    padding:'10px',
    flexDirection:'row',
    backgroundColor:'rgba(255, 255, 255, 1)'
  },
  menuBorder:{
    border:'solid rgba(160, 160, 160, 1)',
    display:'flex',
    boxSizing:'border-box',
    alignItems:'center',
    justifyContent:'center',
    borderCollapse:'collapse',
    borderLeftWidth:0
  },
  titleSelected:{
    color:'rgba(100, 100, 100, 1)',
    border:'solid rgba(160, 160, 160, 1)',
    display:'flex',
    padding:10,
    fontWeight:600,
    flexDirection:'row'
  },
  titleSelectedText:{
    fontWeight:600
  },
  clampedFirstColumn:{
    top:0,
    left:0,
    zIndex:5,
    padding:'10px',
    position:'sticky',
    flexDirection:'row',
    backgroundColor:'rgba(255, 255, 255, 1)'
  },
});
