import {Platform, StyleSheet, Text, View, TouchableOpacity, Linking} from "react-native";
import {useRef} from "react";
import {Link} from "expo-router";
import {useHover, useFocus, useActive} from 'react-native-web-hooks';

const CustomLink = ({label, style, href = '/', colour = '#000000', external = false}) => {
  
  // Instance Variables
  
  const ref = useRef(null);
  
  const isHovered = useHover(ref);
  const isFocused = useFocus(ref);
  const isActive = useActive(ref);
  
  
  // Methods
  
  const text = () => {
    return (
      <Text ref={ref}
            style={[
              styles.text,
              isHovered && styles.hover,
              isFocused && styles.focused,
              isActive && styles.active,
              {color:colour}
            ]}
            draggable={false}>
        {label}
      </Text>
    );
  }
  
  // Render
  
  return (
    external ?
      <TouchableOpacity onPress={() => Linking.canOpenURL(href).then((supported) => {
          if(supported){
            Linking.openURL(href).then();
          }
        })}>
        <View style={style}>
          {text()}
        </View>
      </TouchableOpacity>
      :
      <Link style={style}
            href={href}>
        {text()}
      </Link>
  );
}

export default CustomLink

const styles = StyleSheet.create({
  text:{
    textAlign:'inherit',
    ...Platform.select({
      web:{
        cursor:'pointer',
        outlineStyle:'none',
        borderBottomWidth:1,
        borderBottomColor:'transparent',
        transitionDuration:'200ms'
      },
      default:{},
    }),
  },
  active:{
    opacity:1.0
  },
  hover:{
    opacity:0.6
  },
  focused:{
    borderBottomColor:'black'
  }
});
