import {StyleSheet, Text, View} from "react-native";
import Info from "../Custom UI/Info";
import Icon from "@expo/vector-icons/MaterialIcons";
import Shadow from "../Custom UI/Shadow";
import {Themes} from "../Constants/ThemeColours";
import MenuButton from "../Custom UI/Buttons/MenuButton";
import {useTranslation} from "react-i18next";
import {useAuthentication} from "../Contexts/Authentication";
import {useEffect, useState} from "react";
import {formatUserName, validMenuItems} from "../Helpers/Helpers";
import {localUTCDayFromDateString, localTimeFromDateString} from "../Helpers/DateHelpers";

export default function AppointmentCell({row, appointment, menuItemsArray}){
  
  // Instance Variables
  
  const {t} = useTranslation();
  const {colorScheme, themedStyle} = useAuthentication();
  const styles = themedStyle(allStyles);
  const [menuItems, setMenuItems] = useState([]);
  
  // Effects
  
  useEffect(() => {
    setMenuItems(validMenuItems(menuItemsArray, appointment, row));
  }, [row, appointment, setMenuItems, menuItemsArray]);
  
  // Render
  
  return (
    <View style={styles.container}>
      <Shadow style={styles.shadow}
              shadowRadius={10}
              shadowOpacity={0.1}>
        <View style={styles.appointmentContainer}>
          <View style={styles.colourView}/>
          
          <View style={styles.availabilityCell}>
            <Text style={styles.availabilityCellDate}>
              {formatUserName(appointment.patient)}
            </Text>
            
            <MenuButton style={styles.menu}
                        colour={'#444444'}
                        menuItemsArray={menuItems}
            />
            
            <View style={styles.availabilityCellInfo}>
              <Text>
                {localUTCDayFromDateString(appointment.startAt)}: {localTimeFromDateString(appointment.startAt, false)} - {localTimeFromDateString(appointment.endAt, false)}
              </Text>
  
              {appointment.isPaid ?
                <View style={styles.isPaid}>
                  <Info label={t('PAID')}
                        placement={'left'}>
                    <View style={{width:20, height:20}}>
                      <Icon name="check-circle"
                            size={20}
                            color={Themes[colorScheme].success}
                      />
                    </View>
                  </Info>
                </View>
                :
                null
              }
            </View>
  
            
          </View>
        </View>
      </Shadow>
    </View>
  );
}

const allStyles = StyleSheet.create({
  isPaid:{
  
  },
  container:{
    flex:1,
    alignItems:'center'
  },
  menu:{
    top:5,
    right:5,
    width:20,
    position:'absolute'
  },
  colourView:{
    width:5,
    backgroundColor:Themes.light.failure,
    borderTopLeftRadius:Themes.styles.borderRadius,
    borderBottomLeftRadius:Themes.styles.borderRadius
  },
  shadow:{
    width:'100%'
  },
  checkmark:{
    flex:1,
    alignItems:'center',
    paddingTop:10
  },
  availabilityCell:{
    color:Themes.light.black,
    width:'100%',
    padding:10,
    position:'relative'
  },
  availabilityCellInfo:{
    color:Themes.light.black,
    height:20,
    alignItems:'center',
    flexDirection:'row',
    justifyContent:'space-between'
  },
  availabilityCellDate:{
    color:Themes.light.black,
    alignItems:'center',
    fontWeight:'bold'
  },
  appointmentContainer:{
    width:'100%',
    flexDirection:'row',
    borderRadius:Themes.styles.borderRadius
  }
});
