import {StyleSheet, Text, View, TouchableOpacity} from "react-native";
import {Themes} from "../Constants/ThemeColours";
import {currentYear} from "../Helpers/DateHelpers";
import {useTranslation} from "react-i18next";
import {setDefaultLocale} from "react-datepicker";
import {useAuthentication} from "../Contexts/Authentication";
import {localeKey, storeData} from "../Helpers/LocalStorageHelpers";

const Footer = () => {
  
  // Instance Variables
  
  const {t, i18n} = useTranslation();
  const {themedStyle} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  // Render
  
  return (
    <View style={styles.container}>
      <Text style={styles.copyright}>
        {t('COPYRIGHT', {aYear:currentYear()})}
      </Text>
      
      <TouchableOpacity style={{marginLeft:'auto', marginRight:10}}
                        onPress={() => {
                          let locale = 'en';
        
                          if(i18n.language === 'en'){
                            locale = 'fr';
                          }
                          i18n.changeLanguage(locale).then((newResult) => {
                            storeData(localeKey, locale).then(() => {});
                            setDefaultLocale(locale);
                          });
                        }}>
        <Text style={styles.language}>
          {i18n.language === 'en' ? 'FR' : 'EN'}
        </Text>
      </TouchableOpacity>
    </View>
  );
}

export default Footer

const allStyles = StyleSheet.create({
  language:{
    color:Themes.light.white,
    padding:5,
    fontWeight:'bold'
  },
  container:{
    width:'100%',
    height:Themes.styles.footerHeight,
    padding:5,
    display:'flex',
    alignItems:'center',
    flexDirection:'row',
    backgroundColor:Themes.light.black
  },
  copyright:{
    color:Themes.light.white,
    padding:5
  }
});
