import {SafeAreaView, StyleSheet, TextInput, View} from "react-native";
import {useState, useEffect} from "react";
import Icon from '@expo/vector-icons/MaterialIcons';
import Label from "../Label";
import Errors from "../Errors";
import {Themes} from "../../Constants/ThemeColours";
import IconButton from "../Buttons/IconButton";
import {useDebounce} from "@uidotdev/usehooks";
import {useAuthentication} from "../../Contexts/Authentication";

const LabeledInput = ({error, label, change, submit, warning, inputRef, validator, placeholder, keyboardType, placeholderIcon,
                        addButtonClicked, accessibilityLabel, placeholderIconColour, text = '', delay = 0,
                        width = '100%', disabled = false, marginTop = 0, fontWeight= 'bold',
                        marginLeft = 0, marginBottom = 20, inputMarginTop = 0, secureTextEntry = false}) => {
  
  // Instance Variables
  
  const {colorScheme, themedStyle} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  const [value, setValue] = useState(text);
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  
  const debouncedText = useDebounce(value, delay);
  
  // Effects
  
  useEffect(() => {
    if(text !== value && change){
      change(debouncedText);
    }
  }, [debouncedText]);
  
  useEffect(() => {
    setValue(text);
  }, [text]);
  
  // Methods
  
  const updateValue = (aValue) => {
    let newValue = aValue;
  
    if(validator){
      newValue = validator(aValue);
    }
    if(newValue !== value){
      setValue(newValue);
    }
  }
  
  // Render
  
  return (
    <SafeAreaView style={{width:width}}
                  accessibilityLabel={accessibilityLabel}>
      <Label style={[styles.label,
        {marginTop:marginTop},
        {fontWeight:fontWeight}
      ]}
             label={label}
      />
      
      <View style={[
        styles.container,
        {marginTop:inputMarginTop, marginLeft:marginLeft, marginBottom:marginBottom},
        {borderColor:(focused ? Themes[colorScheme].primary : Themes[colorScheme].black)}
      ]}>
        {placeholderIcon ?
          <View style={styles.placeholderIcon}>
            <Icon name={placeholderIcon}
                  size={25}
                  color={placeholderIconColour ? placeholderIconColour : Themes.light.mediumGray}
            />
          </View>
          :
          null
        }
        
        <TextInput style={[
          styles.input,
          (disabled ? styles.disabled : {})
        ]}
                   ref={inputRef}
                   value={value}
                   onBlur={() => setFocused(false)}
                   onFocus={() => setFocused(!disabled)}
                   editable={!disabled}
                   placeholder={placeholder}
                   onChange={(aEvent) => updateValue(aEvent.target.value)}
                   keyboardType={keyboardType}
                   onChangeText={(aText) => updateValue(aText)}
                   onSubmitEditing={submit}
                   secureTextEntry={secureTextEntry && !showPassword}
        />
        
        {secureTextEntry ?
          <IconButton name={showPassword ? 'visibility-off' : 'visibility'}
                      size={20}
                      colour={Themes[colorScheme].mediumGray}
                      buttonClicked={() => setShowPassword(!showPassword)}
          />
          :
          null
        }
      </View>
      
      {addButtonClicked ?
        <IconButton name={'add-circle-outline'}
                    size={30}
                    style={styles.addButton}
                    colour={Themes[colorScheme].primary}
                    buttonClicked={addButtonClicked}
        />
        :
        null
      }
      
      <Errors error={error}
              warning={warning}
      />
    </SafeAreaView>
  );
}

export default LabeledInput

const allStyles = StyleSheet.create({
  input:{
    flex:1,
    paddingTop:10,
    borderColor:'rgba(0, 0, 0, 0)',
    borderWidth:0,
    paddingLeft:5,
    paddingRight:10,
    outlineColor:'rgba(0, 0, 0, 0)',
    outlineStyle:'solid',
    outlineWidth:0,
    outlineOffset:0,
    paddingBottom:10
  },
  label:{
    paddingBottom:7
  },
  disabled:{
    borderRadius:7,
    backgroundColor:'rgba(235, 235, 235, 1)'
  },
  container:{
    width:'100%',
    display:'flex',
    alignItems:'center',
    flexDirection:'row',
    borderWidth:1,
    borderColor:'black',
    borderRadius:7
  },
  addButton:{
    marginTop:'7px'
  },
  placeholderIcon:{
    marginRight:5,
    marginLeft:10
  }
});
