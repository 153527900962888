// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./_layout.js": { enumerable: true, get() { return require("/codebuild/output/src3862997098/src/app/_layout.js"); } },
  "./appointment.js": { enumerable: true, get() { return require("/codebuild/output/src3862997098/src/app/appointment.js"); } },
  "./appointments.js": { enumerable: true, get() { return require("/codebuild/output/src3862997098/src/app/appointments.js"); } },
  "./forgot.js": { enumerable: true, get() { return require("/codebuild/output/src3862997098/src/app/forgot.js"); } },
  "./index.js": { enumerable: true, get() { return require("/codebuild/output/src3862997098/src/app/index.js"); } },
  "./invitation.js": { enumerable: true, get() { return require("/codebuild/output/src3862997098/src/app/invitation.js"); } },
  "./patient.js": { enumerable: true, get() { return require("/codebuild/output/src3862997098/src/app/patient.js"); } },
  "./patients.js": { enumerable: true, get() { return require("/codebuild/output/src3862997098/src/app/patients.js"); } },
  "./payment.js": { enumerable: true, get() { return require("/codebuild/output/src3862997098/src/app/payment.js"); } },
  "./profile.js": { enumerable: true, get() { return require("/codebuild/output/src3862997098/src/app/profile.js"); } },
  "./unmatched.js": { enumerable: true, get() { return require("/codebuild/output/src3862997098/src/app/unmatched.js"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;