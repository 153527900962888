import {StyleSheet, View} from "react-native";
import {useEffect, useState} from "react";
import {Themes} from "../Files/Constants/ThemeColours";
import {useRouter} from "expo-router";
import LabeledInput from "../Files/Custom UI/Inputs/LabeledInput";
import LabeledSwitch from "../Files/Custom UI/Inputs/LabeledSwitch";
import LabeledDropDown from "../Files/Custom UI/Inputs/LabeledDropDown";
import {useTranslation} from "react-i18next";
import {dateFromDateString} from "../Files/Helpers/DateHelpers";
import {errorMessageFromServerError, formatUserName} from "../Files/Helpers/Helpers";
import {UserTypes} from "../Files/Constants/UserConstants";

export default function Appointment({clinicId, appointment, savePressed, availability, saveFinished,
                                      toastMessage, api, user, themedStyle, selectedPatient, clearSelectedPatient}){
  
  // Instance Variables
  
  const {t} = useTranslation();
  const router = useRouter();

  let initialNumberOfBloodDrawRefills = 0;
  let initialIsRepeatBloodDraw = false;

  if(appointment){
    initialNumberOfBloodDrawRefills = appointment.numberOfBloodDrawRefills;
  }
  else if(selectedPatient && selectedPatient.newestAppointment && selectedPatient.newestAppointment.numberOfBloodDrawRefills){
    initialNumberOfBloodDrawRefills = selectedPatient.newestAppointment.numberOfBloodDrawRefills - 1;
  }

  if(appointment){
    initialIsRepeatBloodDraw = appointment.isRepeatBloodDraw;
  }
  else if(initialNumberOfBloodDrawRefills > 0){
    initialIsRepeatBloodDraw = true;
  }

  const styles= themedStyle(allStyles);
  
  const [startTime] = useState(appointment ? dateFromDateString(appointment.startAt).toString() : (availability ? availability.startAt : ''));
  const [newStartTime] = useState((availability ? availability.startAt : ''));
  
  const [saving, setSaving] = useState(false);
  const [isBloodDraw, setIsBloodDraw] = useState(appointment ? appointment.isBloodDraw : true);
  const [referredUserId, setReferredUserId] = useState(user && user.role !== UserTypes.administrator ? user.id : '');
  const [userOptionsArray, setUserOptionsArray] = useState([]);
  const [isRepeatBloodDraw, setIsRepeatBloodDraw] = useState(initialIsRepeatBloodDraw);
  const [isThermalTreatment, setIsThermalTreatment] = useState(appointment ? appointment.isThermalTreatment : (availability ? availability.isThermalTreatmentAllowed : false));
  const [isAutoImmuneDisease, setIsAutoImmuneDisease] = useState(appointment ? appointment.isAutoImmuneDisease : false);
  const [isUsingAntiCoagulants, setIsUsingAntiCoagulants] = useState(appointment ? appointment.isUsingAntiCoagulants : false);
  const [isUsingPrivateInsurance, setIsUsingPrivateInsurance] = useState(appointment ? appointment.isUsingPrivateInsurance : false);
  const [numberOfBloodDrawRefills, setNumberOfBloodDrawRefills] = useState(initialNumberOfBloodDrawRefills);
  
  // Effects
  
  useEffect(() => {
    if(api && user && user.role === UserTypes.administrator){
      api.indexUsers(30, 0).then((newResult) => {
        let returnValue = [];

        for(let physician of newResult.data.objects){
          if(physician.role === UserTypes.doctor){
            returnValue.push({label:formatUserName(physician), value:physician.id});
          }
        }
        setUserOptionsArray(returnValue);
      }, (newError) => {

      });
    }
  }, [api, user]);
  
  useEffect(() => {
    if(isRepeatBloodDraw === false){
      setNumberOfBloodDrawRefills(0);
    }
  }, [isRepeatBloodDraw, setNumberOfBloodDrawRefills]);
  
  useEffect(() => {
    if(savePressed && !saving){
      setSaving(true);
      
      if(appointment && appointment.id && appointment.id.length > 0){
        api.updateAppointment(appointment.id, {patientId:selectedPatient.id, endoretClinicId:clinicId, startAt:newStartTime.toString(),
          isUsingAntiCoagulants:isUsingAntiCoagulants, isThermalTreatment:isThermalTreatment, isBloodDraw:isBloodDraw,
          isRepeatBloodDraw:isRepeatBloodDraw, numberOfBloodDrawRefills:numberOfBloodDrawRefills, isUsingPrivateInsurance:isUsingPrivateInsurance,
          isAutoImmuneDisease:isAutoImmuneDisease, referredUserId:referredUserId}).then((newResult) => {
          toastMessage(t('APPOINTMENT_UPDATED'));
          
          if(!newResult.data.usesPaymentEmail){
            router.push({pathname:'/payment', params:{signedId:newResult.data.signedId}});
          }
          else{
            clearSelectedPatient();
            router.push({pathname:'/patients'});
          }
          saveFinished();
          setTimeout(() => setSaving(false), 500);
        }, (newError) => {
          toastMessage(t('APPOINTMENT_UPDATED_ERROR', {aError:errorMessageFromServerError(newError)}));
          saveFinished();
          setTimeout(() => setSaving(false), 500);
        });
      }
      else{
        api.createAppointment({patientId:selectedPatient.id, endoretClinicId:clinicId, startAt:startTime.toString(),
          isUsingAntiCoagulants:isUsingAntiCoagulants, isThermalTreatment:isThermalTreatment, isBloodDraw:isBloodDraw,
          isRepeatBloodDraw:isRepeatBloodDraw, numberOfBloodDrawRefills:numberOfBloodDrawRefills, isUsingPrivateInsurance:isUsingPrivateInsurance,
          isAutoImmuneDisease:isAutoImmuneDisease, referredUserId:referredUserId}).then((newResult) => {
          toastMessage(t('APPOINTMENT_CREATED'));

          if(!newResult.data.usesPaymentEmail){
            router.push({pathname:'/payment', params:{signedId:newResult.data.signedId}});
          }
          else{
            clearSelectedPatient();
            router.push({pathname:'/patients'});
          }
          saveFinished();
          setTimeout(() => setSaving(false), 500);
        }, (newError) => {
          toastMessage(t('APPOINTMENT_CREATED_ERROR', {aError:errorMessageFromServerError(newError)}));
          saveFinished();
          setTimeout(() => setSaving(false), 500);
        });
      }
    }
  }, [api, saving, setSaving, savePressed, saveFinished]);
  
  // Render
  
  return (
    <View style={styles.container}>
      <LabeledInput text={startTime}
                    label={t(appointment && appointment.id && appointment.id.length > 0 ? 'ORIGINAL_START_AT' : 'START_AT')}
                    disabled={true}
      />

      {appointment && appointment.id && appointment.id.length > 0 ?
        <LabeledInput text={newStartTime}
                      label={t('NEW_START_AT')}
                      disabled={true}
        />
        :
        null
      }
      
      {user && user.role === UserTypes.administrator ?
        <LabeledDropDown label={t('REFERRING_PHYSICIAN')}
                         value={referredUserId}
                         change={setReferredUserId}
                         placeholder={t('REFERRING_PHYSICIAN')}
                         optionsArray={userOptionsArray}
        />
        :
        null
      }
      
      <LabeledSwitch label={t('IS_BLOOD_DRAW')}
                     value={isBloodDraw}
                     change={setIsBloodDraw}
      />
      
      <LabeledSwitch label={t('IS_REPEAT_BLOOD_DRAW')}
                     value={isRepeatBloodDraw}
                     change={setIsRepeatBloodDraw}
      />

      {availability && availability.isThermalTreatmentAllowed ?
        <LabeledSwitch label={t('IS_THERMAL_TREATMENT')}
                       value={isThermalTreatment}
                       change={setIsThermalTreatment}
        />
        :
        null
      }

      <LabeledSwitch label={t('IS_USING_ANTI_COAGULANTS')}
                     value={isUsingAntiCoagulants}
                     change={setIsUsingAntiCoagulants}
      />
      
      {/*<LabeledSwitch label={t('IS_USING_PRIVATE_INSURANCE')}*/}
      {/*               value={isUsingPrivateInsurance}*/}
      {/*               change={setIsUsingPrivateInsurance}*/}
      {/*/>*/}
      
      {isRepeatBloodDraw ?
        <LabeledInput text={numberOfBloodDrawRefills}
                      label={t('NUMBER_OF_BLOOD_DRAW_REFILLS')}
                      change={setNumberOfBloodDrawRefills}
                      placeholder={t('NUMBER_OF_BLOOD_DRAW_REFILLS')}
                      keyboardType="numeric"
        />
        :
        null
      }
    </View>
  );
}

const allStyles = StyleSheet.create({
  container:{
    flex:1,
    padding:24,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:Themes.light.white
  }
});
