import {SafeAreaView, StyleSheet, useColorScheme, View} from "react-native";
import {useEffect, useState} from "react";
import Label from "../Label";
import Errors from "../Errors";
import {Themes} from "../../Constants/ThemeColours";
import IconButton from "../Buttons/IconButton";
import {TextArea} from "native-base";
import {useDebounce} from '@uidotdev/usehooks'

const LabeledTextArea = ({text, error, label, warning, inputRef, placeholder, keyboardType, change, addButtonClicked,
                           accessibilityLabel, delay = 0, disabled = false, marginBottom = 20, marginTop = 0}) => {
  
  // Instance Variables
  
  const colorScheme = useColorScheme();
  const [value, setValue] = useState(text);
  const debouncedText = useDebounce(value, delay);
  
  // Effects
  
  useEffect(() => {
    if(text !== value && change){
      change(debouncedText);
    }
  }, [debouncedText]);
  
  useEffect(() => {
    setValue(text);
  }, [text]);
  
  // Render
  
  return (
    <SafeAreaView style={{width:'100%'}}
                  accessibilityLabel={accessibilityLabel}>
      <Label style={[styles.inputLabel, {marginTop:marginTop}]}
             label={label}
      />
      
      <View style={[styles.labeledInputContainer, {marginBottom:marginBottom}]}>
        <TextArea style={[
          styles.input,
          (disabled ? styles.inputDisabled : {}),
        ]}
                  ref={inputRef}
                  _hover={{borderColor:Themes[colorScheme].primary}}
                  value={value}
                  editable={!disabled}
                  onChange={(aEvent) => setValue(aEvent.target.value)}
                  borderColor={Themes[colorScheme].black}
                  placeholder={placeholder}
                  borderRadius={Themes.styles.borderRadius}
                  keyboardType={keyboardType}
        />
        
        {addButtonClicked ?
          <IconButton name={'add-circle-outline'}
                      size={30}
                      style={styles.labeledInputAddButton}
                      colour={Themes[colorScheme].primary}
                      buttonClicked={addButtonClicked}
          />
          :
          null
        }
      </View>
      
      <Errors error={error}
              warning={warning}
      />
    </SafeAreaView>
  );
}

export default LabeledTextArea

const styles = StyleSheet.create({
  input:{
    flex:1,
    width:'100%',
    padding:10,
    alignItems:'center',
    borderRadius:Themes.styles.borderRadius,
  },
  inputLabel:{
  },
  inputDisabled:{
    backgroundColor:'rgba(235,235,235,1)'
  },
  labeledInputContainer:{
    width:'100%',
    paddingTop:10,
    paddingBottom:10
  },
  labeledInputAddButton:{
    marginTop:'7px'
  }
});
