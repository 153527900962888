import {StyleSheet, Text, View} from "react-native";
import React from "react";
import {Button, Popover} from "native-base";

const GeneralPopover = ({save, close, title, children, anchorElement, accessibilityLabel, open = false, width = 100, showArrow = true}) => {
  
  // Instance Variables
  
  
  
  // Methods
  
  
  
  // Render
  
  return (
      <Popover isOpen={open}
               onClose={() => close()}
               trigger={(triggerProps) => {
                 return React.cloneElement(anchorElement, {...triggerProps});
               }}>
        <Popover.Content w={width}
                         accessibilityLabel={accessibilityLabel}>
          {showArrow ?
            <Popover.Arrow />
            :
            null
          }
  
          {title ?
            <>
              <Popover.CloseButton onPress={() => close()}/>
              
              <Popover.Header>
                {title}
              </Popover.Header>
            </>
            :
            null
          }
          
          <Popover.Body w={width}>
            {children}
          </Popover.Body>
  
          {save ?
            <Popover.Footer justifyContent="flex-end">
              <Button.Group space={2}>
                <Button colorScheme="coolGray"
                        variant="ghost"
                        onPress={() => close()}>
                  Cancel
                </Button>
      
                <Button colorScheme="danger"
                        onPress={() => save()}>
                  Save
                </Button>
              </Button.Group>
            </Popover.Footer>
            :
            null
          }
        </Popover.Content>
      </Popover>
  );
}

export default GeneralPopover

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    padding: 24,
  },
  main: {
    flex: 1,
    justifyContent: "center",
    maxWidth: 960,
    marginHorizontal: "auto",
  },
  title: {
    fontSize: 64,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 36,
    color: "#38434D",
  },
});
