import {useColorScheme} from "react-native";
import React, {useEffect, useState} from "react";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import {Themes} from "../Constants/ThemeColours";
import TermsModal from "../Modals/TermsModal";
import {ApiService} from "../Services/ApiService";
import {TermsService} from "../Services/TermsService";
import {useMediaQuery} from "react-responsive";
import {useTranslation} from "react-i18next";
import {objectIsPopulated} from "../Helpers/Helpers";
import ChangePasswordModal from "../Modals/ChangePasswordModal";
import {customAxiosInstance} from "../Services/CustomAxios";
import {Toast, NativeBaseProvider} from "native-base";
import {useSearchParams, useRouter, useSegments} from "expo-router";
import {getData, storeData, patientKey, deleteData} from "../Helpers/LocalStorageHelpers";

const AuthContext = React.createContext(null);

const baseUrl = process.env.EXPO_PUBLIC_API_URL || (window.location.protocol + '//api.' + window.location.host + '/');

export function useAuthentication() {
  return React.useContext(AuthContext);
}

const authenticatedRoutesArray = ['appointment', 'clinic', 'patient', 'patients', 'profile'];
const unauthenticatedRoutesArray = ['forgot', 'index', 'login'];

export function Provider(props){
  
  // Instance Variables
  
  const {t} = useTranslation();
  const params = useSearchParams();
  const router = useRouter();
  const segments = useSegments();
  const colorScheme = useColorScheme();
  
  const isDesktop = useMediaQuery({minWidth:1025, maxWidth:1600});
  const isTablet = useMediaQuery({minWidth:768, maxWidth:1024});
  const isLargeMobile = useMediaQuery({minWidth:376, maxWidth:765});
  const isMobile = useMediaQuery({maxWidth:375});
  const portrait = useMediaQuery({query:'(orientation:portrait)'});
  //const isRetina = useMediaQuery({query:'(min-resolution:2dppx)'});
  
  const screenSizeIndex = isMobile ? 0 : isLargeMobile ? 1 : isTablet ? 2 : isDesktop ? 3 : 4;
  const screenSizesArray = ['mobile', 'largeMobile', 'tablet', 'desktop', 'big'];
  const screenSize = screenSizesArray[screenSizeIndex];
  
  const [user, setUser] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  
  // Getters
  
  const isLoggedIn = () => {
    return objectIsPopulated(user) && user.id !== null && user.id !== undefined && user.id.length > 0;
  };
  
  const reloadUserFromServer = () => {
    api.showCurrentUser().then((newResult) => {
      setUser(newResult.data);
    }, (newError) => {
      setUser({});
    });
  };
  
  // Setters
  
  const clearLocalData = (aToastKey) => {
    if(aToastKey){
      toastMessage(t(aToastKey));
    }
    setUser({});
    clearSelectedPatient();
    router.push({pathname:'/'});
  };
  
  const clearIfLoggedIn = (aError) => {
    if(aError && aError.response && aError.response.status === 401){
      if(isLoggedIn() || authenticatedRoutesArray.includes()){
        clearLocalData('LOGOUT_SESSION_MESSAGE');
      }
    }
  };
  
  const customAxios = customAxiosInstance(clearIfLoggedIn);
  const api = ApiService(baseUrl, customAxios);
  
  const termsCustomAxios = customAxiosInstance();
  const termsApi = TermsService(baseUrl, termsCustomAxios);
  
  // Effects
  
  useEffect(() => {
    reloadUserFromServer();
  }, []);
  
  useEffect(() => {
    if(objectIsPopulated(selectedPatient)){
      storeData(patientKey, selectedPatient).then(() => {});
    }
    else{
      getData(patientKey).then((aPatient) => {
        if(objectIsPopulated(aPatient) && aPatient.id && aPatient.id.length > 0)
          setSelectedPatient(aPatient);
      });
    }
  }, [selectedPatient, setSelectedPatient]);
  
  useEffect(() => {
    if(user !== null){
      let path = 'index';
  
      if(segments && segments.length > 0){
        path = segments[0];
      }
      if(isLoggedIn()){
        if(unauthenticatedRoutesArray.includes(path)){
          router.push({pathname:'/patients', params:params});
        }
      }
      else if(authenticatedRoutesArray.includes(path)){
        router.push({pathname:'/', params:params});
      }
    }
  }, [user, segments]);
  
  const signIn = (aEmail, aPassword) => {
    return api.signIn({user:{email:aEmail, password:aPassword}}).then((newResult) => {
      setUser(newResult.data);
      return newResult;
    }, (newError) => {
      return Promise.reject(newError);
    });
  };
  
  const signOut = () => {
    return api.signOut().then((newResult) => {
      clearLocalData('LOGOUT_MESSAGE');
      router.push({pathname:'/'});
      return newResult;
    }, (newError) => {
      return Promise.reject(newError);
    });
  };
  
  const themedStyle = (aStyles)=> {
    let returnValue = {};
    
    // TODO: Uncomment when theming is complete.
    // if(colorScheme !== 'light'){
    //   const theme = Themes[colorScheme];
    //   const lightTheme = Themes.light;
    //
    //   for(let style of Object.keys(aStyles)){
    //     returnValue[style] = {};
    //
    //     for(let key of Object.keys(aStyles[style])){
    //       returnValue[style][key] = aStyles[style][key];
    //
    //       for(let lightThemeKey of Object.keys(lightTheme)){
    //         if(aStyles[style][key] === lightTheme[lightThemeKey]){
    //           returnValue[style][key] = theme[lightThemeKey];
    //           break;
    //         }
    //       }
    //     }
    //   }
    // }
    // else{
      returnValue = aStyles;
    // }
    return returnValue;
  };
  
  const updateUser = (aUser) => {
    return api.updateUser(aUser).then((newResult) => {
      setUser(newResult.data);
      return newResult;
    }, (newError) => {
      return Promise.reject(newError);
    });
  };
  
  const clearSelectedPatient = () => {
    deleteData(patientKey).then(() => {
      setSelectedPatient(null);
    });
  }
  
  const toastMessage = (aMessage, aPlacement = 'top') => {
    Toast.show({title:aMessage, placement:aPlacement, _overlay:{zIndex:999999}});
  }
  
  // Render
  
  return (
    <AuthContext.Provider value={{api, user, portrait, termsApi, screenSize, colorScheme, selectedPatient, setSelectedPatient,
      signIn, signOut, themedStyle, isLoggedIn, updateUser, toastMessage, clearSelectedPatient, reloadUserFromServer}}>
      <NativeBaseProvider>
        <NavBar />
        
        {props.children}
        
        <Footer />
        
        <TermsModal open={isLoggedIn() && user.hasAgreedToAllTermsDocuments !== true}/>
        
        <ChangePasswordModal open={isLoggedIn() && user.forceResetPasswordOnLogin === true}/>
      </NativeBaseProvider>
    </AuthContext.Provider>
  );
}
