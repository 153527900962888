// For further information, visit:
//
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
export const daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function dateIsValid(aDate){
  return aDate && (aDate.toString() !== 'Invalid Date') && (aDate instanceof Date);
}

export function timeFromDate(aDateString){
  let returnValue = '';
  
  if(aDateString){
    let date = new Date(aDateString);
    
    if(dateIsValid(date)){
      returnValue = date.toTimeString();
    }
  }
  return returnValue;
}

export function dayFromDateString(aDateString){
  let returnValue = '';
  
  if(aDateString){
    let date = new Date(aDateString);
    
    if(dateIsValid(date)){
      returnValue = date.toDateString();
    }
  }
  return returnValue;
}

export function localTimeFromDate(aDate, aShowSeconds = true){
  let returnValue = '';
  
  if(dateIsValid(aDate)){
    if(aShowSeconds){
      returnValue = aDate.toLocaleTimeString();
    }
    else{
      let hours = aDate.getHours();
      let minutes = aDate.getMinutes();
      let meridiem = 'AM'
      
      if(hours > 11){
        meridiem = 'PM';
      }
      if(hours > 12){
        hours = hours - 12;
      }
      returnValue = hours.toString() + ':';
      
      if(minutes < 10){
        returnValue += '0';
      }
      returnValue += aDate.getMinutes().toString();
      returnValue += ' ' + meridiem;
    }
  }
  return returnValue;
}

export function localTimeFromDateString(aDateString, aShowSeconds = true){
  let returnValue = '';
  
  if(aDateString){
    returnValue = localTimeFromDate(new Date(aDateString), aShowSeconds);
  }
  return returnValue;
}

export function localUTCDayFromDateString(aDateString){
  let returnValue = '';
  
  if(aDateString){
    // let timezoneOffset = (new Date()).getTimezoneOffset();
    // let date = addMinutesToDateString(aDateString, timezoneOffset);
    let date = dateFromDateString(aDateString);
    
    if(dateIsValid(date)){
      let day = date.getDate().toString();
      let month = (date.getMonth() + 1).toString();
      
      returnValue = date.getFullYear() + '-';
      
      if(month.length === 1){
        returnValue += '0';
      }
      returnValue += month + '-';
      
      if(day.length === 1){
        returnValue += '0';
      }
      returnValue += day;
    }
  }
  return returnValue;
}

export function localDayTimeFromDateString(aDateString, aShowSeconds = true){
  let returnValue = '';
  
  if(aDateString){
    returnValue = localUTCDayFromDateString(aDateString) + ' - ' + localTimeFromDateString(aDateString, aShowSeconds);
  }
  return returnValue;
}

export function dayOfTheWeekFromDate(aDateString){
  let returnValue = null;
  
  if(aDateString){
    let date = new Date(aDateString);
    
    if(dateIsValid(date)){
      let day = date.getDate().toString();
      returnValue = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day];
    }
  }
  return returnValue;
}

export function dayOfTheWeekFromDateString(aDateString){
  let returnValue = '';
  
  if(aDateString){
    let date = new Date(aDateString);
    
    if(dateIsValid(date)){
      let day = Number(date.getDay().toString()) + 1;
      returnValue = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day];
    }
  }
  return returnValue;
}

export function secondsBetweenDateStrings(aStartDateString, aEndDateString){
  let returnValue = 0;
  
  if(aEndDateString && aStartDateString){
    const endTime = new Date(aEndDateString);
    const startTime = new Date(aStartDateString);
    
    if(dateIsValid(endTime) && dateIsValid(startTime)){
      returnValue = (endTime - startTime) / 1000;
    }
  }
  return returnValue;
}

export function addDaysToDate(aDate, aDays){
  let returnValue = aDate;
  
  if(dateIsValid(aDate) && !isNaN(aDays)){
    returnValue.setDate(returnValue.getDate() + parseInt(aDays));
  }
  return returnValue;
}

export function addDaysToDateString(aDate, aDays){
  return addDaysToDate(dateFromDateString(aDate), aDays);
}

export function addWorkDaysToDate(aDate, aDays){
  let returnValue = aDate;
  
  if(dateIsValid(aDate) && !isNaN(aDays)){
    let dayOfTheWeek = aDate.getDay();
    let daysToAdd = parseInt(aDays);
    
    if(daysOfTheWeek[dayOfTheWeek] === 'Sunday'){
      daysToAdd += 1;
    }
    if((dayOfTheWeek + daysToAdd) > 5){
      let remainingWorkDays = daysToAdd - (5 - dayOfTheWeek);
      daysToAdd += 2;
      
      if(remainingWorkDays > 5){
        daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
        
        if(remainingWorkDays % 5 === 0){
          daysToAdd -= 2;
        }
      }
    }
    returnValue = addDaysToDate(returnValue, daysToAdd);
  }
  return returnValue;
}

export function addWorkDaysToDateString(aDate, aDays){
  return addWorkDaysToDate(dateFromDateString(aDate), aDays);
}

export function addMonthsToDate(aDate, aMonths){
  let returnValue = aDate;
  
  if(dateIsValid(aDate) && aMonths){
    const month = aDate.getMonth() + aMonths;
    returnValue.setMonth(month);
  }
  return returnValue;
}

export function addMonthsToDateString(aDateString, aMonths){
  let returnValue = null;
  
  if(aDateString && aMonths){
    returnValue = addMonthsToDate(new Date(aDateString), aMonths);
  }
  return returnValue;
}

export function addMinutesToDate(aDate, aMinutes){
  let returnValue = aDate;
  
  if(dateIsValid(aDate) && aMinutes){
    const minutes = aDate.getMinutes() + aMinutes;
    returnValue.setMinutes(minutes);
  }
  return returnValue;
}

export function addMinutesToDateString(aDateString, aMinutes){
  let returnValue = null;
  
  if(aDateString && aMinutes){
    returnValue = addMinutesToDate(new Date(aDateString), aMinutes);
  }
  return returnValue;
}

export function dateFromDateString(aDateString, aFormat){
  let returnValue = null;
  
  if(aDateString){
    // TODO: Add format support.
    returnValue = new Date(aDateString);
  }
  return returnValue;
}

export function currentDate(){
  let returnValue = new Date();
  returnValue.setHours(0);
  returnValue.setMinutes(0);
  returnValue.setSeconds(0);
  returnValue.setMilliseconds(0);
  return returnValue;
}

export function currentYear(){
  return (new Date()).getFullYear();
}

export function dateEqualsDate(aFirstDate, aSecondDate){
  return (secondsBetweenDateStrings(aFirstDate, aSecondDate) === 0);
}

export function dateAfterDate(aFirstDate, aSecondDate){
  return (secondsBetweenDateStrings(aFirstDate, aSecondDate) < 0);
}

export function dateOnOrAfterDate(aFirstDate, aSecondDate){
  return (secondsBetweenDateStrings(aFirstDate, aSecondDate) <= 0);
}

export function dateBeforeDate(aFirstDate, aSecondDate){
  return (secondsBetweenDateStrings(aFirstDate, aSecondDate) > 0);
}

export function dateOnOrBeforeDate(aFirstDate, aSecondDate){
  return (secondsBetweenDateStrings(aFirstDate, aSecondDate) >= 0);
}

