import {Slot} from "expo-router";
import {Provider} from "../Files/Contexts/Authentication";

export default function Root() {
  return (
    <Provider>
      <Slot />
    </Provider>
  );
}
