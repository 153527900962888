import {StyleSheet, View} from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import {Themes} from "../Constants/ThemeColours";
import {Tooltip} from 'native-base'
import {useAuthentication} from "../Contexts/Authentication";

const Info = ({label, children, colour, textColour, size = 25, delay = 200, placement = 'top', showArrow = true}) => {
  
  // Instance Variables
  
  const {colorScheme, themedStyle} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  // Methods
  
  // TODO: The children must be a node type. Add checks to validate before rendering, or wrap them in a View.
  
  // Render
  
  return (
    <View>
      <Tooltip style={styles.info}
               bg={colour ? colour : Themes[colorScheme].black}
               label={label}
               hasArrow={showArrow}
               openDelay={delay}
               placement={['top left', 'top', 'top right', 'right', 'bottom right', 'bottom', 'bottom left', 'left'].includes(placement) ? placement : 'top'}
               closeDelay={2 * delay}
               _text={{color:textColour ? textColour : Themes[colorScheme].white}}>
        {children ?
          children
          :
          <View style={{width:size, height:size}}>
            <Icon name={'info-outline'}
                  size={size}
                  colour={Themes[colorScheme].black}
            />
          </View>
        }
      </Tooltip>
    </View>
    
  );
}

export default Info

const allStyles = StyleSheet.create({
  info:{
    maxWidth:250
  }
});
