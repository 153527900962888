import {FlatList, SafeAreaView, ScrollView, StyleSheet, Text, View} from "react-native";
import {Themes} from "../Constants/ThemeColours";
import {Divider} from "native-base";
import {useTranslation} from "react-i18next";
import AvailabilityCell from "../Cells/AvailabilityCell";
import {useAuthentication} from "../Contexts/Authentication";
import {appointmentsOnDate} from "../Helpers/AppointmentHelpers";

const AvailabilitiesList = ({style, select, selectedDate, availabilities}) => {
  
  // Instance Variables
  
  const {t} = useTranslation();
  const {colorScheme, themedStyle, isLoggedIn} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  // Methods
  
  
  
  // Render
  
  return (
    <SafeAreaView style={[styles.container, style]}>
      <View>
        <Text style={styles.title}>
          {t('AVAILABLE_TIMES') + (selectedDate && selectedDate.length > 0 ? (' (' + selectedDate + ')') : '')}
        </Text>
  
        <Divider bg={Themes[colorScheme].black}
                 thickness="1"
                 orientation="horizontal"
        />
      </View>
      
      <ScrollView style={styles.scrollView}>
        <FlatList data={appointmentsOnDate(availabilities, selectedDate)}
                  renderItem={({item}) =>
                    <AvailabilityCell select={() => isLoggedIn() ? select(item) : null}
                                      availability={item}
                    />}
                  keyExtractor={(aItem, aIndex) => {return 'availability-cell-' + (aItem && aItem.startAt ? aItem.startAt : aIndex)}}
        />
      </ScrollView>
    </SafeAreaView>
  );
}

export default AvailabilitiesList

const allStyles = StyleSheet.create({
  title:{
    fontSize:15,
    textAlign:'center',
    paddingTop:20,
    fontWeight:600,
    paddingBottom:10,
  },
  container:{
    flex:1,
    alignItems:'center',
  },
  scrollView:{
    width:'100%',
    height:'100%'
  }
});
