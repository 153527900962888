import {StyleSheet, Text, View, Pressable} from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import Info from "../Custom UI/Info";
import Shadow from "../Custom UI/Shadow";
import {Themes} from "../Constants/ThemeColours";
import {useTranslation} from "react-i18next";
import {useAuthentication} from "../Contexts/Authentication";
import {localUTCDayFromDateString, localTimeFromDateString} from "../Helpers/DateHelpers";

export default function AvailabilityCell({select, availability}){
  
  // Instance Variables
  
  const {t} = useTranslation();
  const {colorScheme, themedStyle} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  // Render
  
  return (
    <Pressable style={styles.container}
               onPress={select}>
      <Shadow style={styles.shadow}
              shadowRadius={7}
              shadowOpacity={0.1}>
        <View style={styles.availabilityContainer}>
          <View style={styles.colourView}/>
          
          <View style={styles.availabilityCell}>
            <Text style={styles.availabilityCellDate}>
              {localUTCDayFromDateString(availability.startAt)}
            </Text>
            
            <View style={styles.availabilityCellInfo}>
              <Text>
                {localTimeFromDateString(availability.startAt, false)} - {localTimeFromDateString(availability.endAt, false)}
              </Text>
              
              {availability && availability.isThermalTreatmentAllowed ?
                <Info label={t('THERMAL_TREATMENT_AVAILABLE')}
                      placement={'left'}>
                  <View style={{width:20, height:20, marginRight:10}}>
                    <Icon name={'check-circle-outline'}
                          size={20}
                          color={Themes[colorScheme].success}
                    />
                  </View>
                </Info>
                :
                null
              }
            </View>
          </View>
        </View>
      </Shadow>
    </Pressable>
  );
}

const allStyles = StyleSheet.create({
  container:{
    flex:1,
    overflow:'hidden',
    alignItems:'center'
  },
  shadow:{
    width:'100%'
  },
  colourView:{
    width:5,
    backgroundColor:Themes.light.success,
    borderTopLeftRadius:Themes.styles.borderRadius,
    borderBottomLeftRadius:Themes.styles.borderRadius
  },
  checkmark:{
    flex:1,
    alignItems:'center',
    paddingTop:10
  },
  availabilityCell:{
    width:'100%',
    padding:10,
    paddingRight:2
  },
  availabilityCellInfo:{
    alignItems:'flex-end',
    flexDirection:'row',
    justifyContent:'space-between'
  },
  availabilityCellDate:{
    alignItems:'center',
    fontWeight:'bold'
  },
  availabilityContainer:{
    width:'100%',
    borderRadius:Themes.styles.borderRadius,
    flexDirection:'row',
    backgroundColor:'white'
  }
});
