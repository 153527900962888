import {FlatList, ScrollView, StyleSheet, Text, View} from "react-native";
import {useEffect, useRef, useState} from "react";
import IconButton from "../Custom UI/Buttons/IconButton";
import LabeledInput from "../Custom UI/Inputs/LabeledInput";
import DynamicListCell from "../Cells/DynamicListCell";
import {useTranslation} from "react-i18next";
import DynamicListHeader from "../Custom UI/DynamicListHeader";
import {useAuthentication} from "../Contexts/Authentication";
import InfiniteScrollManager from "../Managers/InfiniteScrollManager";

export default function DynamicList({
                                      id,
                                      didFetch,
                                      didReload,
                                      headerView,
                                      reloadCell,
                                      selectCell,
                                      extraObject,
                                      objectToAdd,
                                      reloadDelay,
                                      columnsArray,
                                      fetchObjects,
                                      shouldReload,
                                      totalObjects,
                                      isCellLoading,
                                      objectsFilter,
                                      objectsReduce,
                                      subHeaderView,
                                      explanationKey,
                                      maxColumnWidth,
                                      minColumnWidth,
                                      menuItemsArray,
                                      objectToDelete,
                                      objectToReplace,
                                      addButtonClicked,
                                      backButtonClicked,
                                      fixedObjectsArray,
                                      finishedAddingObject,
                                      addColumnButtonClicked,
                                      finishedDeletingObject,
                                      finishedReplacingObject,
                                      responseExtraObjectKeysArray,
                                      limit = 10,
                                      backKey = 'BACK',
                                      showTotal = true,
                                      showBorder = false,
                                      borderWidth = 0.5,
                                      canSortList = false,
                                      showSearchBar = true,
                                      totalObjectsKey = 'TOTAL',
                                      addObjectToEnd = false,
                                      clampFirstColumn = false,
                                      objectToDeleteKey = 'id',
                                      objectToReplaceKey = 'id',
                                      responseTotalKeysArray = ['data', 'total'],
                                      responseObjectKeysArray = ['data', 'objects'],
                                      searchBarPlaceHolderKey = 'SEARCH'
                                    }){
  
  // Instance Variables
  
  const {t} = useTranslation();
  const {colorScheme, themedStyle} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  const menuButtonWidth = 30;
  
  const scrollManagerRef = useRef(null);
  const {current:scrollManager} = scrollManagerRef;
  
  const scrollRef = useRef(null);
  const {current:scrollContainer} = scrollRef;
  
  const containerRef = useRef(null);
  const {current:container} = containerRef;
  
  const [total, setTotal] = useState(0);
  const [width, setWidth] = useState(1024);
  const [ascending, setAscending] = useState(false);
  const [searching, setSearching] = useState(false);
  const [totalWidth, setTotalWidth] = useState(0); // TODO: This needs a useEffect.
  const [fetchFailed, setFetchFailed] = useState(false);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [objectsArray, setObjectsArray] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [selectedColumn, setSelectedColumn] = useState(0);
  const [selectedObject, setSelectedObject] = useState(null);
  const [titleObjectsArray, setTitleObjectsArray] = useState([]); // TODO: This needs a useEffect.
  const [sectionHeadersArray, setSectionHeadersArray] = useState([]); // TODO: This needs a useEffect.
  const [filteredObjectsArray, setFilteredObjectsArray] = useState(null); // TODO: This needs a useEffect.
  const [shouldClampFirstColumn, setShouldClampFirstColumn] = useState(false);
  const [verticalScrollBarVisible, setVerticalScrollBarVisible] = useState(false);
  const [columnConfigurationsArray, setColumnConfigurationsArray] = useState([]); // TODO: This needs a useEffect.
  
  // Effects
  
  useEffect(() => {
    if(columnsArray && Array.isArray(columnsArray)){
      // if(columnsArray.length !== columnConfigurationsArray.length){
        let newTotalWidth = width;
        let finalWidth = width;
      
        if(verticalScrollBarVisible){
          finalWidth = width - 10;
        }
        let newTitleObjectsArray = [];
        let newColumnConfigurationsArray = [];
      
        if(Array.isArray(columnsArray)){
          newTotalWidth = 0;
          let weightSum = weightSumForColumnsArray(columnsArray);
        
          for(let index = 0; index < columnsArray.length; index += 1){
            let column = columnsArray[index];
            let columnWidth = widthForColumn(finalWidth, column, weightSum);
            newTitleObjectsArray.push({
              key:column.columnNameKey,
              width:columnWidth,
              description:column.description,
              customTitle:column.customTitle
            });
            newColumnConfigurationsArray.push({
              type:column.type,
              width:columnWidth,
              select:column.select,
              disabled:column.disabled,
              dateFormat:column.dateFormat,
              handleSave:column.handleSave,
              optionsArray:column.optionsArray,
              templateCell:column.templateCell,
              valueFormatter:column.valueFormatter,
              propertiesArray:column.propertiesArray,
              translationPrefix:column.translationPrefix
            });
            newTotalWidth = newTotalWidth + columnWidth;
          }
          if(Boolean(menuItemsArray)){
            newTotalWidth = newTotalWidth + menuButtonWidth;
          }
        // }
        setTotalWidth(newTotalWidth);
        setTitleObjectsArray(newTitleObjectsArray);
        setShouldClampFirstColumn(clampFirstColumn && (newTotalWidth > finalWidth));
        setColumnConfigurationsArray(newColumnConfigurationsArray);
      }
    }
    else{
      console.log('DynamicList (useEffect): The props columnsArray:', columnsArray, 'is not an array!');
    }
  }, [width, columnsArray, setTotalWidth, setTitleObjectsArray, setShouldClampFirstColumn, setColumnConfigurationsArray]);
  
  useEffect(() => {
    if(objectsArray && objectsArray.length === 0 && scrollManager && scrollManager.hasLoadedAll && !scrollManager.hasLoadedAll()){
      scrollManager.fetch(true);
    }
  }, [objectsArray, scrollManager]);
  
  useEffect(() => {
    if(canSortList){
      if(scrollManager && !scrollManager.isLoading()){
        scrollManager.fetchAndReloadWithLimit(true, limit);
      }
    }
  }, [ascending, selectedColumn]);
  
  useEffect(() => {
    if(scrollManager && !scrollManager.isLoading()){
      scrollManager.fetchAndReloadWithLimit(true, limit);
    }
  }, [searchString]);
  
  useEffect(() => {
    if(shouldReload){
      if(didReload){
        didReload();
      }
      scrollManager.fetchAndReloadWithLimit(true, limit);
    }
  }, [limit, didReload, shouldReload, scrollManager]);
  //
  // useEffect(() => {
  //   if(finishedAddingObject && objectToAdd){
  //     scrollManager.insertItem(objectToAdd, addObjectToEnd ? null : 0);
  //     finishedAddingObject();
  //     setTotal(total + 1);
  //     updateObjectsArray();
  //   }
  // }, [setTotal, objectToAdd, addObjectToEnd, scrollManager, finishedAddingObject]);
  //
  // useEffect(() => {
  //   if(finishedDeletingObject && objectToDelete && objectToDelete[objectToDeleteKey] && objectToDelete[objectToDeleteKey].length > 0){
  //     scrollManager.removeItemMatchingProperty(objectToDelete, objectToDeleteKey);
  //     finishedDeletingObject();
  //     setTotal(total - 1);
  //     updateObjectsArray();
  //   }
  // }, [setTotal, objectToDelete, objectToDeleteKey, scrollManager, finishedDeletingObject]);
  //
  // useEffect(() => {
  //   if(finishedReplacingObject && objectToReplace && objectToReplace[objectToReplaceKey] && objectToReplace[objectToReplaceKey].length > 0){
  //     scrollManager.replaceItemMatchingProperty(objectToReplace, objectToReplaceKey);
  //     finishedReplacingObject();
  //     updateObjectsArray();
  //   }
  // }, [objectToReplace, objectToReplaceKey, scrollManager, finishedReplacingObject]);
  
  // Methods
  
  // const notifyFetchError = (aError) => toast(t('FETCH_FAILED_ERROR', {aError:aError}), NOTIFY_OPTS.autoCloseFiveSeconds);
  
  // scrollRef = React.createRef();
  // containerRef = React.createRef();
  
  const updateList = () => {
    let manager = scrollManager;
    
    if(manager === null || manager === undefined){
      manager = scrollManagerRef.current;
    }
    setObjectsArray(manager.getList());
  };
  
  // Methods
  
  const getObjects = (aLimit, aOffset) => {
    // const {didFetch, extraObject, fetchObjects, totalObjects, objectsFilter, objectsReduce, fixedObjectsArray,
    //   responseTotalKeysArray, responseObjectKeysArray, responseExtraObjectKeysArray} = this.props;
    
    if(fetchObjects && responseTotalKeysArray && responseObjectKeysArray){
      return fetchObjects(aLimit, aOffset, searchString, selectedColumn, ascending).then((newResponse) => {
        let newTotal = valueForProperties(newResponse, responseTotalKeysArray);
        let newObjectsArray = valueForProperties(newResponse, responseObjectKeysArray);
        setTotal(newTotal);
        setSearching(false);
        setFetchFailed(false);
        
        if(didFetch){
          didFetch();
        }
        if(totalObjects && !(objectsFilter || objectsReduce)){
          totalObjects(newTotal);
        }
        if(extraObject && responseExtraObjectKeysArray){
          extraObject(valueForProperties(newResponse, responseExtraObjectKeysArray));
        }
        return {objects:newObjectsArray, total:newTotal};
      }, (newError) => {
        // notifyFetchError(errorMessageFromServerError(newError));
        setSearching(false);
        setFetchFailed(false);
        return Promise.reject(newError);
      });
    }
    else{
      if(fixedObjectsArray === undefined){
        console.log('DynamicList (getObjects): Missing props fetchObjects:', fetchObjects, 'and/or responseTotalKeysArray:', responseTotalKeysArray, 'and/or responseObjectKeysArray:', responseObjectKeysArray, '.');
      }
      return Promise.resolve('');
    }
  }
  
  const getObjectsFailedWithError = (aError) => {
    console.error('DynamicList (getObjectsFailedWithError): Failed with error:', aError);
    // notifyFetchError(aError);
    setFetchFailed(false);
  }
  
  const fetchIfNotScrollable = () => {
    if(!isShowingVerticalScrollBar() && scrollManager && !scrollManager.hasLoadedAll() && !scrollManager.isLoading()){
      scrollManager.fetchAndReloadWithLimit(false, limit);
    }
  }
  
  const updateObjectsArray = () => {
    setObjectsArray(scrollManager.getList());
    setTimeout(() => {
      if(!isShowingVerticalScrollBar() && scrollManager && !scrollManager.hasLoadedAll()){
        fetchIfNotScrollable();
      }
    }, 20);
  }
  
  const weightSumForColumnsArray = (aColumnsArray) => {
    let returnValue = 0;
    
    if(Array.isArray(aColumnsArray)){
      for(let column of aColumnsArray){
        returnValue += Math.max(column.columnWeight, 1);
      }
    }
    return returnValue;
  }
  
  const widthForColumn = (aScreenWidth, aColumn, aTotalWeightSum) => {
    let returnValue = aScreenWidth;
    let weight = 1;
    let useCustomWidth = false;
    
    if(aColumn !== null && aColumn !== undefined){
      weight = Math.max(aColumn.columnWeight, 1);
      
      if(aColumn.width !== null && aColumn.width !== undefined){
        returnValue = Number(aColumn.width);
        useCustomWidth = true;
      }
    }
    if(!useCustomWidth){
      if(Boolean(menuItemsArray)){
        returnValue = returnValue - menuButtonWidth;
      }
      returnValue = returnValue / Math.max(aTotalWeightSum, 1);
      
      if(aColumn && aColumn.minWidth){
        returnValue = Math.max(returnValue, aColumn.minWidth);
      }
      if(minColumnWidth !== null && minColumnWidth !== undefined){
        returnValue = Math.max(returnValue, minColumnWidth);
      }
      returnValue = returnValue * weight;
      
      if(maxColumnWidth !== null && maxColumnWidth !== undefined){
        returnValue = Math.min(returnValue, maxColumnWidth);
      }
    }
    return returnValue;
  }
  
  const valueForProperties = (aObject, aPropertiesArray) => {
    let returnValue = '';
    
    if(aObject){
      if(Array.isArray(aPropertiesArray)){
        let cascadedObject = aObject;
        
        for(let index = 0; index < aPropertiesArray.length; index += 1){
          let property = aPropertiesArray[index];
          cascadedObject = cascadedObject[property];
          
          if(cascadedObject === null || cascadedObject === undefined){
            if(index < (aPropertiesArray.length - 1)){
              // TODO: Add a flag for rendering errors.
              // console.log('DynamicList (valueForProperties): Properties Array:', aPropertiesArray, 'at index:', index, 'has an invalid property:', property, '.');
            }
            cascadedObject = null;
            break;
          }
        }
        if(cascadedObject !== null && cascadedObject !== undefined){
          returnValue = cascadedObject;
        }
      }
      else{
        console.log('DynamicList (valueForProperties): Properties Array provided:', aPropertiesArray, 'is not an array.');
      }
    }
    else{
      console.log('DynamicList (valueForProperties): Object provided:', aObject, 'is invalid.');
    }
    return returnValue;
  };
  
  const dynamicCellForObject = (aObject, aIndex, aTotalWidth, aShouldClampFirstColumn, aColumnConfigurationsArray) => {
    return <DynamicListCell style={styles.listCell}
                            key={'dynamic-list-cell-' + aObject.id ? aObject.id + '-' + aIndex : aIndex}
                            row={aIndex}
                            width={aTotalWidth}
                            object={aObject}
                            reload={reloadCell}
                            selectCell={selectCell ?
                              () => {
                                selectCell(aObject, aIndex);
                                setSelectedRow(aIndex);
                                setSelectedObject(aObject);
                              }
                              :
                              null
                            }
                            isLoading={isCellLoading}
                            showBorder={showBorder}
                            borderWidth={borderWidth}
                            reloadDelay={reloadDelay}
                            columnsArray={aColumnConfigurationsArray}
                            isSelectedRow={selectedRow === aIndex}
                            menuItemsArray={menuItemsArray}
                            selectedColumn={selectedColumn}
                            clampFirstColumn={aShouldClampFirstColumn}
                            valueForProperties={(aPropertiesArray) => valueForProperties(aObject, aPropertiesArray)}
    />
  };
  
  const applyFiltersToArray = (aArray, aSection = 0) => {
    let returnValue = [...aArray];
    
    if(objectsReduce){
      returnValue = Object.values(returnValue.reduce(objectsReduce, {}));
    }
    if(objectsFilter){
      returnValue = returnValue.filter(objectsFilter);
    }
    if(totalObjects && (objectsFilter || objectsReduce || Array.isArray(fixedObjectsArray))){
      totalObjects(returnValue.length, aSection);
    }
    return returnValue;
  }
  
  const selectedColumnChanged = (aColumn, aAscending) => {
    if((ascending !== aAscending) || (selectedColumn !== aColumn)){
      setAscending(aAscending);
      setSelectedColumn(aColumn);
    }
  };
  
  const isShowingVerticalScrollBar = () => {
    let returnValue = false;
    
    if(scrollRef && scrollRef.current){
      returnValue = scrollRef.current.scrollHeight > scrollRef.current.clientHeight;
    }
    if(returnValue !== verticalScrollBarVisible){
      setVerticalScrollBarVisible(returnValue);
    }
    return returnValue;
  }
  
  const isShowingHorizontalScrollBar = () => {
    let returnValue = false;
    
    if(scrollRef && scrollRef.current){
      returnValue = scrollRef.current.scrollWidth > scrollRef.current.clientWidth;
    }
    return returnValue;
  }
  
  // Render
  
  return (
    <View style={styles.container}
          id={id}
          ref={containerRef}
          onLayout={() => {
            if(containerRef && containerRef.current){
              setWidth(containerRef.current.clientWidth)
            }
          }}>
      <InfiniteScrollManager ref={scrollManagerRef}
                             fail={(aError) => getObjectsFailedWithError(aError)}
                             success={() => updateList()}
                             getItems={(aLimit, aOffset) => getObjects(aLimit, aOffset)}
      />
      
      {explanationKey ?
        <View style={styles.explanationText}>
          {t(explanationKey)}
        </View>
        :
        null
      }
      
      <View style={styles.titleView}>
        {backButtonClicked || showTotal || headerView ?
          <View style={styles.titleHeaderView}>
            {backButtonClicked ?
              <View tyle={styles.backButton}
                    onClick={() => backButtonClicked()}>
                <Text>
                  {'< ' + t(backKey)}
                </Text>
              </View>
              :
              null
            }
            
            {showTotal ?
              <View>
                <Text style={styles.title}>
                  {t(totalObjectsKey)} - {(objectsFilter || objectsReduce || Array.isArray(fixedObjectsArray)) ? filteredObjectsArray.length : total}
                </Text>
              </View>
              :
              null
            }
            
            {headerView ?
              <View style={styles.headerView}>
                {headerView}
              </View>
              :
              null
            }
          </View>
          :
          null
        }
        
        {showSearchBar ?
          <View style={styles.searchContainer}>
            <LabeledInput style={styles.searchInput}
                          text={searchString}
                          delay={300}
                          change={(aText) => {
                            setSearching(true);
                            setSearchString(aText);
                          }}
                          loading={searching}
                          marginBottom={0}
                          placeholder={t(searchBarPlaceHolderKey)}
                          addButtonClicked={addButtonClicked ?
                            () => {
                              setSelectedRow(-1);
                              setSelectedObject(null);
                              addButtonClicked();
                            }
                            :
                            null
                          }
            />
          </View>
          :
          null
        }
        
        {!showSearchBar && addButtonClicked ?
          <IconButton name={'add-circle-outline'}
                      colour={'#999999'}
                      buttonClicked={() => {
                        setSelectedRow(-1);
                        setSelectedObject(null);
                        addButtonClicked();
                      }}
          />
          :
          null
        }
      </View>
      
      {subHeaderView ?
        <View style={styles.subHeaderView}>
          {subHeaderView}
        </View>
        :
        null
      }
      
      <View style={styles.itemContainer}
           id={id + '-objects-list'}
           ref={scrollRef}>
        <DynamicListHeader width={totalWidth}
                           menuWidth={(menuItemsArray && menuItemsArray.length > 0) ? menuButtonWidth : 0}
                           showBorder={showBorder}
                           borderWidth={borderWidth}
                           canSortList={canSortList}
                           addButtonClicked={addColumnButtonClicked}
                           clampFirstColumn={shouldClampFirstColumn}
                           titleObjectsArray={titleObjectsArray}
                           initialSelectedColumn={selectedColumn}
                           onSelectedColumnChanged={selectedColumnChanged}
        />
        
        <View style={styles.scrollContainer}>
          {fixedObjectsArray ?
            <View style={styles.cellScrollContainer}>
              {sectionHeadersArray.length > 0 ?
                (sectionHeadersArray.map((aSectionHeader, aSectionIndex) =>
                  <View>
                    <View style={styles.scrollTitleClamp}>
                      {t(aSectionHeader) + ' - ' + filteredObjectsArray[aSectionIndex].length}
                    </View>
                    
                    {filteredObjectsArray[aSectionIndex].map((aObject, aIndex) => dynamicCellForObject(aObject, aIndex, totalWidth, shouldClampFirstColumn, columnConfigurationsArray))}
                  </View>
                ))
                :
                (filteredObjectsArray.map((aObject, aIndex) => dynamicCellForObject(aObject, aIndex, totalWidth, shouldClampFirstColumn, columnConfigurationsArray)))
              }
            </View>
            :
            <>
              {/*<ScrollView style={styles.cellScrollContainer}>*/}
                <FlatList style={{overflow:'visible'}}
                          contentContainerStyle={{flexGrow:1}}
                          data={objectsArray}
                          renderItem={({item, index}) => dynamicCellForObject(item, index, totalWidth, shouldClampFirstColumn, columnConfigurationsArray)}
                          keyExtractor={(aItem, aIndex) => {return 'dynamic-cell-' + (aItem && aItem.id ? aItem.id : aIndex)}}
                          onEndReached={() => scrollManager.fetchAndReloadWithLimit(false, limit)}
                          initialNumToRender={limit}
                          ListEmptyComponent={() => (<View></View>)}
                          ListFooterComponent={() => (<View></View>)}
                          ListHeaderComponent={() => (<View></View>)}
                          onEndReachedThreshold={0.2}
                />
              {/*</ScrollView>*/}
              
              {/*<InfiniteScroll style={{overflow:'unset', width:totalWidth + 'px'}}*/}
              {/*                next={() => this.scrollManager.fetchAndReloadWithLimit(false, limit)}*/}
              {/*                loader={<Loading loading={scrollManager.isLoading()}/>}*/}
              {/*                hasMore={!this.scrollManager.hasLoadedAll()}*/}
              {/*                dataLength={this.scrollManager.totalCount()}*/}
              {/*                scrollableTarget={id + '-objects-list'}>*/}
              {/*  {filteredObjectsArray && filteredObjectsArray.length > 0 ?*/}
              {/*    <View className="dynamic-list-cell-scroll-container">*/}
              {/*      {filteredObjectsArray.map((aObject, aIndex) => dynamicCellForObject(aObject, aIndex, totalWidth, shouldClampFirstColumn, columnConfigurationsArray))}*/}
              {/*    </View>*/}
              {/*    :*/}
              {/*    null*/}
              {/*  }*/}
              {/*  {objectsArray && objectsArray.length > 0 ?*/}
              {/*    <View style={styles.cellScrollContainer}>*/}
              {/*      {objectsArray.map((aObject, aIndex) => dynamicCellForObject(aObject, aIndex, totalWidth, shouldClampFirstColumn, columnConfigurationsArray))}*/}
              {/*    </View>*/}
              {/*    :*/}
              {/*    null*/}
              {/*  }*/}
              {/*</InfiniteScroll>*/}
              
              {/*{fetchFailed ?*/}
              {/*  <LoadFailed retry={() => this.setState({fetchFailed:false}, () => this.scrollManager.fetchAndReloadWithLimit(false, limit))}*/}
              {/*              message={i18next.t('FETCH_FAILED')}*/}
              {/*  />*/}
              {/*  :*/}
              {/*  null*/}
              {/*}*/}
            </>
          }
        </View>
      </View>
      
      {/*<CustomMenu trigger={(triggerProps) => {*/}
      {/*  return testRef.current;*/}
      {/*  // return menuAnchorElement ? [menuAnchorElement] : null;*/}
      {/*}}*/}
      {/*            menuAnchorElement={menuAnchorElement}/>*/}
  
      {/*<Menu id={id + '-dynamic-list-menu'}*/}
      {/*      open={Boolean(menuAnchorElement)}*/}
      {/*      style={{zIndex:9999}}*/}
      {/*      onClose={() => this.setState({selectedRow:-1, menuAnchorElement:null}, () => setTimeout(() => this.setState({selectedObject:null}), 200))}*/}
      {/*      anchorEl={menuAnchorElement}*/}
      {/*      keepMounted>*/}
      {/*  {menuItemsArray && menuItemsArray.length > 0 ?*/}
      {/*    (menuItemsArray.map((aMenuItem) => (*/}
      {/*      (aMenuItem.isValid === null || aMenuItem.isValid === undefined || aMenuItem.isValid(selectedObject) ?*/}
      {/*          <MenuItem key={id + '-dynamic-list-menu-item-' + aMenuItem.title}*/}
      {/*                    style={{position:'relative'}}*/}
      {/*                    onClick={(aEvent) => {*/}
      {/*                      aEvent.preventDefault();*/}
      {/*                      aEvent.stopPropagation();*/}
      {/*            */}
      {/*                      if(aMenuItem.clickAction){*/}
      {/*                        aMenuItem.clickAction(selectedObject, selectedRow, aEvent, menuAnchorElement);*/}
      {/*                      }*/}
      {/*                      this.setState({selectedRow:-1, menuAnchorElement:null}, () => setTimeout(() => this.setState({selectedObject:null}), 200))*/}
      {/*                    }}>*/}
      {/*            <Translation>{(t, {i18n}) => t(aMenuItem.title)}</Translation>*/}
      {/*          </MenuItem>*/}
      {/*          :*/}
      {/*          null*/}
      {/*      )*/}
      {/*    )))*/}
      {/*    :*/}
      {/*    null*/}
      {/*  }*/}
      {/*  */}
      {/*  <MenuItem key={id + '-dynamic-list-menu-item-cancel'}*/}
      {/*            style={{position:'relative'}}*/}
      {/*            onClick={(aEvent) => {*/}
      {/*              aEvent.preventDefault();*/}
      {/*              aEvent.stopPropagation();*/}
      {/*    */}
      {/*              if(menuCancel){*/}
      {/*                menuCancel();*/}
      {/*              }*/}
      {/*              this.setState({selectedRow:-1, menuAnchorElement:null}, () => setTimeout(() => this.setState({selectedObject:null}), 200));*/}
      {/*            }}>*/}
      {/*    <Translation>{(t, {i18n}) => t('CANCEL')}</Translation>*/}
      {/*  </MenuItem>*/}
      {/*</Menu>*/}
    </View>
  );
}

const allStyles = StyleSheet.create({
  title:{
    height:38.5,
    display:'flex',
    fontWeight:'bold',
    alignItems:'center',
    paddingLeft:10
  },
  container:{
    width:'100%',
    height:'100%',
    display:'flex',
    overflow:'hidden',
    position:'relative',
    flexDirection:'column'
  },
  titleView:{
    width:'100%',
    display:'flex',
    fontSize:'16px',
    marginTop: 10,
    alignItems:'center',
    marginBottom:10,
    flexDirection:'row',
    justifyContent:'space-between'
  },
  headerView:{
    width:'auto',
    height:38.5,
    display:'flex',
    flexGrow: 1,
    flexShrink: 1,
    flexDirection:'row'
  },
  backButton:{
    cursor:'pointer',
    marginRight:20
  },
  searchInput:{
    width:'100%'
  },
  itemContainer:{
    position:'relative',
    flexGrow:1,
    marginTop:5,
    overflowX:'auto',
    overflowY:'auto',
    flexShrink:1,
    backgroundColor:'white'
  },
  searchContainer:{
    width:250,
    display:'flex',
    alignItems:'center',
    paddingRight:10,
    justifyContent:'flex-end'
  },
  listCell:{
    width:'100%',
    height:'auto',
    position:'relative'
  },
  explanationText:{
    color:'rgba(100,100,100,1)',
    width:'calc(100% - 40px)',
    fontSize:'14px',
    marginLeft:20,
    marginRight:20,
    marginBottom:20
  },
  subHeaderView:{
    width:'calc(100% - 40px)',
    display:'flex',
    marginTop:10,
    alignItems:'center',
    marginLeft:20,
    marginRight:20
  },
  scrollContainer:{
    position:'relative'
  },
  scrollTitleClamp:{
    top:40,
    left:0,
    color:'white',
    right:0,
    width:'100%',
    zIndex:3,
    display:'block',
    padding:5,
    fontSize:'14px',
    position:'sticky',
    fontWeight:600,
    paddingLeft:20,
    backgroundColor:'rgba(100,100,100,1)'
  },
  titleHeaderView:{
    height:'100%',
    display:'flex',
    flexGrow:1,
    maxWidth:'100%',
    minWidth:'50%',
    alignItems:'center',
    flexShrink:1,
    flexDirection:'row',
    justifyContent:'flex-start'
  },
  cellScrollContainer:{
    display:'unset',
    position:'relative'
  }
});
