import {Text, View, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, ActivityIndicator} from 'react-native';
import Icon from "@expo/vector-icons/MaterialIcons";

const IconButton = ({name, style, padding = 0, marginRight = 0, buttonClicked, size = 20, colour = '#000000', loading = false, backgroundColour = '#00000000'}) => {
  
  // Instance Variables
  
  
  
  // Methods
  
  
  
  // Render
  
  return (
    loading ?
      <ActivityIndicator size="small"
                         color={colour}
      />
      :
      <Icon.Button name={name}
                   size={size}
                   style={[{padding:padding, marginRight:marginRight}, style]}
                   color={colour}
                   onPress={(aEvent) => {
                     if(buttonClicked){
                       aEvent.preventDefault();
                       aEvent.stopPropagation();
                       buttonClicked(aEvent);
                     }
                   }}
                   underlayColor={'#00000000'}
                   iconStyle={{cursor:'pointer'}}
                   backgroundColor={backgroundColour}
      />
  );
}

export default IconButton

const styles = StyleSheet.create({
  button:{
    width:40,
    color:'#00000000',
    height:40,
    backgroundColor:'#00000000'
  },
  container:{
  
  }
});
