import { StyleSheet, Text, View } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import {Themes} from "../Constants/ThemeColours";
import {useAuthentication} from "../Contexts/Authentication";

const Errors = ({error, warning}) => {
  
  // Instance Variables
  
  const {themedStyle} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  // Render
  
  return (
    error || warning ?
      <View style={styles.container}>
        {error ?
          <>
            <Icon name="error"
                  size={20}
                  color="#FF0000"
            />
            
            <Text style={styles.titleError}>
              {error}
            </Text>
          </>
          :
          null
        }
        
        {warning ?
          <>
            <Icon name="warning"
                  size={20}
                  color="#00FF00"
            />
            
            <Text style={styles.titleWarning}>
              {warning}
            </Text>
          </>
          :
          null
        }
      </View>
      :
      null
  );
}

export default Errors

const allStyles = StyleSheet.create({
  container:{
    flex:1,
    marginTop:5,
    alignItems:'center',
    flexDirection:'row'
  },
  title:{
    fontSize:14,
    fontWeight:'bold',
    marginLeft:5
  },
  titleError:{
    color:Themes.light.failure,
    fontSize:14,
    fontWeight:'bold',
    marginLeft:5
  },
  titleWarning:{
    color:Themes.light.warning,
    fontSize:14,
    fontWeight:'bold',
    marginLeft:5
  }
});
