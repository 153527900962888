import {StyleSheet, Text, View} from "react-native";
import React, {useEffect, useState} from "react";
import Logo from "../Files/Assets/Images/Light/Coverdale_Clinics.jpg";
import Errors from "../Files/Custom UI/Errors";
import Shadow from "../Files/Custom UI/Shadow";
import {Image} from "native-base";
import {Themes} from "../Files/Constants/ThemeColours";
import CustomLink from "../Files/Custom UI/CustomLink";
import LogoFrench from "../Files/Assets/Images/Light/Coverdale_Clinics_Fr.png";
import LabeledInput from "../Files/Custom UI/Inputs/LabeledInput";
import ColouredButton from "../Files/Custom UI/Buttons/ColouredButton";
import {useTranslation} from "react-i18next";
import {useAuthentication} from "../Files/Contexts/Authentication";
import {emailIsValid, errorMessageFromServerError, passwordIsValid} from "../Files/Helpers/Helpers";

export default function Login(){

  // Instance Variables

  const {t, i18n} = useTranslation();
  const {signIn, colorScheme, themedStyle} = useAuthentication();
  const styles = themedStyle(allStyles);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');

  // Effects

  useEffect(() => {
    setError('');
  }, [email, password]);

  // Methods

  const login = () => {
    if(emailIsValid(email)){
      if(passwordIsValid(password)){
        signIn(email, password).then((newResult) => {
          // TODO: Add a toast.
        }, (newError) => {
          setError(errorMessageFromServerError(newError));
        });
      }
      else{
        setError(t('INVALID_PASSWORD_ERROR'));
      }
    }
    else{
      if(passwordIsValid(password)){
        setError(t('INVALID_EMAIL'));
      }
      else{
        setError(t('INVALID_EMAIL_AND_PASSWORD'));
      }
    }
  };

  // Render

  return (
    <View style={styles.container}>
      <Shadow>
        <View style={styles.main}>
          <View style={styles.logo}>
            <Image style={{display:(i18n.language === 'en' ? 'block' : 'none'), visibility:(i18n.language === 'en' ? 'visible' : 'hidden')}}
                   width={350}
                   height={129}
                   source={Logo}
            />
    
            <Image style={{display:(i18n.language === 'en' ? 'none' : 'block'), visibility:(i18n.language === 'en' ? 'hidden' : 'visible')}}
                   width={350}
                   height={118}
                   source={LogoFrench}
            />
    
            {/*TODO: Re-enable once the logo's are changes to SVG's.*/}
            {/*{i18n.language === 'en' ?*/}
            {/*  <Logo width={350}*/}
            {/*        height={100}*/}
            {/*  />*/}
            {/*  :*/}
            {/*  <LogoFrench width={350}*/}
            {/*              height={100}*/}
            {/*  />*/}
            {/*}*/}
          </View>

          <Text style={styles.title}>
            {t('LOGIN')}
          </Text>

          <Errors error={emailIsValid(email) && passwordIsValid(password) ? error: null}/>

          <LabeledInput text={email}
                        error={error.length > 0 && !emailIsValid(email) ? t('INVALID_EMAIL') : null}
                        label={t('EMAIL')}
                        change={setEmail}
                        submit={login}
                        placeholder={t('EMAIL')}
                        keyboardType={'email-address'}
          />

          <LabeledInput text={password}
                        error={error.length > 0 && (!passwordIsValid(password) || password.length === 0) ? t('INVALID_PASSWORD_ERROR') : null}
                        label={t('PASSWORD')}
                        change={setPassword}
                        submit={login}
                        placeholder={t('PASSWORD')}
                        secureTextEntry={true}
          />

          <ColouredButton title={t('LOGIN')}
                          colour={Themes[colorScheme].primary}
                          onPress={login}
                          textColour={Themes[colorScheme].text}
                          pressedColour={Themes[colorScheme].secondary}
                          highlightedColour={Themes[colorScheme].secondary}
                          accessibilityLabel={t('LOGIN_BUTTON_ACCESSIBILITY')}
          />

          <CustomLink style={styles.forgotPassword}
                      href={'/forgot'}
                      label={t('FORGOT_PASSWORD')}
          />
        </View>
      </Shadow>
    </View>
  );
}

const allStyles = StyleSheet.create({
  logo:{
    width:350,
    marginLeft:25
  },
  main:{
    width:500,
    padding:40,
    maxWidth:960,
    borderRadius:10,
    justifyContent:'center',
    marginHorizontal:'auto',
    backgroundColor:'white'
  },
  title:{
    color:Themes.light.black,
    fontSize:32,
    marginTop:20,
    textAlign:'center',
    fontWeight:'bold',
    paddingBottom:20
  },
  container:{
    flex:1,
    padding:24,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'white'
  },
  forgotPassword:{
    paddingTop:3,
    paddingRight:5,
    marginLeft:'auto'
  }
});
