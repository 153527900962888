import {useState} from "react";
import Appointment from "../../app/appointment";
import GeneralModal from "./GeneralModal";
import {formatUserName} from "../Helpers/Helpers";
import {useTranslation} from "react-i18next";
import {useAuthentication} from "../Contexts/Authentication";

const AppointmentModal = ({open, save, close, patientId, clinicId, appointment, availability}) => {
  
  // Instance Variables
  
  const {t} = useTranslation();
  
  const {api, user, themedStyle, toastMessage, selectedPatient, clearSelectedPatient} = useAuthentication();
  
  const [savePressed, setSavePressed] = useState(false);
  
  // Methods
  
  const description = () => {
    let returnValue = null;
    
    if(appointment && appointment.patient){
      returnValue = t('FOR_PATIENT', {aName:formatUserName(appointment.patient)});
    }
    else if(selectedPatient){
      returnValue = t('FOR_PATIENT', {aName:formatUserName(selectedPatient)});
    }
    return returnValue;
  }
  
  // Render
  
  return (
    <GeneralModal open={open}
                  save={() => setSavePressed(true)}
                  close={close}
                  title={appointment ? 'EDIT_APPOINTMENT' : 'CREATE_APPOINTMENT'}
                  description={description()}>
      <Appointment clinicId={clinicId}
                   patientId={patientId}
                   appointment={appointment}
                   savePressed={savePressed}
                   availability={availability}
                   saveFinished={() => {
                     setSavePressed(false);
                     
                     if(save){
                       save();
                     }
                   }}
                   api={api}
                   user={user}
                   themedStyle={themedStyle}
                   toastMessage={toastMessage}
                   selectedPatient={selectedPatient}
                   clearSelectedPatient={clearSelectedPatient}
      />
    </GeneralModal>
  );
}

export default AppointmentModal
