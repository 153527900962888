{
  "ACCEPT":"Accept",
  "ACCEPT_TERMS_ERROR":"Failed to accept terms with error: {{aError}}.",
  "ADDRESS_LINE_1":"Address Line 1",
  "ADDRESS_LINE_2":"Address Line 2",
  "ALLERGIES":"Allergies",
  "APPOINTMENT":"Appointment",
  "APPOINTMENT_ACTIONS":"Appointment Actions",
  "APPOINTMENTS":"Appointments",
  "APPOINTMENT_CREATED":"Successfully created appointment!",
  "APPOINTMENT_UPDATED":"Successfully updated appointment!",
  "APPOINTMENT_CREATED_ERROR":"Failed to create appointment with error: {{aError}}.",
  "APPOINTMENT_UPDATED_ERROR":"Failed to update appointment with error: {{aError}}.",
  "AVAILABLE_TIMES":"Available Times",
  "BACK":"Back",
  "BOOKED_APPOINTMENTS":"Booked Appointments",
  "CANCEL":"Cancel",
  "CANCEL_APPOINTMENT":"Cancel Appointment",
  "CHANGE":"Change",
  "CHANGE_PASSWORD":"Change Password",
  "CHANGE_PASSWORD_BUTTON_ACCESSIBILITY":"Change Password Button",
  "CITY":"City",
  "CLINIC":"Clinic",
  "CONFIRM":"Confirm",
  "CONFIRM_DESCRIPTION":"Are you sure you want to proceed?",
  "CONFIRM_EMAIL":"Confirm Email",
  "CONFIRM_EMAIL_BUTTON_ACCESSIBILITY":"Confirm Email Button",
  "CONFIRM_NEW_PASSWORD":"Confirm New Password",
  "CONTACT":"Contact",
  "CONTACT_DESCRIPTION":"Connect with Labtician - Request additional appointments for a particular clinic. Report an issue with the ECP Network. Provide a suggestion for improvement. Or anything else!",
  "CONTACT_EMAIL":"Contact Email",
  "CONTACT_US_BUTTON_ACCESSIBILITY":"Contact Us Button",
  "COPYRIGHT":"© Endoret {{aYear}}",
  "COUNTRY":"Country",
  "CREATE":"Create",
  "CREATE_APPOINTMENT":"Create Appointment",
  "CURRENT_PASSWORD":"Current Password",
  "CURRENT_PASSWORD_DOES_NOT_MATCH_ERROR":"You current password does not match the confirmation password.",
  "DATE":"Date",
  "DATE_OF_BIRTH":"Date of Birth",
  "DECLINE":"Decline",
  "DELETE_APPOINTMENT":"Remove Appointment",
  "DELETE_APPOINTMENT_DESCRIPTION":"Are you sure you want to remove the appointment on {{aDate}} for {{aName}}?",
  "DELETE_APPOINTMENT_ERROR":"Failed to remove appointment with error: {{aError}}.",
  "DELETE_APPOINTMENT_SUCCESS":"Appointment on {{aDate}} for {{aName}} has been removed successfully!",
  "DELETE_APPOINTMENT_TITLE":"Remove Appointment?",
  "DELETE_PATIENT":"Remove Patient",
  "DELETE_PATIENT_DESCRIPTION":"Are you sure you want to remove the patient {{aName}}?",
  "DELETE_PATIENT_ERROR":"Failed to remove {{aName}} with error: {{aError}}.",
  "DELETE_PATIENT_SUCCESS":"{{aName}} has been removed successfully!",
  "DELETE_PATIENT_TITLE":"Remove Patient?",
  "DOES_NOT_IDENTIFY":"Does not identify",
  "EDIT":"Edit",
  "EDIT_APPOINTMENT":"Edit Appointment",
  "EDIT_APPOINTMENT_MESSAGE":"Select an alternative appointment date from the available options.",
  "EDIT_PATIENT":"Edit Patient",
  "EMAIL":"Email",
  "EMAIL_ADMIN":"Email Admin",
  "EMAIL_CONFIRMATION_ERROR":"Failed to confirm your email address with error: {{aError}}.",
  "EMAIL_CONFIRMED":"Your email address has been successfully confirmed!",
  "EMAIL_SENT":"Email Sent!",
  "END_AT":"End At",
  "ENDORET_CLINIC":"Endoret Clinic",
  "ENGLISH":"English",
  "ENTER_MESSAGE_HERE":"Enter Message Here",
  "FAX_NUMBER":"Fax Number",
  "FEMALE":"Female",
  "FIRST_NAME":"First Name",
  "FOR_PATIENT":"For Patient: {{aName}}",
  "FORGOT_PASSWORD":"Forgot Password",
  "FORGOT_PASSWORD_BUTTON_ACCESSIBILITY":"Send Forgot Password Email Button",
  "FORGOT_PASSWORD_EMAIL_SEND_ERROR":"Failed to send email with error: {{aError}}.",
  "FORGOT_PASSWORD_EMAIL_SENT":"An email has been sent to the above address. Please follow the directions to reset your password.",
  "FRENCH":"French",
  "GENDER":"Gender",
  "INVALID_EMAIL":"Invalid email address.",
  "INVALID_EMAIL_AND_PASSWORD":"Invalid email and password.",
  "INVALID_PASSWORD":"Invalid password.",
  "INVALID_PASSWORD_ERROR":"Your password must be at least 8 characters, and contain at least 3 of the following: 1 uppercase (A-Z), 1 lowercase (a-z), 1 number (0-9), and/or 1 symbol (e.g.: !, $, #, or %).",
  "INVALID_PHONE_NUMBER":"Invalid phone number.",
  "IS_AUTO_IMMUNE_DISEASE":"Is Auto Immune Disease",
  "IS_BLOOD_DRAW":"Is Blood Draw",
  "IS_REPEAT_BLOOD_DRAW":"Is Repeat Blood Draw",
  "IS_THERMAL_TREATMENT":"Is Thermal Treatment",
  "IS_USING_ANTI_COAGULANTS":"Is Using Anti Coagulants",
  "IS_USING_PRIVATE_INSURANCE":"Is Using Private Insurance",
  "LAST_NAME":"Last Name",
  "LICENSE_NUMBER":"License Number",
  "LOGIN":"Login",
  "LOGIN_BUTTON_ACCESSIBILITY":"Login Button",
  "LOGOUT":"Logout",
  "LOGOUT_MESSAGE":"You have logged out.",
  "LOGOUT_SESSION_MESSAGE":"Your session has timed out. Please login again.",
  "MALE":"Male",
  "MESSAGE_SEND_ERROR":"Message failed to send with error: {{aError}}.",
  "MESSAGE_SENT":"Message sent! We'll get back to you soon.",
  "NAME":"Name",
  "NEW_APPOINTMENT":"New Appointment",
  "NEW_PASSWORD":"New Password",
  "NEW_PASSWORD_SAME_AS_OLD_PASSWORD_ERROR":"Your new password is the same as your current password.",
  "NEW_PATIENT":"New Patient",
  "NEW_PATIENT_BUTTON_ACCESSIBILITY":"New Patient",
  "NEW_START_AT":"New Start At",
  "NUMBER_OF_BLOOD_DRAW_REFILLS":"Number of Blood Draw Refills",
  "ORIGINAL_START_AT":"Original Start At",
  "PAID":"Paid",
  "PASSWORD":"Password",
  "PASSWORD_CHANGE_REQUIRED":"You must change your password.",
  "PASSWORD_CHANGED":"Your password has been changed successfully!",
  "PASSWORD_CHANGED_ERROR":"Failed to change your password with error: {{aError}}.",
  "PASSWORD_RESET":"Your password has been reset!",
  "PASSWORD_RESET_ERROR":"Failed to reset your password with error: {{aError}}.",
  "PATIENT":"Patient",
  "PATIENT_ACTIONS":"Patient Actions",
  "PATIENTS":"Patients",
  "PATIENT_CREATE_ERROR":"Failed to create patient with error: {{aError}}.",
  "PATIENT_CREATED":"Successfully created patient {{aName}}!",
  "PATIENT_ID":"Patient ID",
  "PATIENT_PROFILE":"Patient Profile",
  "PATIENT_UPDATE_BUTTON_ACCESSIBILITY":"Update Patient",
  "PATIENT_UPDATE_ERROR":"Failed to update patient with error: {{aError}}.",
  "PATIENT_UPDATED":"Successfully updated patient {{aName}}!",
  "PAY_FOR_APPOINTMENT":"Pay for Appointment",
  "PAYMENT":"Payment",
  "PAYMENT_CANCELLED":"Payment cancelled. Please reload the page to start over.",
  "PAYMENT_COMPLETED":"Thank you for your payment.\nPlease click the link below to access more information about PRGF.",
  "PAYMENT_ERROR":"Payment failed with error: {{aError}}.",
  "PAYMENT_FAILED":"Payment failed. Please reload the page to start over.",
  "PAYMENT_SIGNED_ID_ERROR":"Purchase not found. Please contact your Endoret Admin.",
  "PHONE_NUMBER":"Phone Number",
  "POSTAL_CODE":"Postal Code",
  "PREFERRED_LANGUAGE":"Preferred Language",
  "PRGF":"PLASMA RICH IN GROWTH FACTORS",
  "PROFILE":"Profile",
  "PROFILE_UPDATE_BUTTON_ACCESSIBILITY":"Update Profile",
  "PROVINCE":"Province",
  "REFERRING_PHYSICIAN":"Referring Physician",
  "REFILLS_REMAINING":"Refills Remaining",
  "RESET":"Reset",
  "RESET_PASSWORD":"Reset Password",
  "RESET_PASSWORD_BUTTON_ACCESSIBILITY":"Reset Password Button",
  "SAVE":"Save",
  "SEARCH":"Search",
  "SECONDARY_EMAIL":"Secondary Email",
  "SEND":"Send",
  "SENT":"Sent",
  "SIGNATURE":"Signature",
  "START_AT":"Start At",
  "THERMAL_TREATMENT":"Thermal Treatment",
  "THERMAL_TREATMENT_AVAILABLE":"Thermal Treatment is available.",
  "TOTAL":"Total",
  "UPCOMING_APPOINTMENT":"Upcoming Appointment",
  "UPDATE":"Update"
}
