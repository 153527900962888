import {localUTCDayFromDateString} from "./DateHelpers";

export function appointmentsOnDate(aArray, aDate){
  let returnValue = [];
  
  if(aDate){
    for(let appointment of aArray){
      if(aDate === localUTCDayFromDateString(appointment.startAt)){
        returnValue.push(appointment);
      }
    }
  }
  else{
    returnValue = aArray;
  }
  return returnValue;
}
