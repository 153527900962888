import { StyleSheet, Text, View } from "react-native";
import {useCallback, useState} from "react";

const Label = ({label, style}) => {
  
  // Instance Variables
  
  
  
  // Methods
  
  
  
  // Render
  
  return (
    <Text style={[styles.text, style]}>
      {label}
    </Text>
  );
}

export default Label

const styles = StyleSheet.create({
  text: {
    fontSize:14,
    fontWeight:'bold',
  }
});
