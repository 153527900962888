import {View} from "react-native";
import {useTranslation} from "react-i18next";
import {Box, Divider, Menu} from "native-base";

const CustomMenu = ({trigger, width = 200, dividerHeight = 3, menuItemsArray = []}) => {
  
  // Instance Variables
  
  const {t} = useTranslation()
  
  // Render
  
  return (
    <Box w="90%"
         alignItems="center">
      <Menu w={width}
            trigger={trigger}>
        {menuItemsArray.map((aMenuItem, aIndex) => {
          return aMenuItem.groupTitle && aMenuItem.groupTitle.length > 0 ?
            <View key={'menu-group-title-' + aMenuItem.groupTitle}>
              <Menu.Group title={t(aMenuItem.groupTitle)}>
                {aMenuItem.array.map((aGroupedMenuItem) => {
                  return (
                    <Menu.Item key={'menu-group-item-' + aMenuItem.groupTitle + '-' + aGroupedMenuItem.title}
                               onPress={aGroupedMenuItem.clickAction}>
                      {t(aGroupedMenuItem.title)}
                    </Menu.Item>
                  )}
                )}
              </Menu.Group>
      
              {aIndex < (menuItemsArray.length - 1) ?
                <Divider w="100%"
                         mt={dividerHeight}
                />
                :
                null
              }
            </View>
            :
            <Menu.Item key={'menu-item-' + aMenuItem.title}
                       onPress={aMenuItem.clickAction}>
              {t(aMenuItem.title)}
            </Menu.Item>
        })
        }
      </Menu>
    </Box>
  );
}

export default CustomMenu
