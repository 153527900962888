import {ActivityIndicator, StyleSheet, Text, useColorScheme, View} from "react-native";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Icon from '@expo/vector-icons/MaterialIcons';
import IconButton from "../Custom UI/Buttons/IconButton";
import MenuButton from "../Custom UI/Buttons/MenuButton";
import {dateFromDateString} from "../Helpers/DateHelpers";
import {Themes} from "../Constants/ThemeColours";
import {useAuthentication} from "../Contexts/Authentication";
import {containsValidMenuItems, validMenuItems} from "../Helpers/Helpers";

export default function DynamicListCell({
                                          row,
                                          width,
                                          object,
                                          reload,
                                          isLoading,
                                          selectCell,
                                          columnsArray,
                                          isSelectedRow,
                                          menuItemsArray,
                                          selectedColumn,
                                          valueForProperties,
                                          showBorder = false,
                                          borderWidth = 0.5,
                                          reloadDelay = 60000,
                                          clampFirstColumn = true
                                        }){
  
  // Instance Variables
  
  const {t} = useTranslation();
  const {colorScheme, themedStyle} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  const [showMenu, setShowMenu] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [reloadingCell, setReloadingCell] = useState({});
  
  // Effects
  
  useEffect(() => {
    setShowMenu(containsValidMenuItems(menuItemsArray, object));
  }, [object, setShowMenu, menuItemsArray]);
  
  useEffect(() => {
    setMenuItems(validMenuItems(menuItemsArray, object, row));
  }, [row, object, setMenuItems, menuItemsArray]);
  
  // Methods
  
  const shouldReload = (aObject, aRow) => {
    if(reload && !reloadingCell[aRow]){
      reloadingCell[aRow] = true;
      setReloadingCell(reloadingCell);
      setTimeout(() => {
        reloadingCell[aRow] = false;
        setReloadingCell(reloadingCell);
        reload(aObject, aRow);
      }, reloadDelay);
    }
    return true;
  };
  
  const flexForWidth = (aColumnWidth) => {
    return '0 0 ' + aColumnWidth.toString() + 'px';
  };
  
  // Render
  
  // TODO: Figure out dates.
  
  return (
    <View style={[
      styles.container,
      (isSelectedRow ? styles.selectedRow : {}),
      {width:width}
    ]}
          key={'dynamic-cell-column-key-' + row}
          onClick={selectCell ? () => selectCell() : null}>
        {columnsArray.map((column, index) => (
          <View style={[
            styles[((index === selectedColumn) ? 'selected' : 'text')],
            (clampFirstColumn && (index === 0) ? styles.clamped : {}),
            (showBorder ? styles.border : {}),
            {width:column.width, flex:flexForWidth(column.width)},
            {
              borderLeftWidth:index === 0 ? borderWidth : 0,
              borderRightWidth:clampFirstColumn && index === 0 ? 2 * borderWidth : borderWidth, borderBottomWidth:borderWidth
            }
          ]}
                id={'dynamic-list-cell-' + row + '-' + index}
                key={'dynamic-cell-column-key-' + (column.propertiesArray && column.propertiesArray.length > 0 ? valueForProperties(column.propertiesArray) : '') + '-' + index}
                onClick={column.select ? () => column.select(object, row) : null}>
              {column.translationPrefix && column.translationPrefix.length > 0 ?
                column.propertiesArray && column.propertiesArray.length > 0 ?
                  valueForProperties(column.propertiesArray).length > 0 ?
                    <Text style={[styles[((index === selectedColumn) ? styles.selectedText : styles.innerText)]]}>
                      {t(column.translationPrefix + valueForProperties(column.propertiesArray))}
                    </Text>
                    :
                    null
                  :
                  <Text style={[styles[((index === selectedColumn) ? styles.selectedText : styles.innerText)]]}>
                    {t(column.translationPrefix)}
                  </Text>
                :
                column.dateFormat && column.dateFormat.length > 0 ?
                  <Text style={[styles[((index === selectedColumn) ? styles.selectedText : styles.innerText)]]}>
                    {dateFromDateString(valueForProperties(column.propertiesArray), column.dateFormat)}
                  </Text>
                  :
                  column.templateCell ?
                    <View style={styles.template}>
                      {column.templateCell(object, row)}
                    </View>
                    :
                    column.valueFormatter ?
                      <Text style={[
                        styles.propertiesArray,
                        ((index === selectedColumn) ? styles.selectedText : styles.innerText)]}>
                        {column.valueFormatter(valueForProperties(column.propertiesArray), object, row)}
                      </Text>
                      :
                      valueForProperties(column.propertiesArray) === true ?
                        <View style={styles.checkmark}>
                          <Icon name="check-circle"
                                size={20}
                                color={Themes[colorScheme].success}
                          />
                        </View>
                        :
                        <View style={styles.propertiesArray}>
                          {valueForProperties(column.propertiesArray) === false ?
                            <View style={styles.checkmark}>
                              {/*<Icon name="error"*/}
                              {/*      size={20}*/}
                              {/*      color={Themes[colorScheme].failure}*/}
                              {/*/>*/}
                            </View>
                            :
                            <Text style={[((index === selectedColumn) ? styles.selectedText : styles.innerText)]}>
                              {valueForProperties(column.propertiesArray)}
                            </Text>
                          }
                        </View>
              }
            </View>
        ))}
      
      {showMenu ?
        <View style={[
          styles.loadingContainer,
          (showBorder ? styles.border : {}),
          {borderBottomWidth:borderWidth, borderRightWidth:borderWidth}
        ]}>
              <MenuButton colour={'#444444'}
                          loading={isLoading && isLoading(object, row) && shouldReload(object, row)}
                          menuItemsArray={menuItems}
              />
            </View>
        :
        null
      }
      </View>
  );
}

const allStyles = StyleSheet.create({
  text:{
    color:'rgba(100,100,100,1)',
    height:'auto',
    display:'flex',
    hyphens:'auto',
    fontSize:'16',
    position:'relative',
    wordWrap:'breakWord',
    overflowWrap:'breakWord'
  },
  border:{
    border:'solid rgba(160,160,160,1)',
    boxSizing:'border-box',
    borderTopWidth:0,
    borderCollapse:'collapse',
    borderLeftWidth:0
  },
  clamped:{
    left:'0',
    zIndex:2,
    position:'sticky',
    backgroundColor:'rgba(255,255,255,1)'
  },
  template:{
    width:'100%',
  },
  checkmark:{
    flex:1,
    paddingTop:10,
    alignItems:"center"
  },
  innerText:{
    fontWeight:400
  },
  selected:{
    color:'rgba(100,100,100,1)',
    height:'auto',
    display:'flex',
    hyphens:'auto',
    fontSize:'16',
    position:'relative',
    wordWrap:'breakWord',
    fontWeight:600,
    overflowWrap:'breakWord'
  },
  container:{
    width:'100%',
    display:'flex',
    position:'relative',
    flexDirection:'row',
    justifyContent:'flex-start'
  },
  selectedRow:{
    backgroundColor:'rgba(245,245,245,1)'
  },
  selectedText:{
    fontWeight:600
  },
  loadingContainer:{
    display:'flex',
    fontSize:'21',
    position:'relative',
    alignItems:'center',
    justifyContent:'center'
  },
  propertiesArray:{
    hyphens:'auto',
    padding: 10,
    overflowWrap:'anywhere'
  }
});
