import React from "react";
import {DefaultApi} from "@tuq/terms-api-client";

export function TermsService(aUrl, aCustomAxios){
  return new DefaultApi({
    baseOptions:{
      withCredentials:true,
      headers:{
        'accept':'application/json'
      }
    }
  }, aUrl + '/terms_and_conditions/', aCustomAxios);
}

