import {StyleSheet, View} from "react-native";
import {useEffect, useState} from "react";
import {Themes} from "../Constants/ThemeColours";
import GeneralModal from "./GeneralModal";
import LabeledInput from "../Custom UI/Inputs/LabeledInput";
import {useTranslation} from "react-i18next";
import {useAuthentication} from "../Contexts/Authentication";
import {errorMessageFromServerError, passwordIsValid} from "../Helpers/Helpers";

const ChangePasswordModal = ({open}) => {
  
  // Instance Variables
  
  const {t} = useTranslation();
  const {api, toastMessage, reloadUserFromServer} = useAuthentication();
  
  const [error, setError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  
  // Effects
  
  useEffect(() => {
    setError('');
  }, [newPassword, currentPassword, confirmNewPassword]);
  
  // Methods
  
  const updatePassword = () => {
    if(passwordIsValid(newPassword) && passwordIsValid(currentPassword) && passwordIsValid(confirmNewPassword)){
      if(newPassword === confirmNewPassword){
        if(newPassword !== currentPassword){
          setError('');
          api.updatePassword({user:{password:newPassword, currentPassword:currentPassword, passwordConfirmation:confirmNewPassword}}).then(() => {
            toastMessage(t('PASSWORD_CHANGED'));
            reloadUserFromServer();
          }, (newError) => {
            setError(errorMessageFromServerError(newError));
            toastMessage(t('PASSWORD_CHANGED_ERROR', {aError:errorMessageFromServerError(newError)}));
          });
        }
        else{
          setError(t('NEW_PASSWORD_SAME_AS_OLD_PASSWORD_ERROR'));
        }
      }
      else{
        setError(t('CURRENT_PASSWORD_DOES_NOT_MATCH_ERROR'));
      }
    }
    else{
      setError(t('INVALID_PASSWORD_ERROR'));
    }
  };
  
  // Render
  
  return (
    <GeneralModal open={open}
                  save={updatePassword}
                  error={error}
                  title={'PASSWORD_CHANGE_REQUIRED'}
                  saveButtonTitle={'CHANGE_PASSWORD'}
                  showCloseButton={false}
                  showCancelButton={false}>
      <View style={allStyles.main}>
        <LabeledInput text={currentPassword}
                      label={t('CURRENT_PASSWORD')}
                      change={setCurrentPassword}
                      submit={updatePassword}
                      placeholder={t('CURRENT_PASSWORD')}
                      secureTextEntry={true}
        />
  
        <LabeledInput text={newPassword}
                      label={t('NEW_PASSWORD')}
                      change={setNewPassword}
                      submit={updatePassword}
                      placeholder={t('NEW_PASSWORD')}
                      secureTextEntry={true}
        />
        
        <LabeledInput text={confirmNewPassword}
                      label={t('CONFIRM_NEW_PASSWORD')}
                      change={setConfirmNewPassword}
                      submit={updatePassword}
                      placeholder={t('CONFIRM_NEW_PASSWORD')}
                      secureTextEntry={true}
        />
      </View>
    </GeneralModal>
  );
}

const allStyles = StyleSheet.create({
  main: {
    width:'100%',
    padding:20,
    borderRadius:10,
    justifyContent:'center',
    backgroundColor:Themes.light.white,
    marginHorizontal:'auto'
  }
});

export default ChangePasswordModal
