
// 'contains', 'prefix', 'suffix'

export function objectIsPopulated(aObject){
  return aObject && (typeof aObject === 'object') && (Object.keys(aObject).length > 0);
}

export function orderDirection(aAscending){
  return aAscending ? 'asc' : 'desc';
}

export function emailIsValid(aEmail){
  return (aEmail.match('\\S+@\\S+\\.\\S') !== null);
}

export function passwordIsValid(aPassword){
  let numberOfPassedRequirements = 0;
  let numberRegex = /^(.*\d.*)$/;
  let symbolRegex = /^(.*\W.*)$/;
  let lowerCaseRegex = /^(.*[a-z].*)$/;
  let upperCaseRegex = /^(.*[A-Z].*)$/;
  
  if(numberRegex.test(aPassword)){
    numberOfPassedRequirements += 1;
  }
  if(symbolRegex.test(aPassword)){
    numberOfPassedRequirements += 1;
  }
  if(lowerCaseRegex.test(aPassword)){
    numberOfPassedRequirements += 1;
  }
  if(upperCaseRegex.test(aPassword)){
    numberOfPassedRequirements += 1;
  }
  return (aPassword.length >= 8) && (numberOfPassedRequirements >= 3);
}

export function isObject(aPotentialObject){
  return (typeof aPotentialObject === "object" && !Array.isArray(aPotentialObject) && aPotentialObject !== null);
}

export function objectsAreEqual(aObject, aSecondObject){
  const objectKeys = Object.keys(aObject);
  const secondObjectKeys = Object.keys(aSecondObject);
  
  if(objectKeys.length !== secondObjectKeys.length){
    return false;
  }
  for(let key of objectKeys){
    if(aObject[key] !== aSecondObject[key]){
      if(typeof aObject[key] == "object" && typeof aSecondObject[key] == "object"){
        if(!objectsAreEqual(aObject[key], aSecondObject[key])){
          return false;
        }
      }
      else{
        return false;
      }
    }
  }
  return true;
}

export function keyValuePairsArrayForObject(aObject){
  let returnValue = [{key:'', value:''}];
  
  if(aObject){
    let keysArray = Object.keys(aObject);
    
    if(keysArray && keysArray.length > 0){
      returnValue = [];
      
      for(let key of keysArray){
        returnValue.push({key:key, value:aObject[key]});
      }
    }
  }
  return returnValue;
}

export function errorMessageFromServerError(aError){
  let returnValue = '';
  
  if(aError){
    if(typeof aError === 'string'){
      returnValue = aError;
    }
    else if(aError.data){
      if(aError.data.error && aError.data.error.length > 0){
        returnValue = aError.data.error;
      }
      else if(aError.data.errors){
        if(Array.isArray(aError.data.errors)){
          returnValue = aError.data.errors.join(', ');
        }
      }
    }
  }
  return returnValue;
}

export function formatUserName(aUser){
  let returnValue = '';
  
  if(objectIsPopulated(aUser)){
    if(aUser.lastName !== null && aUser.lastName !== undefined){
      returnValue = aUser.firstName + ' ' + aUser.lastName;
    }
    else{
      returnValue = aUser.firstName;
    }
  }
  return returnValue;
}

export function formatUserFullName(aUser){
  let returnValue = '';
  
  if(objectIsPopulated(aUser)){
    if(aUser.lastName !== null && aUser.lastName !== undefined){
      if(aUser.middleName !== null && aUser.middleName !== undefined){
        returnValue = aUser.firstName + ' ' + aUser.middleName + ' ' + aUser.lastName;
      }
      else{
        returnValue = aUser.firstName + ' ' + aUser.lastName;
      }
    }
    else{
      if(aUser.middleName !== null && aUser.middleName !== undefined){
        returnValue = aUser.firstName + ' ' + aUser.middleName;
      }
      else{
        returnValue = aUser.firstName;
      }
    }
  }
  return returnValue;
}

export function containsValidMenuItems(aMenuItemsArray, aObject){
  let returnValue = false;
  
  if(aMenuItemsArray && aMenuItemsArray.length > 0){
    for(let menuItem of aMenuItemsArray){
      if(menuItem.groupTitle && menuItem.groupTitle.length > 0){
        for(let groupMenuItem of menuItem.array){
          if(groupMenuItem.isValid === null || groupMenuItem.isValid === undefined || groupMenuItem.isValid(aObject)){
            returnValue = true;
            break;
          }
        }
        if(returnValue){
          break;
        }
      }
      else if(menuItem.isValid === null || menuItem.isValid === undefined || menuItem.isValid(aObject)){
        returnValue = true;
        break;
      }
    }
  }
  return returnValue;
}

export function validMenuItems(aMenuItemsArray, aObject, aRow){
  let returnValue = [];
  
  if(aMenuItemsArray && aMenuItemsArray.length > 0){
    for(let menuItem of aMenuItemsArray){
      if(menuItem.groupTitle && menuItem.groupTitle.length > 0){
        let groupMenuItemsArray = [];
        
        for(let groupMenuItem of menuItem.array){
          if(groupMenuItem.isValid === null || groupMenuItem.isValid === undefined || groupMenuItem.isValid(aObject)){
            groupMenuItemsArray.push({title:groupMenuItem.title, clickAction:(aEvent) => groupMenuItem.clickAction(aObject, aRow, aEvent)});
          }
        }
        if(groupMenuItemsArray.length > 0){
          returnValue.push({groupTitle:menuItem.groupTitle, array:groupMenuItemsArray});
        }
      }
      else if(menuItem.isValid === null || menuItem.isValid === undefined || menuItem.isValid(aObject)){
        returnValue.push({title:menuItem.title, clickAction:(aEvent) => menuItem.clickAction(aObject, aRow, aEvent)});
      }
    }
  }
  return returnValue;
}
