import {StyleSheet, Text, View} from "react-native";
import {useCallback, useRef, useEffect, useState} from "react";
import Shadow from "../Files/Custom UI/Shadow";
import {Themes} from "../Files/Constants/ThemeColours";
import provinces from "../Files/Assets/Data/provinces.json";
import LabeledInput from "../Files/Custom UI/Inputs/LabeledInput";
import ColouredButton from "../Files/Custom UI/Buttons/ColouredButton";
import LabeledDropDown from "../Files/Custom UI/Inputs/LabeledDropDown";
import LabeledTextArea from "../Files/Custom UI/Inputs/LabeledTextArea";
import {useFocusEffect} from '@react-navigation/native';
import {useTranslation} from "react-i18next";
import LabeledDatePicker from "../Files/Custom UI/Inputs/LabeledDatePicker";
import LabeledPhoneNumber from "../Files/Custom UI/Inputs/LabeledPhoneNumber";
import {useAuthentication} from "../Files/Contexts/Authentication";
import {dateFromDateString} from "../Files/Helpers/DateHelpers";
import {useLocalSearchParams, useRouter} from "expo-router";
import {emailIsValid, errorMessageFromServerError, objectIsPopulated} from "../Files/Helpers/Helpers";

function Patient(){
  
  // Instance Variables
  
  const {t} = useTranslation();
  const {api, user, selectedPatient, setSelectedPatient, colorScheme, themedStyle, toastMessage, clearSelectedPatient} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  const router = useRouter();
  const params = useLocalSearchParams();
  const {id} = params;
  
  const emptyPatient = {allergies:'', alternateEmail:'', city:'', country:'CA', dateOfBirth:'', email:'', firstName:'',
    gender:'', lastName:'', locale:'en', patientId:'', phoneNumber:'', state:'ON', street1:'', street2:'', zip:''};
  
  const genderOptionsArray = [{label:t('MALE'), value:'male'}, {label:t('FEMALE'), value:'female'}, {label:t('DOES_NOT_IDENTIFY'), value:'other'}];
  const localeOptionsArray = [{label:t('ENGLISH'), value:'en'}, {label:t('FRENCH'), value:'fr'}];
  
  const inputRefs = useRef({})
  
  // const [patient, setPatient] = useState(selectedPatient ? selectedPatient : emptyPatient);
  
  const [zip, setZip] = useState(selectedPatient ? selectedPatient.zip : emptyPatient.zip);
  const [city, setCity] = useState(selectedPatient ? selectedPatient.city : emptyPatient.city);
  const [email, setEmail] = useState(selectedPatient ? selectedPatient.email : emptyPatient.email);
  const [state, setState] = useState(selectedPatient ? selectedPatient.state : emptyPatient.state);
  const [gender, setGender] = useState(selectedPatient ? selectedPatient.gender : emptyPatient.gender);
  const [locale, setLocale] = useState(selectedPatient ? selectedPatient.locale : emptyPatient.locale);
  const [street1, setStreet1] = useState(selectedPatient ? selectedPatient.street1 : emptyPatient.street1);
  const [street2, setStreet2] = useState(selectedPatient ? selectedPatient.street2 : emptyPatient.street2);
  const [lastName, setLastName] = useState(selectedPatient ? selectedPatient.lastName : emptyPatient.lastName);
  const [allergies, setAllergies] = useState(selectedPatient ? selectedPatient.allergies : emptyPatient.allergies);
  const [firstName, setFirstName] = useState(selectedPatient ? selectedPatient.firstName : emptyPatient.firstName);
  const [patientId, setPatientId] = useState(selectedPatient ? selectedPatient.patientId : emptyPatient.patientId);
  const [dateOfBirth, setDateOfBirth] = useState(selectedPatient ? dateFromDateString(selectedPatient.dateOfBirth) : emptyPatient.dateOfBirth);
  const [phoneNumber, setPhoneNumber] = useState(selectedPatient ? selectedPatient.phoneNumber : emptyPatient.phoneNumber);
  const [alternateEmail, setAlternateEmail] = useState(selectedPatient ? selectedPatient.alternateEmail : emptyPatient.alternateEmail);
  
  const [cityOptions, setCityOptions] = useState({});
  const [provinceOptionsArray, setProvinceOptionsArray] = useState([]);
  
  // Getters
  
  const patient = {allergies:allergies, city:city, country:'CA', dateOfBirth:dateFromDateString(dateOfBirth), email:email,
    firstName:firstName, gender:gender, lastName:lastName, locale:locale, patientId:patientId,
    phoneNumber:phoneNumber, state:state, street1:street1, street2:street2, zip:zip, alternateEmail:alternateEmail};
  
  // Setters
  
  const setPatient = (aPatient) => {
    if(objectIsPopulated(aPatient)){
      setZip(aPatient.zip);
      setCity(aPatient.city);
      setEmail(aPatient.email);
      setState(aPatient.state);
      setGender(aPatient.gender);
      setLocale(aPatient.locale);
      setStreet1(aPatient.street1);
      setStreet2(aPatient.street2);
      setLastName(aPatient.lastName);
      setAllergies(aPatient.allergies);
      setFirstName(aPatient.firstName);
      setPatientId(aPatient.patientId);
      setDateOfBirth(dateFromDateString(aPatient.dateOfBirth));
      setPhoneNumber(aPatient.phoneNumber);
      setAlternateEmail(aPatient.alternateEmail);
    }
  };
  
  // Effects
  
  useFocusEffect(
    useCallback(() => {
      return () => {
        clearSelectedPatient();
        setPatient(emptyPatient);
      }
    }, []));
  
  useEffect(() => {
    if(patientId === '' && user && user.clinic && user.clinic.state !== 'ON'){
      setCity('');
      setState(user.clinic.state);
    }
  }, [user, setCity, setState, patientId]);
  
  useEffect(() => {
    if(provinceOptionsArray.length === 0){
      let cities = {};
      let provinceOptions = [];
  
      for(let province of provinces){
        provinceOptions.push({label:province.name, value:province.key});
        cities[province.key] = [];

        for(let city of province.cities){
          cities[province.key].push({label:city, value:city});
        }
      }
      setCityOptions(cities);
      setProvinceOptionsArray(provinceOptions);
    }
  }, [setCityOptions, provinceOptionsArray, setProvinceOptionsArray]);
  
  useEffect(() => {
    if(api && id && id.length > 0 && (!selectedPatient || selectedPatient.id !== id)){
      api.showPatient(id).then((newResult) => {
        setSelectedPatient(newResult.data);
        setPatient(newResult.data);
      }, (newError) => {
      
      });
    }
  }, [id, api, patient, setPatient, setSelectedPatient]);
  
  // Methods
  
  const updatePatient = () => {
    if(selectedPatient && selectedPatient.id){
      api.updatePatient(selectedPatient.id, patient).then((newResult) => {
        toastMessage(t('PATIENT_UPDATED', {aName:newResult.data.firstName}));
        router.push({pathname:'/patients'});
      }, (newError) => {
        toastMessage(t('PATIENT_UPDATE_ERROR', {aError:errorMessageFromServerError(newError)}));
      });
    }
    else{
      api.createPatient(patient).then((newResult) => {
        toastMessage(t('PATIENT_CREATED', {aName:newResult.data.firstName}));
        router.push({pathname:'/patients'});
      }, (newError) => {
        toastMessage(t('PATIENT_CREATE_ERROR', {aError:errorMessageFromServerError(newError)}));
      });
    }
  };
  
  // Render
  
  return (
    <View style={styles.container}>
      <Shadow style={styles.shadow}>
        <View style={styles.main}>
          <Text style={styles.title}>
            {t('PATIENT')}
          </Text>
          
          <LabeledInput text={firstName}
                        label={t('FIRST_NAME')}
                        change={setFirstName}
                        submit={() => inputRefs.current['lastName'].focus()}
                        inputRef={(aRef) => inputRefs.current['firstName'] = aRef}
                        placeholder={t('FIRST_NAME')}
          />
          
          <LabeledInput text={lastName}
                        label={t('LAST_NAME')}
                        change={setLastName}
                        submit={() => inputRefs.current['email'].focus()}
                        inputRef={(aRef) => inputRefs.current['lastName'] = aRef}
                        placeholder={t('LAST_NAME')}
          />
          
          <LabeledInput text={email}
                        error={emailIsValid(email) || email.length === 0  ? null : t('INVALID_EMAIL')}
                        label={t('EMAIL')}
                        change={setEmail}
                        submit={() => inputRefs.current['alternateEmail'].focus()}
                        inputRef={(aRef) => inputRefs.current['email'] = aRef}
                        placeholder={t('EMAIL')}
                        keyboardType={'email-address'}
          />
  
          <LabeledInput text={alternateEmail}
                        error={alternateEmail === undefined || alternateEmail === null || alternateEmail.length === 0 || emailIsValid(alternateEmail) ? null : t('INVALID_EMAIL')}
                        label={t('SECONDARY_EMAIL')}
                        change={setAlternateEmail}
                        submit={() => inputRefs.current['phoneNumber'].focus()}
                        inputRef={(aRef) => inputRefs.current['alternateEmail'] = aRef}
                        placeholder={t('SECONDARY_EMAIL')}
                        keyboardType={'email-address'}
          />
  
          <LabeledPhoneNumber text={phoneNumber}
                              label={t('PHONE_NUMBER')}
                              change={setPhoneNumber}
                              submit={() => inputRefs.current['gender'].focus()}
                              inputRef={(aRef) => inputRefs.current['phoneNumber'] = aRef}
                              placeholder={t('PHONE_NUMBER')}
          />
  
          {patientId.length > 0 ?
            <LabeledInput text={patientId}
                          label={t('PATIENT_ID')}
                          disabled={true}
                          placeholder={t('PATIENT_ID')}
            />
            :
            null
          }
  
          <LabeledDropDown label={t('GENDER')}
                           value={gender}
                           change={(aValue) => setGender(aValue)}
                           submit={() => inputRefs.current['dateOfBirth'].input.focus()}
                           inputRef={(aRef) => inputRefs.current['gender'] = aRef}
                           placeholder={t('GENDER')}
                           optionsArray={genderOptionsArray}
          />
  
          <LabeledDatePicker date={dateOfBirth}
                             label={t('DATE_OF_BIRTH')}
                             change={(aDate) => setDateOfBirth(aDate)}
                             submit={() => inputRefs.current['locale'].focus()}
                             inputRef={(aRef) => inputRefs.current['dateOfBirth'] = aRef}
                             placeholder={'YYYY/MM/DD'}
          />
          
          <LabeledDropDown label={t('PREFERRED_LANGUAGE')}
                           value={locale}
                           change={(aValue) => {
                             setLocale(aValue);
                             inputRefs.current['allergies'].focus();
                           }}
                           inputRef={(aRef) => inputRefs.current['locale'] = aRef}
                           placeholder={t('PREFERRED_LANGUAGE')}
                           optionsArray={localeOptionsArray}
          />
          
          <LabeledTextArea text={allergies}
                           label={t('ALLERGIES')}
                           change={setAllergies}
                           submit={() => inputRefs.current['street1'].focus()}
                           inputRef={(aRef) => inputRefs.current['allergies'] = aRef}
                           placeholder={t('ALLERGIES')}
                           marginBottom={0}
          />
          
          <LabeledInput text={street1}
                        label={t('ADDRESS_LINE_1')}
                        change={setStreet1}
                        submit={() => inputRefs.current['street2'].focus()}
                        inputRef={(aRef) => inputRefs.current['street1'] = aRef}
                        placeholder={t('ADDRESS_LINE_1')}
          />
          
          <LabeledInput text={street2}
                        label={t('ADDRESS_LINE_2')}
                        change={setStreet2}
                        submit={() => inputRefs.current['city'].focus()}
                        inputRef={(aRef) => inputRefs.current['street2'] = aRef}
                        placeholder={t('ADDRESS_LINE_2')}
          />
          
          <LabeledDropDown label={t('CITY')}
                           value={city}
                           change={(aValue) => {
                             setCity(aValue);
                             inputRefs.current['state'].focus();
                           }}
                           inputRef={(aRef) => inputRefs.current['city'] = aRef}
                           placeholder={t('CITY')}
                           optionsArray={cityOptions[state]}
          />
  
          <LabeledDropDown label={t('PROVINCE')}
                           value={state}
                           change={(aValue) => {
                             if(aValue !== state){
                               setState(aValue);
                               inputRefs.current['zip'].focus();
                             }
                           }}
                           inputRef={(aRef) => inputRefs.current['state'] = aRef}
                           placeholder={t('PROVINCE')}
                           optionsArray={provinceOptionsArray}
          />
          
          <LabeledInput text={patient.zip}
                        label={t('POSTAL_CODE')}
                        change={setZip}
                        submit={updatePatient}
                        inputRef={(aRef) => inputRefs.current['zip'] = aRef}
                        placeholder={t('POSTAL_CODE')}
          />
          
          <LabeledInput text={patient.country}
                        label={t('COUNTRY')}
                        disabled={true}
                        placeholder={t('COUNTRY')}
          />
          
          <ColouredButton title={t(id && id.length > 0 ? 'UPDATE' : 'CREATE')}
                          colour={Themes[colorScheme].primary}
                          onPress={updatePatient}
                          textColour={Themes[colorScheme].text}
                          pressedColour={Themes[colorScheme].secondary}
                          highlightedColour={Themes[colorScheme].secondary}
                          accessibilityLabel={t('PATIENT_UPDATE_BUTTON_ACCESSIBILITY')}
          />
        </View>
      </Shadow>
    </View>
  );
}
export default Patient

const allStyles = StyleSheet.create({
  container:{
    flex:1,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'white'
  },
  shadow:{
    width:'100%',
    height:'100%',
    justifyContent:'center',
    backgroundColor:'white',
    marginHorizontal:'auto'
  },
  main:{
    width:'100%',
    height:'100%',
    padding:20,
    overflow:'scroll'
  },
  title:{
    fontSize:32,
    textAlign:'center',
    fontWeight:'bold',
    paddingBottom:20
  },
  subtitle:{
    color:'#38434D',
    fontSize:28
  },
});
