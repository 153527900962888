import {StyleSheet, Text, View} from "react-native";
import React, {useEffect, useState} from "react";
import Logo from "../Files/Assets/Images/Light/Labtician_English.svg";
import Errors from "../Files/Custom UI/Errors";
import Shadow from "../Files/Custom UI/Shadow";
import {Themes} from "../Files/Constants/ThemeColours";
import LogoFrench from "../Files/Assets/Images/Light/Labtician_French.svg";
import LabeledInput from "../Files/Custom UI/Inputs/LabeledInput";
import ColouredButton from "../Files/Custom UI/Buttons/ColouredButton";
import {useTranslation} from "react-i18next";
import {useAuthentication} from "../Files/Contexts/Authentication";
import {useLocalSearchParams, useRouter} from "expo-router";
import {errorMessageFromServerError, passwordIsValid} from "../Files/Helpers/Helpers";

export default function Invitation(){
  
  // Instance Variables
  
  const params = useLocalSearchParams();
  const router = useRouter();
  const {t, i18n} = useTranslation();
  const {api, colorScheme, themedStyle, toastMessage} = useAuthentication();
  
  const {confirmationToken, resetPasswordToken} = params;
  const styles = themedStyle(allStyles);
  
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  
  // Effects
  
  useEffect(() => {
    setError('');
  }, [password]);
  
  // Methods
  
  const updatePassword = () => {
    if(passwordIsValid(password)){
      setError('');
      
      if(confirmationToken && confirmationToken.length > 0){
        api.updateUserFromInvitation({
          user:{password:password, passwordConfirmation:password},
          confirmationToken:confirmationToken
        }).then(() => {
          router.push({pathname:'/profile'});
          toastMessage(t('EMAIL_CONFIRMED'));
        }, (newError) => {
          setError(errorMessageFromServerError(newError));
          toastMessage(t('EMAIL_CONFIRMATION_ERROR', {aError:errorMessageFromServerError(newError)}));
        });
      }
      else if(resetPasswordToken && resetPasswordToken.length > 0){
        api.resetPassword({user:{password:password, resetPasswordToken:resetPasswordToken}}).then(() => {
          router.push({pathname:'/patients'});
          toastMessage(t('PASSWORD_RESET'));
        }, (newError) => {
          setError(errorMessageFromServerError(newError));
          toastMessage(t('PASSWORD_RESET_ERROR', {aError:errorMessageFromServerError(newError)}));
        });
      }
    }
    else{
      setError(t('INVALID_PASSWORD_ERROR'));
    }
  };
  
  // Render
  
  return (
    <View style={styles.container}>
      <Shadow>
        <View style={styles.main}>
          <View style={styles.logo}>
            {i18n.language === 'en' ?
              <Logo width={350}
                    height={100}
              />
              :
              <LogoFrench width={350}
                          height={100}
              />
            }
          </View>
          
          <Text style={styles.title}>
            {t(confirmationToken && confirmationToken.length > 0 ? 'CONFIRM_EMAIL' : 'RESET_PASSWORD')}
          </Text>
          
          <Errors error={error}/>
          
          <LabeledInput text={password}
                        label={t('PASSWORD')}
                        change={setPassword}
                        submit={updatePassword}
                        placeholder={t('PASSWORD')}
                        secureTextEntry={true}
          />
          
          <ColouredButton title={t(confirmationToken && confirmationToken.length > 0 ? 'CONFIRM' : 'RESET')}
                          colour={Themes[colorScheme].primary}
                          onPress={updatePassword}
                          textColour={Themes[colorScheme].text}
                          pressedColour={Themes[colorScheme].secondary}
                          highlightedColour={Themes[colorScheme].secondary}
                          accessibilityLabel={t(confirmationToken && confirmationToken.length > 0 ? 'CONFIRM_EMAIL_BUTTON_ACCESSIBILITY' : 'RESET_PASSWORD_BUTTON_ACCESSIBILITY')}
          />
        </View>
      </Shadow>
    </View>
  );
}

const allStyles = StyleSheet.create({
  logo:{
    width:350,
    marginLeft:25
  },
  main:{
    width:500,
    padding:40,
    maxWidth:960,
    borderRadius:10,
    justifyContent:'center',
    marginHorizontal:'auto',
    backgroundColor:'white'
  },
  title:{
    color:Themes.light.black,
    fontSize:32,
    marginTop:20,
    textAlign:'center',
    fontWeight:'bold',
    paddingBottom:20
  },
  container:{
    flex:1,
    padding:24,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'white'
  }
});
