.labeled-datepicker{
  color:black;
  width:100%;
  height:unset!important;
  display:flex;
  padding:10px!important;
  flex-grow:1;
  font-size:14px;
  align-items:center;
  line-height:normal;
  border-color:black;
  border-width:1px;
  border-radius:7px;
}
.react-datepicker-popper{
  z-index:99999!important;
}
.react-datepicker-wrapper{
  width:100%;
  padding-right:20px!important;
}
