import {StyleSheet, View} from "react-native";
import {useCallback, useState, useEffect} from "react";
import Info from "../Files/Custom UI/Info";
import Shadow from "../Files/Custom UI/Shadow";
import {Themes} from "../Files/Constants/ThemeColours";
import {useRouter} from "expo-router";
import DynamicList from "../Files/Lists/DynamicList";
import ContactModal from "../Files/Modals/ContactModal";
import ColouredButton from "../Files/Custom UI/Buttons/ColouredButton";
import {useFocusEffect} from "@react-navigation/native";
import {useTranslation} from "react-i18next";
import ConfirmationModal from "../Files/Modals/ConfirmationModal";
import {useAuthentication} from "../Files/Contexts/Authentication";
import RemoveAppointmentModal from "../Files/Modals/RemoveAppointmentModal";
import {localDayTimeFromDateString} from "../Files/Helpers/DateHelpers";
import {errorMessageFromServerError, formatUserName, orderDirection} from "../Files/Helpers/Helpers";

export default function Patients(){
  
  // Instance Variables
  
  const {t} = useTranslation();
  const router = useRouter();
  const {api, user, setSelectedPatient, colorScheme, themedStyle, toastMessage, clearSelectedPatient} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  const [shouldReload, setShouldReload] = useState(false);
  const [patientToDelete, setPatientToDelete] = useState(null);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [patientWithAppointmentToDelete, setPatientWithAppointmentToDelete] = useState(null);
  
  const [columnsArray] = useState([
    {key:'created_at', columnWeight:1.5, columnNameKey:'PATIENT_ID', propertiesArray:['patientId']},
    {key:'first_name', columnWeight:2.5, columnNameKey:'NAME', propertiesArray:['firstName'], valueFormatter:(aValue, aObject) => {
        return formatUserName(aObject);
      }},
    {key:'next_appointment.start_at', columnWeight:2.5, columnNameKey:'UPCOMING_APPOINTMENT', propertiesArray:['nextAppointment', 'startAt'], valueFormatter:(aValue) => {
        return aValue ? localDayTimeFromDateString(aValue, false) : '';
      }},
    {key:'next_appointment.is_thermal_treatment', columnWeight:2, columnNameKey:'THERMAL_TREATMENT', propertiesArray:['nextAppointment', 'isThermalTreatment']},
    {key:'next_appointment.number_of_blood_draw_refills', columnWeight:2, columnNameKey:'REFILLS_REMAINING', propertiesArray:['nextAppointment', 'numberOfBloodDrawRefills']},
    {key:'next_appointment.is_paid', columnWeight:1, columnNameKey:'PAID', propertiesArray:['nextAppointment', 'isPaid']}
  ]);
  
  const [menuItemsArray] = useState([
    {groupTitle:'APPOINTMENT_ACTIONS',
      array:[
        {title:'PAY_FOR_APPOINTMENT', clickAction:(aPatient) => {
            setSelectedPatient(aPatient);
            router.push({pathname:'/payment', params:{signedId:aPatient.nextAppointment.signedId}})
          }, isValid:(aPatient) => (aPatient && aPatient.nextAppointment && aPatient.nextAppointment.id && aPatient.nextAppointment.id.length > 0 && !aPatient.nextAppointment.isPaid)},
        {title:'NEW_APPOINTMENT', clickAction:(aPatient) => {
            setSelectedPatient(aPatient);
            router.push({pathname:'/appointments'})
          }, isValid:(aPatient) => (aPatient && (!aPatient.nextAppointment || !aPatient.nextAppointment.id))},
        {title:'EDIT_APPOINTMENT', clickAction:(aPatient) => {
            setSelectedPatient(aPatient);
            router.push({pathname:'/appointments', params:{edit:true}})
          }, isValid:(aPatient) => (aPatient && aPatient.nextAppointment && aPatient.nextAppointment.id && aPatient.nextAppointment.id.length > 0)},
        {title:'CANCEL_APPOINTMENT', clickAction:(aPatient) => {
            setPatientWithAppointmentToDelete(aPatient);
          }, isValid:(aPatient) => (aPatient && aPatient.nextAppointment && aPatient.nextAppointment.id && aPatient.nextAppointment.id.length > 0)}
      ]
    },
    {groupTitle:'PATIENT_ACTIONS',
      array:[
        {title:'EDIT_PATIENT', clickAction:(aPatient) => {
            setSelectedPatient(aPatient);
            router.push({pathname:'/patient', params:{id:aPatient.id}});
          }},
        {title:'DELETE_PATIENT', clickAction:(aPatient) => {
            setPatientToDelete(aPatient);
          }}
      ]
    }
  ]);
  
  // Effects
  
  useFocusEffect(useCallback(() => {
    if(user && user.hasAgreedToAllTermsDocuments){
      setShouldReload(true);
    }
  }, [user, setShouldReload]));
  
  useEffect(() => {
    clearSelectedPatient()
  }, []);
  
  // Methods
  
  const columnNameForIndex = (aIndex) => {
    let returnValue = null;
    
    if(aIndex < columnsArray.length){
      let column = columnsArray[aIndex];
      returnValue = column.key;
    }
    return returnValue;
  }
  
  // Render
  
  return (
    <View style={styles.container}>
      <Shadow style={styles.main}>
        {user && user.hasAgreedToAllTermsDocuments ?
          <DynamicList id="patients-list"
                       didReload={() => setShouldReload(false)}
                       headerView={
                         <View style={styles.dynamicListHeaderViewButton}>
                           <Info label={t('CONTACT_DESCRIPTION')}
                                 placement={'bottom'}>
                             <View>
                               <ColouredButton style={{marginRight:20}}
                                               title={t('CONTACT')}
                                               colour={Themes[colorScheme].primary}
                                               height={38.5}
                                               onPress={() => setOpenContactModal(true)}
                                               textColour={Themes[colorScheme].text}
                                               pressedColour={Themes[colorScheme].secondary}
                                               highlightedColour={Themes[colorScheme].secondary}
                                               accessibilityLabel={t('CONTACT_US_BUTTON_ACCESSIBILITY')}
                               />
                             </View>
                           </Info>
                           
                           <ColouredButton title={t('NEW_PATIENT')}
                                           colour={Themes[colorScheme].primary}
                                           height={38.5}
                                           onPress={() => {
                                             clearSelectedPatient();
                                             router.push({pathname:'/patient'});
                                           }}
                                           textColour={Themes[colorScheme].text}
                                           pressedColour={Themes[colorScheme].secondary}
                                           highlightedColour={Themes[colorScheme].secondary}
                                           accessibilityLabel={t('NEW_PATIENT_BUTTON_ACCESSIBILITY')}
                           />
                         </View>}
                       borderWidth={0}
                       canSortList={true}
                       clampFirstColumn={true}
                       columnsArray={columnsArray}
                       fetchObjects={(aLimit, aOffset, aSearchString, aColumn, aAscending) => api.indexPatients(aLimit, aOffset, orderDirection(aAscending), columnNameForIndex(aColumn), aSearchString, 'contains', 'first_name')}
                       shouldReload={shouldReload}
                       menuItemsArray={menuItemsArray}
                       minColumnWidth={80}
                       totalObjectsKey='TOTAL'
                       responseTotalKeysArray={['data', 'total']}
                       responseObjectKeysArray={['data', 'objects']}
          />
          :
          null
        }
      </Shadow>
  
      <ContactModal open={openContactModal}
                    close={() => setOpenContactModal(false)}
      />
      
      <ConfirmationModal open={patientToDelete !== null}
                         save={() => api.deletePatient(patientToDelete ? patientToDelete.id : '').then((newResult) => {
                           toastMessage(t('DELETE_PATIENT_SUCCESS', {aName:formatUserName(patientToDelete)}));
                           // TODO: Just remove the returned patient.
                           setShouldReload(true);
                           setPatientToDelete(null);
                         }, (newError) => {
                           toastMessage(t('DELETE_PATIENT_ERROR', {aError:errorMessageFromServerError(newError)}));
                         })}
                         close={() => setPatientToDelete(null)}
                         title={'DELETE_PATIENT_TITLE'}
                         description={t('DELETE_PATIENT_DESCRIPTION', {aName:formatUserName(patientToDelete)})}
      />
      
      <RemoveAppointmentModal open={patientWithAppointmentToDelete !== null}
                              close={() => {
                                // TODO: Just replace the returned patient.
                                setShouldReload(true);
                                setPatientWithAppointmentToDelete(null);
                              }}
                              patient={patientWithAppointmentToDelete}
                              appointment={patientWithAppointmentToDelete ? patientWithAppointmentToDelete.nextAppointment : null}
      />
    </View>
  );
}

const allStyles = StyleSheet.create({
  container:{
    flex:1,
    backgroundColor:'#FFFFFF'
  },
  main:{
    flex:1,
    maxWidth:'100%',
    marginHorizontal:"auto",
    width:'100%'
  },
  title:{
    fontSize:24,
    fontWeight:"bold",
  },
  dynamicListHeaderViewButton:{
    alignItems:'center',
    marginLeft:'auto',
    marginRight:20,
    flexDirection:'row',
    justifyContent:'center'
  }
});
