import {ScrollView, View, Text, ActivityIndicator} from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import Errors from "../Custom UI/Errors";
import {Themes} from "../Constants/ThemeColours";
import ColouredButton from "../Custom UI/Buttons/ColouredButton";
import {Modal, Button} from "native-base";
import {useTranslation} from "react-i18next";
import {useAuthentication} from "../Contexts/Authentication";

const GeneralModal = ({open, save, close, error, title, warning, children, description,
                        saveButtonTitle = 'SAVE', showCloseButton = true, showCancelButton = true,
                        cancelButtonTitle = 'CANCEL', size = 'lg', loading = false, maxHeight = 512}) => {
  
  // Instance Variables
  
  const {t} = useTranslation();
  const {colorScheme} = useAuthentication();
  
  // Render
  
  return (
    open ?
      <Modal size={['xs', 'sm', 'md', 'lg', 'xl', 'full'].includes(size) ? size : 'lg'}
             isOpen={open}
             onClose={close}>
        <Modal.Content maxH={maxHeight}>
          {showCloseButton ?
            <Modal.CloseButton />
            :
            null
          }
  
          {title || description ?
            <Modal.Header>
              {t(title)}
    
              {description ?
                <Text style={{color:'black'}}>
                  {description}
                </Text>
                :
                null
              }
    
              <Errors error={error}
                      warning={warning}
              />
            </Modal.Header>
            :
            null
          }
          
          <Modal.Body style={{padding:0}}>
            {children}
          </Modal.Body>
          
          <Modal.Footer>
            <Button.Group space={2}>
              {showCancelButton ?
                <Button variant="ghost"
                        colorScheme="blueGray"
                        onPress={() => close()}>
                  {t(cancelButtonTitle)}
                </Button>
                :
                null
              }
  
              {save ?
                <ColouredButton style={{marginRight:20}}
                                title={t(saveButtonTitle)}
                                colour={Themes[colorScheme].primary}
                                height={38.5}
                                onPress={() => save()}
                                textColour={Themes[colorScheme].text}
                                pressedColour={Themes[colorScheme].secondary}
                                highlightedColour={Themes[colorScheme].secondary}
                                accessibilityLabel={t('SAVE_BUTTON_ACCESSIBILITY')}
                />
                :
                null
              }
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      :
      null
  );
}

export default GeneralModal
