import {StyleSheet, Text, View} from "react-native";
import Shadow from "../Files/Custom UI/Shadow";
import {Themes} from "../Files/Constants/ThemeColours";
import {useEffect, useState} from "react";
import {useRouter} from "expo-router";
import LabeledInput from "../Files/Custom UI/Inputs/LabeledInput";
import ColouredButton from "../Files/Custom UI/Buttons/ColouredButton";
import {useTranslation} from "react-i18next";
import {useAuthentication} from "../Files/Contexts/Authentication";
import {emailIsValid, errorMessageFromServerError, passwordIsValid} from "../Files/Helpers/Helpers";

export default function Forgot(){
  
  const {t} = useTranslation();
  const {api, colorScheme, themedStyle, toastMessage} = useAuthentication();
  const router = useRouter();
  const styles = themedStyle(allStyles);
  
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  
  // Effects
  
  useEffect(() => {
    setError('');
  }, [email]);
  
  // Methods
  
  const resetPassword = () => {
    if(emailIsValid(email)){
      api.resetPasswordEmail({user:{email:email}}).then((newResult) => {
        toastMessage(t('FORGOT_PASSWORD_EMAIL_SENT'));
        setTimeout(() => {
          router.push({pathname:'/'});
        }, 2000);
      }, (newError) => {
        setError(errorMessageFromServerError(newError));
        toastMessage(t('FORGOT_PASSWORD_EMAIL_SEND_ERROR', {aName:errorMessageFromServerError(newError)}));
      })
    }
    else{
      setError(t('INVALID_EMAIL'));
    }
  };
  
  // Render
  
  return (
    <View style={styles.container}>
      <Shadow>
        <View style={styles.main}>
          <Text style={styles.title}>
            {t('FORGOT_PASSWORD')}
          </Text>
          
          <LabeledInput text={email}
                        error={error.length > 0 && !emailIsValid(email) ? t('INVALID_EMAIL') : null}
                        label={t('EMAIL')}
                        change={setEmail}
                        submit={resetPassword}
                        placeholder={t('EMAIL')}
                        keyboardType={'email-address'}
          />
          
          <ColouredButton title={t('SEND')}
                          colour={Themes[colorScheme].primary}
                          onPress={resetPassword}
                          textColour={Themes[colorScheme].text}
                          pressedColour={Themes[colorScheme].secondary}
                          highlightedColour={Themes[colorScheme].secondary}
                          accessibilityLabel={t('FORGOT_PASSWORD_BUTTON_ACCESSIBILITY')}
          />
        </View>
      </Shadow>
    </View>
  );
}

const allStyles = StyleSheet.create({
  container: {
    flex:1,
    padding:24,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'white'
  },
  main:{
    width:500,
    padding:40,
    maxWidth:960,
    borderRadius:10,
    justifyContent:'center',
    backgroundColor:'white',
    marginHorizontal:'auto'
  },
  title:{
    color:'black',
    fontSize:32,
    textAlign:'center',
    fontWeight:'bold',
    paddingBottom:20
  }
});
