import {SafeAreaView, StyleSheet, useColorScheme, View} from "react-native";
import Label from "../Label";
import Errors from "../Errors";
import {Switch} from "native-base";
import {Themes} from "../../Constants/ThemeColours";
import IconButton from "../Buttons/IconButton";

const LabeledSwitch = ({error, label, value, change, warning, addButtonClicked, accessibilityLabel, size = 'md',
                         style = {}, disabled = false, marginBottom = 20, switchPosition = 'bottom'}) => {
  
  // Instance Variables
  
  const colorScheme = useColorScheme();
  
  // Methods
  
  
  
  // Render
  
  return (
    <SafeAreaView style={[{width:'100%'}, style]}
                  accessibilityLabel={accessibilityLabel}>
      <View style={[
        (switchPosition === 'right' ? styles.labeledSwitchInputContainerRight : styles.labeledSwitchInputContainerBottom),
        {marginBottom: marginBottom}
      ]}>
        
        <Label style={[styles.inputLabel]}
               label={label}
        />
        
        <Switch size={size}
                style={[
                  styles.switchInputField,
                  (disabled ? styles.switchInputFieldInactive : {}),
                  (switchPosition === 'right' ? {} : styles.switchInputFieldBottom)
                ]}
                _hover={{onTrackColor:Themes[colorScheme].secondary}}
                value={value}
                onToggle={(...args) => change(args[0])}
                isDisabled={disabled}
                onThumbColor={Themes[colorScheme].text}
                onTrackColor={Themes[colorScheme].primary}
                offThumbColor={Themes[colorScheme].lightGray}
                offTrackColor={Themes[colorScheme].mediumGray}
                accessibilityLabel={accessibilityLabel}
        />
      </View>
      
      {addButtonClicked ?
        <IconButton name={'add-circle-outline'}
                    size={30}
                    style={styles.labeledInputAddButton}
                    colour={Themes[colorScheme].primary}
                    buttonClicked={addButtonClicked}
        />
        :
        null
      }
      
      <Errors error={error}
              warning={warning}
      />
    </SafeAreaView>
  );
}

export default LabeledSwitch

const styles = StyleSheet.create({
  container: {
    flex:1,
    padding:24,
    alignItems:'center'
  },
  labeledSwitchInputContainerRight:{
    width:'100%',
    display:'flex',
    alignItems:'flex-start',
    flexDirection:'row',
    justifyContent:'space-between',
    backgroundColor:'rgba(255,255,255,1)'
  },
  labeledSwitchInputContainerBottom:{
    width:'100%',
    display:'flex',
    alignItems:'flex-start',
    flexDirection:'column'
  },
  labeledSwitchLabeledInfo:{
    width:'100%'
  },
  labeledSwitchCellRight:{
    display:'flex',
    alignItems:'center',
    marginLeft:10
  },
  labeledSwitchCellBottom:{
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-start'
  },
  switchInputField:{
    height:25
  },
  switchInputFieldBottom:{
    marginTop:5,
  },
  switchInputFieldInactive:{
  
  }
});
