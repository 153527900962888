import {StyleSheet} from "react-native";
import Markdown from 'react-native-simple-markdown'
import GeneralModal from "./GeneralModal";
import {useTranslation} from "react-i18next";
import {useAuthentication} from "../Contexts/Authentication";
import {useEffect, useState} from "react";
import {errorMessageFromServerError} from "../Helpers/Helpers";

const TermsModal = ({open}) => {
  
  // Instance Variables
  
  const {t} = useTranslation();
  const {termsApi, signOut, themedStyle, toastMessage, reloadUserFromServer} = useAuthentication();
  const styles = themedStyle(allStyles);
  
  const [termsArray, setTermsArray] = useState([]);
  
  // Effects
  
  useEffect(() => {
    termsApi.indexTerms().then((newResult) => {
      setTermsArray(newResult.data);
    }, (newError) => {
    
    });
  }, [setTermsArray]);
  
  // Render
  
  return (
    <GeneralModal open={open}
                  save={() => {
                    termsApi.agreeToTerms(termsArray[0].uuid, {agreedToTerms:true}).then(() => {
                      reloadUserFromServer();
                    }, (newError) => {
                      toastMessage(t('ACCEPT_TERMS_ERROR', {aError:errorMessageFromServerError(newError)}));
                    })}}
                  close={() => signOut()}
                  title={termsArray && termsArray.length > 0 ? termsArray[0].documentName : ''}
                  saveButtonTitle={'ACCEPT'}
                  showCloseButton={false}
                  cancelButtonTitle={'DECLINE'}>
      <Markdown styles={styles.markdownStyles}>
        {(termsArray && termsArray.length > 0 ? termsArray[0].documentBody : '')}
      </Markdown>
    </GeneralModal>
  );
}

export default TermsModal

const allStyles = StyleSheet.create({
  markdownStyles:{
    heading1: {
      fontSize: 24,
      color: 'purple',
    },
    link: {
      color: 'pink',
    },
    mailTo: {
      color: 'orange',
    },
    text: {
      color: '#555555',
    },
  }
});
