[
  {
    "name": "British Columbia",
    "key": "BC",
    "cities": [
      "100 Mile House",
      "108 Mile Ranch",
      "150 Mile House",
      "70 Mile House",
      "Abbotsford",
      "Agassiz",
      "Ahousat",
      "Ainsworth Hot Springs",
      "Aiyansh",
      "Aldergrove",
      "Alert Bay",
      "Alexis Creek",
      "Alkali Lake",
      "Altona",
      "Anahim Lake",
      "Anglemont",
      "Anmore",
      "Argenta",
      "Armstrong",
      "Arras",
      "Ashcroft",
      "Athalmer",
      "Atlin",
      "Avola",
      "Baldonnel",
      "Balfour",
      "Bamfield",
      "Barkerville",
      "Barriere",
      "Bear Lake",
      "Beaverdell",
      "Belcarra",
      "Bella Bella",
      "Bella Coola",
      "Big Lake Ranch",
      "Black Creek",
      "Blind Bay",
      "Blind Channel",
      "Blubber Bay",
      "Blue River",
      "Boston Bar",
      "Boswell",
      "Bowen Island",
      "Bowser",
      "Brackendale",
      "Brentwood Bay",
      "Bridesville",
      "Bridge Lake",
      "Brisco",
      "Britannia Beach",
      "Buffalo Creek",
      "Buick",
      "Burnaby",
      "Burns Lake",
      "Burton",
      "Cache Creek",
      "Campbell River",
      "Canal Flats",
      "Canim Lake",
      "Canoe",
      "Canyon",
      "Cassidy",
      "Castlegar",
      "Cawston",
      "Cecil Lake",
      "Cedarvale",
      "Celista",
      "Charlie Lake",
      "Chase",
      "Chemainus",
      "Cherryville",
      "Chetwynd",
      "Chilanko Forks",
      "Chilliwack",
      "Christina Lake",
      "Clayhurst",
      "Clearwater",
      "Clinton",
      "Coal Harbour",
      "Coalmont",
      "Cobble Hill",
      "Coldstream",
      "Comox",
      "Coombs",
      "Coquitlam",
      "Courtenay",
      "Cowichan Bay",
      "Cranbrook",
      "Crawford Bay",
      "Crescent Spur",
      "Crescent Valley",
      "Creston",
      "Crofton",
      "Cultus Lake",
      "Cumberland",
      "D'arcy",
      "Darfield",
      "Dawson Creek",
      "Dawsons Landing",
      "Dease Lake",
      "Delta",
      "Denman Island",
      "Denny Island",
      "Deroche",
      "Dewdney",
      "Dog Creek",
      "Dome Creek",
      "Douglas Lake",
      "Duncan",
      "Dunster",
      "Eagle Bay",
      "Eagle Creek",
      "Edgewater",
      "Edgewood",
      "Egmont",
      "Elkford",
      "Elko",
      "Endako",
      "Enderby",
      "Erickson",
      "Errington",
      "Esquimalt",
      "Fairmont Hot Springs",
      "Falkland",
      "Fanny Bay",
      "Farmington",
      "Fauquier",
      "Fernie",
      "Field",
      "Forest Grove",
      "Fort Fraser",
      "Fort Nelson",
      "Fort St. James",
      "Fort St. John",
      "Fort Steele",
      "Francois Lake",
      "Fraser Lake",
      "Fruitvale",
      "Furry Creek",
      "Gabriola",
      "Galiano",
      "Galloway",
      "Gang Ranch",
      "Garden Bay",
      "Garibaldi Highlands",
      "Genelle",
      "Germansen Landing",
      "Gibsons",
      "Gillies Bay",
      "Gitwinksihlkw",
      "Gold Bridge",
      "Gold River",
      "Golden",
      "Good Hope Lake",
      "Goodlow",
      "Grand Forks",
      "Granisle",
      "Grasmere",
      "Gray Creek",
      "Greenville",
      "Greenwood",
      "Grindrod",
      "Groundbirch",
      "Hagensborg",
      "Haisla",
      "Halfmoon Bay",
      "Hanceville",
      "Harrison Hot Springs",
      "Harrison Mills",
      "Harrogate",
      "Hartley Bay",
      "Hazelton",
      "Hedley",
      "Heffley Creek",
      "Heriot Bay",
      "Hixon",
      "Holberg",
      "Honeymoon Bay",
      "Hope",
      "Hornby Island",
      "Horsefly",
      "Houston",
      "Hudson's Hope",
      "Invermere",
      "Iskut",
      "Jade City",
      "Jaffray",
      "Jordan River",
      "Juskatla",
      "Kaleden",
      "Kamloops",
      "Kaslo",
      "Kelowna",
      "Keremeos",
      "Kildonan",
      "Kimberley",
      "Kincolith",
      "Kingcome Inlet",
      "Kingsgate",
      "Kitchener",
      "Kitimat",
      "Kitkatla",
      "Kitwanga",
      "Kleena Kleene",
      "Klemtu",
      "Knutsford",
      "Koksilah",
      "Kootenay Bay",
      "Kyuquot",
      "Lac La Hache",
      "Ladysmith",
      "Lake Cowichan",
      "Lake Errock",
      "Langley",
      "Lantzville",
      "Lasqueti",
      "Lavington",
      "Lax Kw'alaams",
      "Lazo",
      "Likely",
      "Lillooet",
      "Lindell Beach",
      "Lions Bay",
      "Lister",
      "Little Fort",
      "Logan Lake",
      "Lone Butte",
      "Longworth",
      "Louis Creek",
      "Lower Nicola",
      "Lower Post",
      "Lumby",
      "Lund",
      "Lytton",
      "Mackenzie",
      "Madeira Park",
      "Malahat",
      "Malakwa",
      "Manning Park",
      "Manson Creek",
      "Mansons Landing",
      "Maple Ridge",
      "Mara",
      "Masset",
      "Mayne",
      "Mcbride",
      "Mcleese Lake",
      "Mcleod Lake",
      "Mclure",
      "Meadow Creek",
      "Merritt",
      "Merville",
      "Mesachie Lake",
      "Meziadin Lake",
      "Mica Creek",
      "Midway",
      "Mill Bay",
      "Milner",
      "Minstrel Island",
      "Mission",
      "Moberly Lake",
      "Monte Creek",
      "Monte Lake",
      "Montney",
      "Montrose",
      "Mount Currie",
      "Moyie",
      "Muncho Lake",
      "Nakusp",
      "Nanaimo",
      "Nanoose Bay",
      "Naramata",
      "Nass Camp",
      "Nelson",
      "Nemaiah Valley",
      "New Denver",
      "New Hazelton",
      "New Songhees 1a",
      "New Westminster",
      "Nimpo Lake",
      "North Pine",
      "North Saanich",
      "North Vancouver",
      "Ocean Falls",
      "Okanagan Centre",
      "Okanagan Falls",
      "Oliver",
      "Oona River",
      "Osoyoos",
      "Oyama",
      "Panorama",
      "Parksville",
      "Parson",
      "Pavilion",
      "Peace River Rd",
      "Peachland",
      "Pemberton",
      "Pender Island",
      "Penny",
      "Penticton",
      "Pinantan Lake",
      "Pink Mountain",
      "Pitt Meadows",
      "Port Alberni",
      "Port Alice",
      "Port Clements",
      "Port Coquitlam",
      "Port Edward",
      "Port Hardy",
      "Port Mcneill",
      "Port Mellon",
      "Port Moody",
      "Port Neville",
      "Port Renfrew",
      "Pouce Coupe",
      "Powell River",
      "Prespatou",
      "Prince George",
      "Prince Rupert",
      "Princeton",
      "Pritchard",
      "Procter",
      "Progress",
      "Prophet River",
      "Qualicum Beach",
      "Quathiaski Cove",
      "Quatsino",
      "Queen Charlotte",
      "Quesnel",
      "Quilchena",
      "Radium Hot Springs",
      "Redstone",
      "Refuge Cove",
      "Revelstoke",
      "Richmond",
      "Riondel",
      "Riske Creek",
      "Roberts Creek",
      "Robson",
      "Rock Creek",
      "Rolla",
      "Rose Prairie",
      "Rosedale",
      "Rossland",
      "Royston",
      "Saanich",
      "Saanichton",
      "Salmo",
      "Salmon Arm",
      "Salt Spring Island",
      "Sandspit",
      "Saturna",
      "Savona",
      "Sayward",
      "Scotch Creek",
      "Sechelt",
      "Seton Portage",
      "Shalalth",
      "Shawnigan Lake",
      "Shirley",
      "Sicamous",
      "Sidney",
      "Silverton",
      "Simoom Sound",
      "Sinclair Mills",
      "Sirdar",
      "Skookumchuck",
      "Slocan Park",
      "Slocan",
      "Smithers",
      "Sointula",
      "Sooke",
      "Sorrento",
      "South Hazelton",
      "South Slocan",
      "Southbank",
      "Sparwood",
      "Spences Bridge",
      "Spillimacheen",
      "Squamish",
      "Squirrel Cove",
      "Stewart",
      "Stuart Island",
      "Summerland",
      "Summit Lake",
      "Sun Peaks",
      "Sunset Prairie",
      "Surge Narrows",
      "Surrey",
      "Swansea Point",
      "Ta Ta Creek",
      "Tahsis",
      "Takla Landing",
      "Tappen",
      "Tatla Lake",
      "Tatlayoko Lake",
      "Taylor",
      "Telegraph Cove",
      "Telegraph Creek",
      "Telkwa",
      "Terrace",
      "Thetis Island",
      "Thornhill",
      "Tlell",
      "Toad River",
      "Tofino",
      "Tomslake",
      "Topley",
      "Trail",
      "Tsawwassen",
      "Tsay Keh Dene",
      "Tulameen",
      "Tumbler Ridge",
      "Ucluelet",
      "Union Bay",
      "Upper Fraser",
      "Valemount",
      "Van Anda",
      "Vancouver",
      "Vanderhoof",
      "Vavenby",
      "Vernon",
      "Victoria",
      "Walhachin",
      "Wardner",
      "Ware",
      "Wasa",
      "Wells",
      "West Kelowna",
      "West Vancouver",
      "Westbank",
      "Westbridge",
      "Westholme",
      "Westwold",
      "Whaletown",
      "Whistler",
      "White Rock",
      "Williams Lake",
      "Willow River",
      "Windermere",
      "Winfield",
      "Winlaw",
      "Winter Harbour",
      "Wonowon",
      "Woss",
      "Wynndel",
      "Yahk",
      "Yale",
      "Ymir",
      "Youbou",
      "Zeballos"
    ]
  },
  {
    "name": "Saskatchewan",
    "key": "SK",
    "cities": [
      "Abbey",
      "Aberdeen",
      "Abernethy",
      "Admiral",
      "Air Ronge",
      "Alameda",
      "Albertville",
      "Alida",
      "Allan",
      "Alsask",
      "Alticane",
      "Alvena",
      "Aneroid",
      "Annaheim",
      "Antler",
      "Arborfield",
      "Archerwill",
      "Arcola",
      "Ardath",
      "Ardill",
      "Arelee",
      "Arran",
      "Asquith",
      "Assiniboia",
      "Atwater",
      "Avonhurst",
      "Avonlea",
      "Aylesbury",
      "Aylsham",
      "Balcarres",
      "Baldwinton",
      "Balgonie",
      "Bangor",
      "Bankend",
      "Barthel",
      "Bateman",
      "Battleford",
      "Beatty",
      "Beaubier",
      "Beauval",
      "Beaver Creek",
      "Beechy",
      "Belle Plaine",
      "Bellegarde",
      "Bengough",
      "Benson",
      "Bethune",
      "Bienfait",
      "Big Beaver",
      "Big River",
      "Biggar",
      "Birch Hills",
      "Birsay",
      "Bjorkdale",
      "Black Lake",
      "Bladworth",
      "Blaine Lake",
      "Blumenhof",
      "Borden",
      "Bracken",
      "Bradwell",
      "Bredenbury",
      "Briercrest",
      "Bright Sand",
      "Broadview",
      "Brock",
      "Brockington",
      "Broderick",
      "Bromhead",
      "Brooksby",
      "Brownlee",
      "Bruno",
      "Buchanan",
      "Buena Vista",
      "Buffalo Narrows",
      "Bulyea",
      "Burr",
      "Burstall",
      "Bushell Park",
      "Cabri",
      "Cactus Lake",
      "Cadillac",
      "Calder",
      "Candiac",
      "Candle Lake",
      "Cando",
      "Canoe Narrows",
      "Canora",
      "Canwood",
      "Cardross",
      "Carievale",
      "Carlton",
      "Carlyle",
      "Carmel",
      "Carnduff",
      "Caron",
      "Caronport",
      "Carragana",
      "Carrot River",
      "Casa Rio",
      "Central Butte",
      "Ceylon",
      "Chamberlain",
      "Chaplin",
      "Chelan",
      "Chitek Lake",
      "Choiceland",
      "Christopher Lake",
      "Churchbridge",
      "Clair",
      "Clavet",
      "Claybank",
      "Claydon",
      "Clearwater River",
      "Climax",
      "Cochin",
      "Coderre",
      "Codette",
      "Cole Bay",
      "Coleville",
      "Colfax",
      "Colgate",
      "Colonsay",
      "Congress",
      "Conquest",
      "Consul",
      "Coppersands",
      "Corman Park",
      "Corning",
      "Coronach",
      "Courval",
      "Cowessess",
      "Craik",
      "Crane Valley",
      "Craven",
      "Creelman",
      "Creighton",
      "Crooked River",
      "Crystal Springs",
      "Cudworth",
      "Cumberland House",
      "Cupar",
      "Cut Knife",
      "Cymric",
      "D'arcy Station",
      "Dafoe",
      "Dalmeny",
      "Danbury",
      "Davidson",
      "Davin",
      "Debden",
      "Deer Valley",
      "Delisle",
      "Delmas",
      "Demaine",
      "Denare Beach",
      "Denholm",
      "Denzil",
      "Deschambault Lake",
      "Dilke",
      "Dillon",
      "Dinsmore",
      "Dodsland",
      "Dollard",
      "Domremy",
      "Dorintosh",
      "Drake",
      "Drinkwater",
      "Dubuc",
      "Duck Lake",
      "Duff",
      "Dummer",
      "Dundurn",
      "Duperow",
      "Duval",
      "Dysart",
      "Eagle Ridge",
      "Earl Grey",
      "Eastend",
      "Eatonia",
      "Ebenezer",
      "Edam",
      "Edenwold",
      "Edgeley",
      "Elbow",
      "Elfros",
      "Elrose",
      "Elstow",
      "Emerald Park",
      "Endeavour",
      "Englefeld",
      "Ernfold",
      "Esterhazy",
      "Estevan",
      "Eston",
      "Evesham",
      "Eyebrow",
      "Fairlight",
      "Fairy Glen",
      "Fenwood",
      "Ferland",
      "Fertile",
      "Fife Lake",
      "Fillmore",
      "Findlater",
      "Fir Mountain",
      "Fiske",
      "Flaxcombe",
      "Fleming",
      "Flintoft",
      "Foam Lake",
      "Fond Du Lac",
      "Forget",
      "Fort Qu'appelle",
      "Fosston",
      "Fox Valley",
      "Foxford",
      "Francis",
      "Frenchman Butte",
      "Frobisher",
      "Frontier",
      "Fulda",
      "Furdale",
      "Gainsborough",
      "Gallivan",
      "Garrick",
      "Gerald",
      "Gladmar",
      "Glaslyn",
      "Glasnevin",
      "Glen Ewen",
      "Glenavon",
      "Glenbain",
      "Glenbush",
      "Glenside",
      "Glentworth",
      "Glidden",
      "Golden Prairie",
      "Goodeve",
      "Goodsoil",
      "Goodwater",
      "Gouldtown",
      "Govan",
      "Grandora",
      "Grasswood",
      "Gravelbourg",
      "Gray",
      "Grayson",
      "Green Lake",
      "Grenfell",
      "Griffin",
      "Gronlid",
      "Guernsey",
      "Gull Lake",
      "Hafford",
      "Hagen",
      "Hague",
      "Halbrite",
      "Handel",
      "Hanley",
      "Harris",
      "Hawarden",
      "Hazel Dell",
      "Hazenmore",
      "Hazlet",
      "Hearne",
      "Hendon",
      "Henribourg",
      "Hepburn",
      "Herbert",
      "Herschel",
      "Heward",
      "Hodgeville",
      "Hoey",
      "Holbein",
      "Holdfast",
      "Hoosier",
      "Hubbard",
      "Hudson Bay",
      "Humboldt",
      "Hyas",
      "Ile-a-la-crosse",
      "Imperial",
      "Indian Head",
      "Insinger",
      "Invermay",
      "Island Lake",
      "Ituna",
      "Jackfish Lake",
      "Jansen",
      "Jedburgh",
      "Kamsack",
      "Kayville",
      "Keeler",
      "Kelfield",
      "Kelliher",
      "Kelvington",
      "Kenaston",
      "Kendal",
      "Kennedy",
      "Kenosee Lake",
      "Kerrobert",
      "Khedive",
      "Killaly",
      "Kincaid",
      "Kindersley",
      "Kinistino",
      "Kinley",
      "Kinoosao",
      "Kipling",
      "Kisbey",
      "Kronau",
      "Krydor",
      "Kuroki",
      "Kyle",
      "Kylemore",
      "La Loche",
      "La Ronge",
      "Lac Vert",
      "Lacadena",
      "Lafleche",
      "Laird",
      "Lajord",
      "Lake Alma",
      "Lake Lenore",
      "Lampman",
      "Lancer",
      "Landis",
      "Lang",
      "Langbank",
      "Langenburg",
      "Langham",
      "Lanigan",
      "Laporte",
      "Lashburn",
      "Leader",
      "Leask",
      "Lebret",
      "Lemberg",
      "Leoville",
      "Leross",
      "Leroy",
      "Leslie",
      "Lestock",
      "Lewvan",
      "Liberty",
      "Liebenthal",
      "Limerick",
      "Lintlaw",
      "Lipton",
      "Lisieux",
      "Livelong",
      "Lloydminster",
      "Lockwood",
      "Lone Rock",
      "Loon Lake",
      "Loreburn",
      "Love",
      "Loverna",
      "Lucky Lake",
      "Lumsden",
      "Luseland",
      "Macdowall",
      "Macklin",
      "Macnutt",
      "Macoun",
      "Macrorie",
      "Madison",
      "Maidstone",
      "Main Centre",
      "Major",
      "Makwa",
      "Mankota",
      "Manor",
      "Mantario",
      "Maple Creek",
      "Marcelin",
      "Marengo",
      "Margo",
      "Markinch",
      "Marquis",
      "Marsden",
      "Marshall",
      "Martensville",
      "Maryfield",
      "Mayfair",
      "Maymont",
      "Mayview",
      "Mazenod",
      "Mccord",
      "Mclean",
      "Mcmahon",
      "Mctaggart",
      "Meacham",
      "Meadow Lake",
      "Meath Park",
      "Medstead",
      "Melfort",
      "Melville",
      "Mendham",
      "Meota",
      "Mervin",
      "Meskanaw",
      "Meyronne",
      "Midale",
      "Middle Lake",
      "Mikado",
      "Milden",
      "Mildred",
      "Milestone",
      "Minton",
      "Mistatim",
      "Mont Nebo",
      "Montmartre",
      "Montreal Lake",
      "Moose Jaw",
      "Moosomin",
      "Morse",
      "Mortlach",
      "Mossbank",
      "Mozart",
      "Muenster",
      "Mullingar",
      "Muskoday",
      "Naicam",
      "Neidpath",
      "Neilburg",
      "Netherhill",
      "Neudorf",
      "Neville",
      "Nipawin",
      "Nokomis",
      "Norquay",
      "North Battleford",
      "North Portal",
      "North Weyburn",
      "Northgate",
      "Nut Mountain",
      "Odessa",
      "Ogema",
      "Okla",
      "Onion Lake",
      "Orkney",
      "Ormiston",
      "Osage",
      "Osler",
      "Oungre",
      "Outlook",
      "Oxbow",
      "Paddockwood",
      "Pakwaw Lake",
      "Palmer",
      "Pambrun",
      "Pangman",
      "Paradise Hill",
      "Parkbeg",
      "Parkerview",
      "Parkman",
      "Parkside",
      "Parry",
      "Pasqua",
      "Pathlow",
      "Patuanak",
      "Paynton",
      "Peebles",
      "Pelican Narrows",
      "Pelly",
      "Pennant Station",
      "Pense",
      "Penzance",
      "Perdue",
      "Phippen",
      "Piapot",
      "Pierceland",
      "Pilger",
      "Pilot Butte",
      "Pinehouse Lake",
      "Plato",
      "Pleasantdale",
      "Plenty",
      "Plunkett",
      "Ponteix",
      "Porcupine Plain",
      "Portreeve",
      "Prairie River",
      "Prairie View",
      "Preeceville",
      "Prelate",
      "Primate",
      "Prince Albert",
      "Prud'homme",
      "Punnichy",
      "Qu'appelle",
      "Quill Lake",
      "Quinton",
      "Rabbit Lake",
      "Radisson",
      "Radville",
      "Rama",
      "Rapid View",
      "Raymore",
      "Red Earth",
      "Redvers",
      "Regina Beach",
      "Regina",
      "Reward",
      "Rhein",
      "Riceton",
      "Richard",
      "Richardson",
      "Richlea",
      "Richmound",
      "Ridgedale",
      "Riverhurst",
      "Riverside Estates",
      "Robsart",
      "Rocanville",
      "Rockglen",
      "Rockhaven",
      "Rokeby",
      "Rose Valley",
      "Rosetown",
      "Rosthern",
      "Rouleau",
      "Ruddell",
      "Runnymede",
      "Rush Lake",
      "Ruthilda",
      "Saltcoats",
      "Sandy Bay",
      "Saskatoon",
      "Sceptre",
      "Scott",
      "Scout Lake",
      "Sedley",
      "Semans",
      "Senlac",
      "Shackleton",
      "Shamrock",
      "Shaunavon",
      "Sheho",
      "Shell Lake",
      "Shellbrook",
      "Shipman",
      "Silton",
      "Simmie",
      "Simpson",
      "Sintaluta",
      "Smeaton",
      "Smiley",
      "Snowden",
      "Sonningdale",
      "Southend",
      "Southey",
      "Sovereign",
      "Spalding",
      "Speers",
      "Spiritwood",
      "Spring Valley",
      "Springside",
      "Springwater",
      "Spruce Home",
      "Spruce Lake",
      "Spy Hill",
      "St Benedict",
      "St Brieux",
      "St Denis",
      "St Gregor",
      "St Isidore De Bellevue",
      "St Louis",
      "St Victor",
      "St Walburg",
      "Stalwart",
      "Stanley Mission",
      "Star City",
      "Steelman",
      "Stenen",
      "Stewart Valley",
      "Stockholm",
      "Stony Beach",
      "Stony Rapids",
      "Stornoway",
      "Storthoaks",
      "Stoughton",
      "Stranraer",
      "Strasbourg",
      "Strongfield",
      "Stump Lake",
      "Sturgeon Landing",
      "Sturgis",
      "Success",
      "Summerberry",
      "Swift Current",
      "Sylvania",
      "Tantallon",
      "Tessier",
      "Theodore",
      "Timber Bay",
      "Tisdale",
      "Togo",
      "Tompkins",
      "Torquay",
      "Tramping Lake",
      "Tribune",
      "Trossachs",
      "Truax",
      "Tuffnell",
      "Tugaske",
      "Turnor Lake",
      "Turtleford",
      "Tuxford",
      "Tway",
      "Tyner",
      "Tyvan",
      "Unity",
      "Uranium City",
      "Val Marie",
      "Vanguard",
      "Vanscoy",
      "Vawn",
      "Veregin",
      "Verwood",
      "Vibank",
      "Viceroy",
      "Victoire",
      "Vidora",
      "Viscount",
      "Vonda",
      "Wadena",
      "Wakaw",
      "Waldeck",
      "Waldheim",
      "Waldron",
      "Wapella",
      "Warman",
      "Waseca",
      "Waskesiu Lake",
      "Waterhen Lake",
      "Watrous",
      "Watson",
      "Wauchope",
      "Wawota",
      "Webb",
      "Weekes",
      "Weirdale",
      "Weldon",
      "Welwyn",
      "West Bend",
      "Weyakwin",
      "Weyburn",
      "Whelan",
      "White Bear",
      "White City",
      "White Fox",
      "Whitecap",
      "Whitewood",
      "Wilcox",
      "Wilkie",
      "Willow Bunch",
      "Willowbrook",
      "Windthorst",
      "Wiseton",
      "Wishart",
      "Wollaston Lake",
      "Wolseley",
      "Wood Mountain",
      "Woodrow",
      "Wroxton",
      "Wymark",
      "Wynyard",
      "Yarbo",
      "Yellow Creek",
      "Yellow Grass",
      "Yellow Quill",
      "Yorkton",
      "Young",
      "Zealandia",
      "Zehner",
      "Zenon Park"
    ]
  },
  {
    "name": "Alberta",
    "key": "AB",
    "cities": [
      "Abee",
      "Acadia Valley",
      "Acheson",
      "Acme",
      "Aden",
      "Aetna",
      "Airdrie",
      "Alberta Beach",
      "Alcomdale",
      "Alder Flats",
      "Aldersyde",
      "Alhambra",
      "Alix",
      "Alliance",
      "Alsike",
      "Altario",
      "Amisk",
      "Andrew",
      "Anzac",
      "Ardmore",
      "Ardrossan",
      "Armena",
      "Arrowwood",
      "Ashmont",
      "Athabasca",
      "Atikameg",
      "Atmore",
      "Balzac",
      "Banff",
      "Barnwell",
      "Barons",
      "Barrhead",
      "Bashaw",
      "Bassano",
      "Bawlf",
      "Bay Tree",
      "Bear Canyon",
      "Beaumont",
      "Beauvallon",
      "Beaverlodge",
      "Beiseker",
      "Bellevue",
      "Bellis",
      "Benalto",
      "Bentley",
      "Berwyn",
      "Bezanson",
      "Big Stone",
      "Big Valley",
      "Bindloss",
      "Birchcliff",
      "Bittern Lake",
      "Black Diamond",
      "Blackfalds",
      "Blackfoot",
      "Blackie",
      "Blairmore",
      "Bloomsbury",
      "Blue Ridge",
      "Blueberry Mountain",
      "Bluesky",
      "Bluffton",
      "Bodo",
      "Bon Accord",
      "Bonanza",
      "Bonnyville",
      "Botha",
      "Bow Island",
      "Bowden",
      "Boyle",
      "Boyne Lake",
      "Bragg Creek",
      "Brant",
      "Breton",
      "Breynat",
      "Brocket",
      "Brooks",
      "Brosseau",
      "Brownfield",
      "Brownvale",
      "Bruce",
      "Bruderheim",
      "Brule",
      "Buck Creek",
      "Buck Lake",
      "Buffalo Head Prairie",
      "Buffalo",
      "Burdett",
      "Busby",
      "Byemoor",
      "Cadogan",
      "Cadomin",
      "Cadotte Lake",
      "Calahoo",
      "Calais",
      "Calgary",
      "Calling Lake",
      "Calmar",
      "Camp Creek",
      "Camrose",
      "Canmore",
      "Canyon Creek",
      "Carbon",
      "Carcajou",
      "Cardston",
      "Carmangay",
      "Carnwood",
      "Caroline",
      "Carrot Creek",
      "Carseland",
      "Carstairs",
      "Carvel",
      "Caslan",
      "Castor",
      "Cayley",
      "Cereal",
      "Cessford",
      "Champion",
      "Chard",
      "Chateh",
      "Chauvin",
      "Cherhill",
      "Cherry Grove",
      "Cherry Point",
      "Chestermere",
      "Chinook",
      "Chipman",
      "Chisholm Mills",
      "Clairmont",
      "Clandonald",
      "Claresholm",
      "Cleardale",
      "Clive",
      "Cluny",
      "Clyde",
      "Coaldale",
      "Coalhurst",
      "Cochrane",
      "Cold Lake",
      "Coleman",
      "Colinton",
      "College Heights",
      "Compeer",
      "Condor",
      "Conklin",
      "Consort",
      "Cooking Lake",
      "Coronation",
      "County Of Grande Prairie No. 1",
      "Coutts",
      "Cowley",
      "Craigmyle",
      "Cranford",
      "Cremona",
      "Crooked Creek",
      "Crossfield",
      "Cynthia",
      "Cypress County",
      "Czar",
      "Dalemead",
      "Dapp",
      "Darwell",
      "Daysland",
      "De Winton",
      "Dead Man's Flats",
      "Deadwood",
      "Debolt",
      "Del Bonita",
      "Delacour",
      "Delburne",
      "Delia",
      "Demmitt",
      "Denwood",
      "Derwent",
      "Desert Blume",
      "Devon",
      "Dewberry",
      "Diamond City",
      "Didsbury",
      "Dixonville",
      "Donalda",
      "Donnelly",
      "Dorothy",
      "Drayton Valley",
      "Driftpile",
      "Drumheller",
      "Duchess",
      "Duffield",
      "Dunmore",
      "Eaglesham",
      "East Coulee",
      "Eckville",
      "Edberg",
      "Edgerton",
      "Edmonton",
      "Edson",
      "Egremont",
      "Elk Point",
      "Elkwater",
      "Ellscott",
      "Elmworth",
      "Elnora",
      "Empress",
      "Enchant",
      "Endiang",
      "Enilda",
      "Enoch",
      "Entwistle",
      "Erskine",
      "Esther",
      "Etzikom",
      "Eureka River",
      "Evansburg",
      "Exshaw",
      "Fairview",
      "Falher",
      "Fallis",
      "Falun",
      "Faust",
      "Fawcett",
      "Fenn",
      "Ferintosh",
      "Finnegan",
      "Fitzgerald",
      "Flatbush",
      "Foisy",
      "Foremost",
      "Forestburg",
      "Fort Assiniboine",
      "Fort Chipewyan",
      "Fort Kent",
      "Fort Mackay",
      "Fort Macleod",
      "Fort Mcmurray",
      "Fort Saskatchewan",
      "Fort Vermilion",
      "Fox Creek",
      "Fox Lake",
      "Frog Lake",
      "Gadsby",
      "Gainford",
      "Galahad",
      "Garden River",
      "Gem",
      "Gibbons",
      "Gift Lake",
      "Girouxville",
      "Gleichen",
      "Glendon",
      "Glenevis",
      "Glenwood",
      "Goodfare",
      "Goodfish Lake",
      "Goodridge",
      "Gordondale",
      "Grande Cache",
      "Grande Prairie",
      "Granum",
      "Grassland",
      "Grassy Lake",
      "Grimshaw",
      "Grouard",
      "Grovedale",
      "Gundy",
      "Gunn",
      "Guy",
      "Gwynne",
      "Hairy Hill",
      "Halfmoon Bay",
      "Halkirk",
      "Hanna",
      "Hardisty",
      "Harvie Heights",
      "Hay Lakes",
      "Hays",
      "Hayter",
      "Heinsburg",
      "Heisler",
      "High Level",
      "High Prairie",
      "High River",
      "Hilda",
      "Hill Spring",
      "Hillcrest Mines",
      "Hilliard",
      "Hines Creek",
      "Hinton",
      "Hobbema",
      "Holden",
      "Hondo",
      "Hotchkiss",
      "Hughenden",
      "Hussar",
      "Huxley",
      "Hylo",
      "Hythe",
      "Iddesleigh",
      "Indus",
      "Innisfail",
      "Innisfree",
      "Irma",
      "Iron River",
      "Iron Springs",
      "Irricana",
      "Irvine",
      "Island Lake South",
      "Island Lake",
      "Islay",
      "James River Bridge",
      "Jarvie",
      "Jarvis Bay",
      "Jasper",
      "Jean Cote",
      "Jenner",
      "John D'or Prairie",
      "Joussard",
      "Kananaskis",
      "Kapasiwin",
      "Kathyrn",
      "Keg River",
      "Kehewin",
      "Kelsey",
      "Keoma",
      "Kikino",
      "Killam",
      "Kingman",
      "Kinsella",
      "Kinuso",
      "Kirriemuir",
      "Kitscoty",
      "La Corey",
      "La Crete",
      "La Glace",
      "Lac Des Arcs",
      "Lac La Biche",
      "Lacombe County",
      "Lacombe",
      "Lafond",
      "Lake Isle",
      "Lake Louise",
      "Lamont",
      "Lancaster Park",
      "Langdon",
      "Lavoy",
      "Leduc County",
      "Leduc",
      "Legal",
      "Leslieville",
      "Lethbridge",
      "Lindale",
      "Lindbergh",
      "Linden",
      "Little Smoky",
      "Lloydminster",
      "Lodgepole",
      "Lomond",
      "Lone Pine",
      "Longview",
      "Lougheed",
      "Lousana",
      "Lundbreck",
      "Lyalta",
      "Ma-me-o Beach",
      "Madden",
      "Magrath",
      "Maleb",
      "Mallaig",
      "Manning",
      "Mannville",
      "Manyberries",
      "Marie Reine",
      "Markerville",
      "Marwayne",
      "Mayerthorpe",
      "Mclaughlin",
      "Mclennan",
      "Mcrae",
      "Meander River",
      "Medicine Hat",
      "Meeting Creek",
      "Metiskow",
      "Milk River",
      "Millarville",
      "Millet",
      "Millicent",
      "Milo",
      "Minburn",
      "Mirror",
      "Monarch",
      "Monitor",
      "Morinville",
      "Morley",
      "Morrin",
      "Mossleigh",
      "Mountain View",
      "Mulhurst Bay",
      "Mundare",
      "Munson",
      "Musidora",
      "Myrnam",
      "Nacmine",
      "Namao",
      "Nampa",
      "Nanton",
      "Neerlandia",
      "Nestow",
      "Nevis",
      "New Brigden",
      "New Dayton",
      "New Norway",
      "Newbrook",
      "Nisku",
      "Niton Junction",
      "Nobleford",
      "Nordegg",
      "Norglenwold",
      "North Star",
      "Notikewin",
      "Ohaton",
      "Okotoks",
      "Olds",
      "Onefour",
      "Onoway",
      "Opal",
      "Orion",
      "Oyen",
      "Paddle Prairie",
      "Paradise Valley",
      "Parkland",
      "Patricia",
      "Peace River",
      "Peerless Lake",
      "Peers",
      "Penhold",
      "Perryvale",
      "Pickardville",
      "Picture Butte",
      "Pincher Creek",
      "Pine Lake",
      "Plamondon",
      "Pollockville",
      "Ponoka",
      "Priddis",
      "Provost",
      "Purple Springs",
      "Radway",
      "Rainbow Lake",
      "Rainier",
      "Ralston",
      "Ranfurly",
      "Raymond",
      "Red Deer County",
      "Red Deer",
      "Red Earth Creek",
      "Red Willow",
      "Redcliff",
      "Redwater",
      "Redwood Meadows",
      "Rimbey",
      "Rivercourse",
      "Riviere Qui Barre",
      "Robb",
      "Rochester",
      "Rochfort Bridge",
      "Rochon Sands",
      "Rocky Mountain House",
      "Rocky Rapids",
      "Rocky View",
      "Rockyford",
      "Rolling Hills",
      "Rolly View",
      "Rosalind",
      "Rosebud",
      "Rosedale Station",
      "Rosedale Valley",
      "Rosemary",
      "Round Hill",
      "Rowley",
      "Rumsey",
      "Rycroft",
      "Ryley",
      "Saddle Lake",
      "Sangudo",
      "Scandia",
      "Schuler",
      "Seba Beach",
      "Sedalia",
      "Sedgewick",
      "Seven Persons",
      "Sexsmith",
      "Shaughnessy",
      "Sherwood Park",
      "Sibbald",
      "Siksika",
      "Silver Valley",
      "Skiff",
      "Slave Lake",
      "Smith",
      "Smoky Lake",
      "South Baptiste",
      "Spedden",
      "Spirit River",
      "Spring Coulee",
      "Spring Lake",
      "Springbrook",
      "Spruce Grove",
      "Spruce View",
      "Sputinow",
      "St Brides",
      "St Isidore",
      "St Lina",
      "St Michael",
      "St Paul",
      "St Vincent",
      "St. Albert",
      "Stand Off",
      "Standard",
      "Star",
      "Stauffer",
      "Stavely",
      "Stettler",
      "Stirling",
      "Stony Plain",
      "Strathmore",
      "Streamstown",
      "Strome",
      "Sturgeon County",
      "Sundre",
      "Sunnybrook",
      "Sunnynook",
      "Sunset Beach",
      "Sunset House",
      "Swalwell",
      "Swan Hills",
      "Sylvan Lake",
      "Taber",
      "Tangent",
      "Tawatinaw",
      "Tees",
      "Thorhild",
      "Thorsby",
      "Three Hills",
      "Tiger Lily",
      "Tilley",
      "Tofield",
      "Tomahawk",
      "Torrington",
      "Trochu",
      "Trout Lake",
      "Tsuu T'ina",
      "Tulliby Lake",
      "Turin",
      "Turner Valley",
      "Twin Butte",
      "Two Hills",
      "Valhalla Centre",
      "Valleyview",
      "Vauxhall",
      "Vega",
      "Vegreville",
      "Vermilion",
      "Veteran",
      "Viking",
      "Vilna",
      "Vimy",
      "Vulcan",
      "Wabamun",
      "Wabasca",
      "Wainwright",
      "Walsh",
      "Wandering River",
      "Wanham",
      "Warburg",
      "Wardlow",
      "Warner",
      "Warspite",
      "Waskatenau",
      "Water Valley",
      "Waterton Park",
      "Watino",
      "Welling",
      "Wembley",
      "West Baptiste",
      "Westerose",
      "Westlock",
      "Wetaskiwin",
      "Whispering Hills",
      "White Gull",
      "Whitecourt",
      "Whitelaw",
      "Widewater",
      "Wildwood",
      "Willingdon",
      "Wimborne",
      "Winfield",
      "Woking",
      "Worsley",
      "Wostok",
      "Wrentham",
      "Youngstown",
      "Zama City"
    ]
  },
  {
    "name": "Quebec",
    "key": "QC",
    "cities": [
      "Abercorn",
      "Acton Vale",
      "Adstock",
      "Aguanish",
      "Akulivik",
      "Akwesasne",
      "Albanel",
      "Albertville",
      "Alcove",
      "Alma",
      "Alouette",
      "Amherst",
      "Amos",
      "Amqui",
      "Ange-gardien",
      "Angliers",
      "Anjou",
      "Armagh",
      "Arntfield",
      "Arundel",
      "Asbestos",
      "Ascot Corner",
      "Aston-jonction",
      "Athelstan",
      "Auclair",
      "Audet",
      "Aumond",
      "Aupaluk",
      "Austin",
      "Authier",
      "Authier-nord",
      "Ayer's Cliff",
      "Baie-comeau",
      "Baie-d'urfe",
      "Baie-des-sables",
      "Baie-du-febvre",
      "Baie-johan-beetz",
      "Baie-saint-paul",
      "Baie-sainte-catherine",
      "Baie-trinite",
      "Barachois",
      "Barkmere",
      "Barraute",
      "Bassin",
      "Batiscan",
      "Beaconsfield",
      "Bearn",
      "Beaucanton",
      "Beauceville",
      "Beauharnois",
      "Beaulac-garthby",
      "Beaumont",
      "Beaupre",
      "Becancour",
      "Bedford",
      "Begin",
      "Belcourt",
      "Bellecombe",
      "Belleterre",
      "Beloeil",
      "Berry",
      "Berthier-sur-mer",
      "Berthierville",
      "Bethanie",
      "Betsiamites",
      "Biencourt",
      "Bishopton",
      "Blainville",
      "Blanc-sablon",
      "Blue Sea",
      "Boileau",
      "Bois-des-filion",
      "Bois-franc",
      "Boisbriand",
      "Boischatel",
      "Bolton-est",
      "Bolton-ouest",
      "Bon-conseil",
      "Bonaventure",
      "Bonsecours",
      "Boucherville",
      "Bouchette",
      "Boulanger",
      "Bowman",
      "Brador",
      "Brebeuf",
      "Brigham",
      "Bristol",
      "Brome",
      "Bromont",
      "Brossard",
      "Brownsburg-chatham",
      "Bryson",
      "Buckland",
      "Bury",
      "Cabano",
      "Cacouna",
      "Cadillac",
      "Calixa-lavallee",
      "Campbell's Bay",
      "Candiac",
      "Cantley",
      "Canton Bedford",
      "Canton Stanstead",
      "Canton Tremblay",
      "Canton-de-hatley",
      "Cap-au-renard",
      "Cap-aux-meules",
      "Cap-chat",
      "Cap-chat-est",
      "Cap-d'espoir",
      "Cap-saint-ignace",
      "Cap-sante",
      "Caplan",
      "Capucins",
      "Carignan",
      "Carleton",
      "Cascapedia-saint-jules",
      "Causapscal",
      "Cazaville",
      "Chambly",
      "Chambord",
      "Champlain",
      "Champneuf",
      "Chandler",
      "Chapais",
      "Chapeau",
      "Charette",
      "Charlemagne",
      "Charny",
      "Chartierville",
      "Chateau-richer",
      "Chateauguay",
      "Chazel",
      "Chelsea",
      "Cheneville",
      "Chertsey",
      "Chesterville",
      "Chevery",
      "Chibougamau",
      "Chicoutimi",
      "Chisasibi",
      "Chomedey",
      "Chute-aux-outardes",
      "Chute-saint-philippe",
      "Clarenceville",
      "Clarke City",
      "Clericy",
      "Clermont",
      "Clerval",
      "Cleveland",
      "Cloridorme",
      "Cloutier",
      "Clova",
      "Coaticook",
      "Colombier",
      "Compton",
      "Contrecoeur",
      "Cookshire-eaton",
      "Cote Saint-luc",
      "Coteau-du-lac",
      "Courcelette",
      "Courcelles",
      "Cowansville",
      "Crabtree",
      "D'alembert",
      "Dalhousie",
      "Dalmas",
      "Danford Lake",
      "Danville",
      "Daveluyville",
      "Davidson",
      "Degelis",
      "Deleage",
      "Delson",
      "Denholm",
      "Desbiens",
      "Deschaillons-sur-saint-laurent",
      "Deschambault",
      "Desmaraisville",
      "Desmeloizes",
      "Destor",
      "Deux-montagnes",
      "Dewittville",
      "Disraeli",
      "Dixville",
      "Dolbeau-mistassini",
      "Dollard-des-ormeaux",
      "Donnacona",
      "Dorval",
      "Dosquet",
      "Drummondville",
      "Duclos",
      "Duhamel",
      "Duhamel-ouest",
      "Dunham",
      "Duparquet",
      "Dupuy",
      "Durham-sud",
      "East Angus",
      "East Broughton Station",
      "East Broughton",
      "East Farnham",
      "East Hereford",
      "Eastmain",
      "Eastman",
      "Egan",
      "Elgin",
      "Em-1-a-sarcelle-rupert",
      "Entrelacs",
      "Escuminac",
      "Esprit-saint",
      "Esterel",
      "Evain",
      "Fabre",
      "Fabreville",
      "Falardeau",
      "Farnham",
      "Farrellton",
      "Fassett",
      "Fatima",
      "Ferland-et-boilleau",
      "Ferme-neuve",
      "Fermont",
      "Forestville",
      "Fort-coulonge",
      "Fortierville",
      "Fossambault-sur-le-lac",
      "Foster",
      "Frampton",
      "Franklin",
      "Franquelin",
      "Frelighsburg",
      "Frontenac",
      "Fugereville",
      "Fulford",
      "Gallichan",
      "Gallix",
      "Gascons",
      "Gaspe",
      "Gatineau",
      "Georgeville",
      "Gethsemani",
      "Girardville",
      "Godbout",
      "Godmanchester",
      "Gore",
      "Gracefield",
      "Granby",
      "Grand-mere",
      "Grand-remous",
      "Grand-saint-esprit",
      "Grande-entree",
      "Grande-riviere",
      "Grande-riviere-ouest",
      "Grande-vallee",
      "Grandes-bergeronnes",
      "Grandes-piles",
      "Greenfield Park",
      "Grenville",
      "Grenville-sur-la-rouge",
      "Grondines",
      "Gros-morne",
      "Grosse-ile",
      "Grosses-roches",
      "Guerin",
      "Guyenne",
      "Ham-nord",
      "Hampstead",
      "Harrington Harbour",
      "Harrington",
      "Hatley",
      "Havelock",
      "Havre-aubert",
      "Havre-aux-maisons",
      "Havre-saint-pierre",
      "Hebertville",
      "Hebertville-station",
      "Hemmingford",
      "Henryville",
      "Herouxville",
      "Hinchinbrooke",
      "Honfleur",
      "Hope",
      "Howick",
      "Huberdeau",
      "Hudson Heights",
      "Hudson",
      "Huntingdon",
      "Ile Du Grand-calumet",
      "Ile-aux-noix",
      "Inukjuak",
      "Inverness",
      "Irlande",
      "Isle-aux-coudres",
      "Issoudun",
      "Ivry-sur-le-lac",
      "Ivujivik",
      "Jacques-cartier",
      "Joliette",
      "Joly",
      "Jonquiere",
      "Kahnawake",
      "Kamouraska",
      "Kangiqsualujjuaq",
      "Kangiqsujuaq",
      "Kangirsuk",
      "Kawawachikamach",
      "Kazabazua",
      "Kegaska",
      "Kiamika",
      "Kingsbury",
      "Kingsey Falls",
      "Kinnear's Mills",
      "Kipawa",
      "Kirkland",
      "Knowlton",
      "Kuujjuaq",
      "Kuujjuarapik",
      "L'alverne",
      "L'ancienne-lorette",
      "L'ange Gardien",
      "L'ange-gardien",
      "L'anse-pleureuse",
      "L'anse-saint-jean",
      "L'ascension",
      "L'ascension-de-notre-seigneur",
      "L'ascension-de-patapedia",
      "L'assomption",
      "L'avenir",
      "L'epiphanie",
      "L'etang-du-nord",
      "L'ile-bizard",
      "L'ile-cadieux",
      "L'ile-d'entree",
      "L'ile-michon",
      "L'ile-perrot",
      "L'isle-aux-grues",
      "L'isle-verte",
      "L'islet",
      "L'isletville",
      "La Baie",
      "La Baleine",
      "La Bostonnais",
      "La Conception",
      "La Corne",
      "La Croche",
      "La Dore",
      "La Durantaye",
      "La Guadeloupe",
      "La Macaza",
      "La Malbaie",
      "La Martre",
      "La Minerve",
      "La Morandiere",
      "La Motte",
      "La Patrie",
      "La Pocatiere",
      "La Prairie",
      "La Presentation",
      "La Redemption",
      "La Reine",
      "La Sarre",
      "La Tabatiere",
      "La Trinite-des-monts",
      "La Tuque",
      "La Visitation-de-l'ile-dupas",
      "La Visitation-de-yamaska",
      "Labelle",
      "Labrecque",
      "Lac Des Loups",
      "Lac-a-la-tortue",
      "Lac-au-saumon",
      "Lac-aux-sables",
      "Lac-beauport",
      "Lac-bouchette",
      "Lac-cayamant",
      "Lac-delage",
      "Lac-des-aigles",
      "Lac-des-ecorces",
      "Lac-des-iles",
      "Lac-des-plages",
      "Lac-des-seize-iles",
      "Lac-drolet",
      "Lac-du-cerf",
      "Lac-edouard",
      "Lac-etchemin",
      "Lac-frontiere",
      "Lac-humqui",
      "Lac-kenogami",
      "Lac-megantic",
      "Lac-saguay",
      "Lac-saint-joseph",
      "Lac-saint-paul",
      "Lac-sainte-marie",
      "Lac-sergent",
      "Lac-simon",
      "Lac-superieur",
      "Lachine",
      "Lachute",
      "Lacolle",
      "Ladriere",
      "Ladysmith",
      "Laforce",
      "Lamarche",
      "Lamartine",
      "Lambton",
      "Landrienne",
      "Laniel",
      "Lanoraie",
      "Lantier",
      "Larouche",
      "Lasalle",
      "Laterriere",
      "Latulipe",
      "Launay",
      "Laurier-station",
      "Laurierville",
      "Laval",
      "Laval-ouest",
      "Lavaltrie",
      "Laverlochere",
      "Lawrenceville",
      "Le Bic",
      "Le Gardeur",
      "Lebel-sur-quevillon",
      "Leclercville",
      "Lefebvre",
      "Lejeune",
      "Lemieux",
      "Lemoyne",
      "Lery",
      "Les Buissons",
      "Les Cedres",
      "Les Coteaux",
      "Les Eboulements",
      "Les Escoumins",
      "Les Hauteurs",
      "Les Mechins",
      "Levis",
      "Lingwick",
      "Listuguj",
      "Longue-pointe-de-mingan",
      "Longue-rive",
      "Longueuil",
      "Lorraine",
      "Lorrainville",
      "Lotbiniere",
      "Lots-renverses",
      "Louiseville",
      "Lourdes",
      "Lourdes-de-blanc-sablon",
      "Lourdes-de-joliette",
      "Low",
      "Luskville",
      "Lyster",
      "Macamic",
      "Maddington",
      "Madeleine-centre",
      "Magog",
      "Magpie",
      "Malartic",
      "Maliotenam",
      "Mancebourg",
      "Manche-d'epee",
      "Mandeville",
      "Maniwaki",
      "Manouane",
      "Manseau",
      "Mansfield",
      "Mansonville",
      "Maple Grove",
      "Marbleton",
      "Maria",
      "Maricourt",
      "Marieville",
      "Marsoui",
      "Marston",
      "Martinville",
      "Mascouche",
      "Mashteuiatsh",
      "Maskinonge",
      "Massueville",
      "Matagami",
      "Matane",
      "Matapedia",
      "Mayo",
      "Mcmasterville",
      "Mcwatters",
      "Melbourne",
      "Melocheville",
      "Mercier",
      "Messines",
      "Metabetchouan-lac-a-la-croix",
      "Metis-sur-mer",
      "Middle Bay",
      "Milan",
      "Mille-isles",
      "Miquelon",
      "Mirabel",
      "Mistissini",
      "Moffet",
      "Moisie",
      "Mont-brun",
      "Mont-carmel",
      "Mont-joli",
      "Mont-laurier",
      "Mont-louis",
      "Mont-royal",
      "Mont-saint-gregoire",
      "Mont-saint-hilaire",
      "Mont-saint-michel",
      "Mont-saint-pierre",
      "Mont-tremblant",
      "Montbeillard",
      "Montcalm",
      "Montcerf-lytton",
      "Montebello",
      "Montmagny",
      "Montpellier",
      "Montreal",
      "Montreal-est",
      "Montreal-nord",
      "Montreal-ouest",
      "Morin-heights",
      "Mulgrave-et-derry",
      "Murdochville",
      "Mutton Bay",
      "Namur",
      "Nantes",
      "Napierville",
      "Natashquan",
      "Nedelec",
      "Nemiscau",
      "Neuville",
      "New Carlisle",
      "New Richmond",
      "Newport",
      "Nicolet",
      "Nominingue",
      "Normandin",
      "Normetal",
      "North Hatley",
      "Notre-dame-de-ham",
      "Notre-dame-de-l'ile-perrot",
      "Notre-dame-de-la-merci",
      "Notre-dame-de-la-paix",
      "Notre-dame-de-la-salette",
      "Notre-dame-de-lorette",
      "Notre-dame-de-montauban",
      "Notre-dame-de-pontmain",
      "Notre-dame-de-stanbridge",
      "Notre-dame-des-bois",
      "Notre-dame-des-monts",
      "Notre-dame-des-pins",
      "Notre-dame-des-prairies",
      "Notre-dame-des-sept-douleurs",
      "Notre-dame-du-lac",
      "Notre-dame-du-laus",
      "Notre-dame-du-mont-carmel",
      "Notre-dame-du-nord",
      "Notre-dame-du-portage",
      "Notre-dame-du-rosaire",
      "Nouvelle",
      "Nouvelle-ouest",
      "Noyan",
      "Obedjiwan",
      "Odanak",
      "Ogden",
      "Oka",
      "Old Fort Bay",
      "Orford",
      "Ormstown",
      "Otter Lake",
      "Otterburn Park",
      "Ouje-bougoumou",
      "Outremont",
      "Pabos Mills",
      "Pabos",
      "Packington",
      "Padoue",
      "Palmarolle",
      "Papineauville",
      "Parent",
      "Parisville",
      "Paspebiac",
      "Perce",
      "Peribonka",
      "Petit-saguenay",
      "Petite-riviere-saint-francois",
      "Petite-vallee",
      "Philipsburg",
      "Piedmont",
      "Pierrefonds",
      "Pierreville",
      "Pike River",
      "Pincourt",
      "Pintendre",
      "Piopolis",
      "Plaisance",
      "Plessisville",
      "Pohenegamook",
      "Pointe-a-la-croix",
      "Pointe-a-la-garde",
      "Pointe-aux-loups",
      "Pointe-aux-outardes",
      "Pointe-aux-trembles",
      "Pointe-calumet",
      "Pointe-claire",
      "Pointe-des-cascades",
      "Pointe-fortune",
      "Pointe-lebel",
      "Pont-rouge",
      "Port-cartier",
      "Port-daniel",
      "Port-menier",
      "Portage-du-fort",
      "Portneuf",
      "Portneuf-sur-mer",
      "Poularies",
      "Preissac",
      "Prevost",
      "Price",
      "Princeville",
      "Proulxville",
      "Puvirnituq",
      "Quaqtaq",
      "Quebec",
      "Quyon",
      "Racine",
      "Radisson",
      "Ragueneau",
      "Rapid Lake",
      "Rapide-danseur",
      "Rapides-des-joachims",
      "Ravignan",
      "Rawdon",
      "Remigny",
      "Repentigny",
      "Richelieu",
      "Richmond",
      "Rigaud",
      "Rimouski",
      "Ripon",
      "Riviere-a-claude",
      "Riviere-a-pierre",
      "Riviere-au-tonnerre",
      "Riviere-beaudette",
      "Riviere-bleue",
      "Riviere-du-loup",
      "Riviere-eternite",
      "Riviere-heva",
      "Riviere-madeleine",
      "Riviere-ouelle",
      "Riviere-paspebiac",
      "Riviere-pentecote",
      "Riviere-rouge",
      "Riviere-saint-jean",
      "Riviere-saint-paul",
      "Riviere-trois-pistoles",
      "Roberval",
      "Rochebaucourt",
      "Rollet",
      "Roquemaure",
      "Rosemere",
      "Rougemont",
      "Routhierville",
      "Rouyn-noranda",
      "Roxboro",
      "Roxton Falls",
      "Roxton Pond",
      "Ruisseau-a-rebours",
      "Sabrevois",
      "Sacre-coeur-saguenay",
      "Saint-adalbert",
      "Saint-adelme",
      "Saint-adelphe-de-champlain",
      "Saint-adolphe-d'howard",
      "Saint-adrien",
      "Saint-adrien-d'irlande",
      "Saint-agapit",
      "Saint-aime",
      "Saint-aime-des-lacs",
      "Saint-alban",
      "Saint-albert",
      "Saint-alexandre-d'iberville",
      "Saint-alexandre-de-kamouraska",
      "Saint-alexandre-des-lacs",
      "Saint-alexis-de-matapedia",
      "Saint-alexis-de-montcalm",
      "Saint-alexis-des-monts",
      "Saint-alfred",
      "Saint-alphonse-de-caplan",
      "Saint-alphonse-de-granby",
      "Saint-alphonse-rodriguez",
      "Saint-amable",
      "Saint-ambroise",
      "Saint-ambroise-de-kildare",
      "Saint-anaclet",
      "Saint-andre-avellin",
      "Saint-andre-d'argenteuil",
      "Saint-andre-de-kamouraska",
      "Saint-andre-de-restigouche",
      "Saint-andre-du-lac-saint-jean",
      "Saint-anicet",
      "Saint-anselme",
      "Saint-antoine-abbe",
      "Saint-antoine-de-tilly",
      "Saint-antoine-sur-richelieu",
      "Saint-antonin",
      "Saint-apollinaire",
      "Saint-armand",
      "Saint-arsene",
      "Saint-aubert",
      "Saint-augustin-de-desmaures",
      "Saint-augustin-saguenay",
      "Saint-barnabe-sud",
      "Saint-barthelemy",
      "Saint-basile",
      "Saint-basile-le-grand",
      "Saint-benjamin",
      "Saint-benoit-du-lac",
      "Saint-benoit-labre",
      "Saint-bernard",
      "Saint-bernard-de-lacolle",
      "Saint-bernard-de-michaudville",
      "Saint-bernard-sur-mer",
      "Saint-blaise-sur-richelieu",
      "Saint-bonaventure",
      "Saint-boniface-de-shawinigan",
      "Saint-bruno",
      "Saint-bruno-de-guigues",
      "Saint-bruno-de-kamouraska",
      "Saint-bruno-lac-saint-jean",
      "Saint-calixte",
      "Saint-camille",
      "Saint-camille-de-bellechasse",
      "Saint-casimir",
      "Saint-celestin",
      "Saint-cesaire",
      "Saint-charles-borromee",
      "Saint-charles-de-bellechasse",
      "Saint-charles-de-bourget",
      "Saint-charles-de-drummond",
      "Saint-charles-garnier",
      "Saint-charles-sur-richelieu",
      "Saint-christophe-d'arthabaska",
      "Saint-chrysostome",
      "Saint-claude",
      "Saint-clement",
      "Saint-cleophas",
      "Saint-cleophas-de-brandon",
      "Saint-clet",
      "Saint-colomban",
      "Saint-come",
      "Saint-come-liniere",
      "Saint-constant",
      "Saint-cuthbert",
      "Saint-cyprien",
      "Saint-cyprien-des-etchemins",
      "Saint-cyrille-de-l'islet",
      "Saint-cyrille-de-wendover",
      "Saint-damase",
      "Saint-damase-de-matapedia",
      "Saint-damase-des-aulnaies",
      "Saint-damien",
      "Saint-damien-de-buckland",
      "Saint-david",
      "Saint-denis-de-brompton",
      "Saint-denis-de-la-bouteillerie",
      "Saint-denis-sur-richelieu",
      "Saint-didace",
      "Saint-dominique",
      "Saint-dominique-du-rosaire",
      "Saint-donat",
      "Saint-donat-de-montcalm",
      "Saint-edmond-de-grantham",
      "Saint-edmond-les-plaines",
      "Saint-edouard-de-lotbiniere",
      "Saint-edouard-de-maskinonge",
      "Saint-edouard-de-napierville",
      "Saint-elie-de-caxton",
      "Saint-eloi",
      "Saint-elphege",
      "Saint-elzear",
      "Saint-elzear-de-bonaventure",
      "Saint-elzear-de-temiscouata",
      "Saint-emile-de-suffolk",
      "Saint-ephrem-de-beauce",
      "Saint-epiphane",
      "Saint-esprit",
      "Saint-etienne-de-beauharnois",
      "Saint-etienne-de-bolton",
      "Saint-etienne-de-lauzon",
      "Saint-etienne-des-gres",
      "Saint-eugene-d'argentenay",
      "Saint-eugene-de-grantham",
      "Saint-eugene-de-guigues",
      "Saint-eusebe",
      "Saint-eustache",
      "Saint-evariste-de-forsyth",
      "Saint-fabien",
      "Saint-fabien-de-panet",
      "Saint-faustin-lac-carre",
      "Saint-felicien",
      "Saint-felix-d'otis",
      "Saint-felix-de-dalquier",
      "Saint-felix-de-kingsey",
      "Saint-felix-de-valois",
      "Saint-ferdinand",
      "Saint-ferreol-les-neiges",
      "Saint-flavien",
      "Saint-fortunat",
      "Saint-francois-d'assise",
      "Saint-francois-d'orleans",
      "Saint-francois-de-sales",
      "Saint-francois-du-lac",
      "Saint-francois-xavier-de-viger",
      "Saint-frederic",
      "Saint-fulgence",
      "Saint-gabriel-de-brandon",
      "Saint-gabriel-de-rimouski",
      "Saint-gabriel-de-valcartier",
      "Saint-gedeon",
      "Saint-gedeon-de-beauce",
      "Saint-georges",
      "Saint-georges-de-champlain",
      "Saint-georges-de-malbaie",
      "Saint-georges-de-windsor",
      "Saint-gerard-des-laurentides",
      "Saint-germain",
      "Saint-germain-de-grantham",
      "Saint-gervais",
      "Saint-gilbert",
      "Saint-gilles",
      "Saint-godefroi",
      "Saint-guillaume",
      "Saint-guy",
      "Saint-henri-de-levis",
      "Saint-hermenegilde",
      "Saint-hilarion",
      "Saint-hippolyte",
      "Saint-honore-de-chicoutimi",
      "Saint-honore-de-shenley",
      "Saint-honore-de-temiscouata",
      "Saint-hubert",
      "Saint-hubert-riviere-du-loup",
      "Saint-hugues",
      "Saint-hyacinthe",
      "Saint-ignace-de-loyola",
      "Saint-ignace-de-stanbridge",
      "Saint-irenee",
      "Saint-isidore",
      "Saint-isidore-de-clifton",
      "Saint-isidore-de-laprairie",
      "Saint-jacques",
      "Saint-jacques-de-leeds",
      "Saint-jacques-le-mineur",
      "Saint-jean-baptiste",
      "Saint-jean-chrysostome",
      "Saint-jean-d'orleans",
      "Saint-jean-de-brebeuf",
      "Saint-jean-de-cherbourg",
      "Saint-jean-de-dieu",
      "Saint-jean-de-la-lande",
      "Saint-jean-de-matapedia",
      "Saint-jean-de-matha",
      "Saint-jean-des-piles",
      "Saint-jean-port-joli",
      "Saint-jean-sur-richelieu",
      "Saint-jerome",
      "Saint-joachim-de-courval",
      "Saint-joachim-de-shefford",
      "Saint-jogues",
      "Saint-joseph-de-beauce",
      "Saint-joseph-de-coleraine",
      "Saint-joseph-de-ham-sud",
      "Saint-joseph-de-kamouraska",
      "Saint-joseph-de-la-rive",
      "Saint-joseph-de-lepage",
      "Saint-joseph-de-sorel",
      "Saint-joseph-du-lac",
      "Saint-jude",
      "Saint-jules",
      "Saint-julien",
      "Saint-just-de-bretenieres",
      "Saint-juste-du-lac",
      "Saint-justin",
      "Saint-lambert",
      "Saint-lambert-de-lauzon",
      "Saint-laurent",
      "Saint-laurent-ile-d'orleans",
      "Saint-lazare",
      "Saint-lazare-de-bellechasse",
      "Saint-leandre",
      "Saint-leon",
      "Saint-leon-le-grand",
      "Saint-leonard",
      "Saint-leonard-d'aston",
      "Saint-leonard-de-portneuf",
      "Saint-liboire",
      "Saint-liguori",
      "Saint-lin-laurentides",
      "Saint-louis",
      "Saint-louis-de-blandford",
      "Saint-louis-de-gonzague",
      "Saint-louis-du-ha-ha",
      "Saint-luc-de-bellechasse",
      "Saint-luc-de-vincennes",
      "Saint-lucien",
      "Saint-ludger",
      "Saint-ludger-de-milot",
      "Saint-magloire",
      "Saint-majorique",
      "Saint-malachie",
      "Saint-malo",
      "Saint-marc-de-figuery",
      "Saint-marc-des-carrieres",
      "Saint-marc-du-lac-long",
      "Saint-marc-sur-richelieu",
      "Saint-marcel-de-richelieu",
      "Saint-marcellin",
      "Saint-martin",
      "Saint-mathias-sur-richelieu",
      "Saint-mathieu-d'harricana",
      "Saint-mathieu-de-beloeil",
      "Saint-mathieu-de-laprairie",
      "Saint-mathieu-de-rioux",
      "Saint-mathieu-du-parc",
      "Saint-maurice",
      "Saint-medard",
      "Saint-michel",
      "Saint-michel-de-bellechasse",
      "Saint-michel-des-saints",
      "Saint-modeste",
      "Saint-moise",
      "Saint-narcisse",
      "Saint-narcisse-de-beaurivage",
      "Saint-narcisse-de-rimouski",
      "Saint-nazaire-d'acton",
      "Saint-nazaire-de-dorchester",
      "Saint-neree",
      "Saint-nicephore",
      "Saint-nicolas",
      "Saint-noel",
      "Saint-norbert",
      "Saint-norbert-d'arthabaska",
      "Saint-odilon",
      "Saint-omer",
      "Saint-omer-l'islet",
      "Saint-ours",
      "Saint-pacome",
      "Saint-pamphile",
      "Saint-pascal",
      "Saint-patrice-de-beaurivage",
      "Saint-paul",
      "Saint-paul-d'abbotsford",
      "Saint-paul-de-la-croix",
      "Saint-paul-de-montminy",
      "Saint-paulin",
      "Saint-philemon",
      "Saint-philibert",
      "Saint-philippe",
      "Saint-philippe-de-neri",
      "Saint-pie",
      "Saint-pie-de-guire",
      "Saint-pierre-baptiste",
      "Saint-pierre-de-broughton",
      "Saint-pierre-de-lamy",
      "Saint-pierre-ile-d'orleans",
      "Saint-pierre-les-becquets",
      "Saint-placide",
      "Saint-polycarpe",
      "Saint-prime",
      "Saint-prosper",
      "Saint-prosper-de-dorchester",
      "Saint-raphael",
      "Saint-raymond",
      "Saint-redempteur",
      "Saint-remi",
      "Saint-remi-d'amherst",
      "Saint-remi-de-tingwick",
      "Saint-rene",
      "Saint-rene-de-matane",
      "Saint-robert",
      "Saint-robert-bellarmin",
      "Saint-roch-de-l'achigan",
      "Saint-roch-de-mekinac",
      "Saint-roch-de-richelieu",
      "Saint-roch-des-aulnaies",
      "Saint-roch-ouest",
      "Saint-romain",
      "Saint-romuald",
      "Saint-rosaire",
      "Saint-samuel",
      "Saint-sauveur",
      "Saint-sebastien",
      "Saint-sebastien-de-frontenac",
      "Saint-severe",
      "Saint-severin-de-beauce",
      "Saint-simeon",
      "Saint-simeon-de-bonaventure",
      "Saint-simon-de-bagot",
      "Saint-simon-de-rimouski",
      "Saint-simon-les-mines",
      "Saint-stanislas",
      "Saint-stanislas-de-champlain",
      "Saint-stanislas-de-kostka",
      "Saint-sulpice",
      "Saint-sylvere",
      "Saint-sylvestre",
      "Saint-telesphore",
      "Saint-tharcisius",
      "Saint-theodore-d'acton",
      "Saint-theophile",
      "Saint-thomas",
      "Saint-thomas-didyme",
      "Saint-thuribe",
      "Saint-tite",
      "Saint-tite-des-caps",
      "Saint-ubalde",
      "Saint-ulric",
      "Saint-urbain-de-charlevoix",
      "Saint-urbain-premier",
      "Saint-valentin",
      "Saint-valere",
      "Saint-valerien-de-rimouski",
      "Saint-vallier",
      "Saint-vianney",
      "Saint-victor",
      "Saint-vital-de-clermont",
      "Saint-wenceslas",
      "Saint-zacharie",
      "Saint-zenon",
      "Saint-zephirin-de-courval",
      "Saint-zotique",
      "Sainte-adele",
      "Sainte-agathe-de-lotbiniere",
      "Sainte-agathe-des-monts",
      "Sainte-agathe-nord",
      "Sainte-agnes-de-dundee",
      "Sainte-angele-de-merici",
      "Sainte-angele-de-monnoir",
      "Sainte-angele-de-premont",
      "Sainte-anne-de-beaupre",
      "Sainte-anne-de-bellevue",
      "Sainte-anne-de-la-perade",
      "Sainte-anne-de-la-rochelle",
      "Sainte-anne-de-sorel",
      "Sainte-anne-des-lacs",
      "Sainte-anne-des-monts",
      "Sainte-anne-des-plaines",
      "Sainte-anne-du-lac",
      "Sainte-anne-du-sault",
      "Sainte-apolline-de-patton",
      "Sainte-aurelie",
      "Sainte-barbe",
      "Sainte-beatrix",
      "Sainte-brigide-d'iberville",
      "Sainte-brigitte-de-laval",
      "Sainte-brigitte-des-saults",
      "Sainte-catherine",
      "Sainte-catherine-de-hatley",
      "Sainte-cecile-de-levrard",
      "Sainte-cecile-de-masham",
      "Sainte-cecile-de-milton",
      "Sainte-cecile-de-whitton",
      "Sainte-christine",
      "Sainte-christine-d'auvergne",
      "Sainte-claire",
      "Sainte-clotilde-de-beauce",
      "Sainte-clotilde-de-chateauguay",
      "Sainte-clotilde-de-horton",
      "Sainte-croix",
      "Sainte-dorothee",
      "Sainte-edwidge",
      "Sainte-elisabeth",
      "Sainte-elisabeth-de-proulx",
      "Sainte-elizabeth-de-warwick",
      "Sainte-emelie-de-l'energie",
      "Sainte-eulalie",
      "Sainte-euphemie",
      "Sainte-famille",
      "Sainte-felicite",
      "Sainte-felicite-de-l'islet",
      "Sainte-flavie",
      "Sainte-florence",
      "Sainte-francoise",
      "Sainte-francoise-de-lotbiniere",
      "Sainte-genevieve",
      "Sainte-genevieve-de-batiscan",
      "Sainte-germaine-boule",
      "Sainte-gertrude-manneville",
      "Sainte-hedwidge-de-roberval",
      "Sainte-helene-de-bagot",
      "Sainte-helene-de-breakeyville",
      "Sainte-helene-de-chester",
      "Sainte-helene-de-kamouraska",
      "Sainte-henedine",
      "Sainte-julie",
      "Sainte-julienne",
      "Sainte-justine",
      "Sainte-justine-de-newton",
      "Sainte-louise",
      "Sainte-luce",
      "Sainte-lucie-de-beauregard",
      "Sainte-lucie-des-laurentides",
      "Sainte-madeleine",
      "Sainte-marcelline-de-kildare",
      "Sainte-marguerite-marie",
      "Sainte-marie",
      "Sainte-marie-de-blandford",
      "Sainte-marie-salome",
      "Sainte-marthe",
      "Sainte-marthe-sur-le-lac",
      "Sainte-martine",
      "Sainte-melanie",
      "Sainte-monique",
      "Sainte-monique-lac-saint-jean",
      "Sainte-paule",
      "Sainte-perpetue",
      "Sainte-perpetue-de-l'islet",
      "Sainte-petronille",
      "Sainte-rita",
      "Sainte-rose",
      "Sainte-rose-de-watford",
      "Sainte-rose-du-nord",
      "Sainte-sabine",
      "Sainte-sabine-de-bellechasse",
      "Sainte-seraphine",
      "Sainte-sophie",
      "Sainte-sophie-d'halifax",
      "Sainte-sophie-de-levrard",
      "Sainte-thecle",
      "Sainte-therese",
      "Sainte-therese-de-gaspe",
      "Sainte-therese-de-la-gatineau",
      "Sainte-ursule",
      "Sainte-victoire-de-sorel",
      "Saints-anges",
      "Saints-martyrs-canadiens",
      "Salaberry-de-valleyfield",
      "Salluit",
      "Sawyerville",
      "Sayabec",
      "Schefferville",
      "Scotstown",
      "Scott",
      "Senneterre",
      "Senneville",
      "Sept-iles",
      "Shannon",
      "Shawinigan",
      "Shawinigan-sud",
      "Shawville",
      "Sheenboro",
      "Shefford",
      "Sheldrake",
      "Sherbrooke",
      "Sherrington",
      "Shigawake",
      "Shipshaw",
      "Sorel-tracy",
      "Squatec",
      "St-athanase",
      "St-barnabe-nord",
      "St-francois-de-la-riviere-du-s",
      "St-francois-xavier-de-brompton",
      "St-gabriel-de-kamouraska",
      "St-joachim-de-montmorency",
      "St-joseph-de-la-pointe-de-levy",
      "St-marcel-de-l'islet",
      "St-nazaire-du-lac-st-jean",
      "St-octave",
      "St-onesime",
      "St-pierre-de-la-riviere-du-sud",
      "St-valerien",
      "Stanbridge East",
      "Stanbridge Station",
      "Standon",
      "Stanstead",
      "Ste-catherine-de-la-j-cartier",
      "Ste-irene-de-matapedia",
      "Ste-jeanne-d'arc-de-matane",
      "Ste-marguerite-de-dorchester",
      "Ste-marguerite-du-lac-masson",
      "Stoke",
      "Stoneham-et-tewkesbury",
      "Stornoway",
      "Stratford",
      "Stukely-sud",
      "Sutton",
      "Tadoussac",
      "Taillon",
      "Taschereau",
      "Tasiujaq",
      "Temiscaming",
      "Terrasse-vaudreuil",
      "Terrebonne",
      "Tete-a-la-baleine",
      "Thetford Mines",
      "Thurso",
      "Tingwick",
      "Tourville",
      "Trecesson",
      "Tres-saint-redempteur",
      "Tring-jonction",
      "Trois-pistoles",
      "Trois-rives",
      "Trois-rivieres",
      "Ulverton",
      "Umiujaq",
      "Upton",
      "Val-alain",
      "Val-brillant",
      "Val-d'espoir",
      "Val-d'or",
      "Val-david",
      "Val-des-bois",
      "Val-des-lacs",
      "Val-des-monts",
      "Val-joli",
      "Val-morin",
      "Val-paradis",
      "Val-racine",
      "Val-saint-gilles",
      "Valcourt",
      "Vallee-jonction",
      "Varennes",
      "Vaudreuil-dorion",
      "Vaudreuil-sur-le-lac",
      "Venise-en-quebec",
      "Venosta",
      "Vercheres",
      "Verdun",
      "Victoriaville",
      "Village Saint-pierre",
      "Ville-marie",
      "Villebois",
      "Villeroy",
      "Vimont",
      "Wakefield",
      "Waltham",
      "Warden",
      "Warwick",
      "Waskaganish",
      "Waswanipi",
      "Waterloo",
      "Waterville",
      "Weedon",
      "Wemindji",
      "Wemotaci",
      "Wendake",
      "Wentworth",
      "Wentworth-nord",
      "West Brome",
      "Westbury",
      "Westmount",
      "Wickham",
      "Windsor",
      "Woburn",
      "Wolinak",
      "Wotton",
      "Yamachiche",
      "Yamaska",
      "Yamaska-est"
    ]
  },
  {
    "name": "New Brunswick",
    "key": "NB",
    "cities": [
      "Acadie Siding",
      "Acadieville",
      "Adams Gulch",
      "Adamsville",
      "Albert Mines",
      "Albrights Corner",
      "Alcida",
      "Alderwood",
      "Aldouane",
      "Allainville",
      "Allardville",
      "Allison",
      "Alma",
      "Ammon",
      "Anagance",
      "Anderson Road",
      "Anderson Settlement",
      "Andersonville",
      "Anfield",
      "Anse-bleue",
      "Apohaqui",
      "Arbeau Settlement",
      "Armond",
      "Aroostook Junction",
      "Aroostook",
      "Arthurette",
      "Ashland",
      "Astle",
      "Atholville",
      "Aulac",
      "Avondale",
      "Back Bay",
      "Baie De Bouctouche",
      "Baie De Petit-pokemouche",
      "Baie Verte",
      "Baie-sainte-anne",
      "Baillie",
      "Bains Corner",
      "Bairdsville",
      "Baker Brook",
      "Balla Philip",
      "Balmoral Est",
      "Balmoral Sud",
      "Balmoral",
      "Baltimore",
      "Bannon",
      "Barnaby",
      "Barnesville",
      "Barnettville",
      "Barony",
      "Barryville",
      "Barter Settlement",
      "Bartholomew",
      "Bartibog Bridge",
      "Bartibog",
      "Bartletts Mills",
      "Bas-cap-pele",
      "Bas-caraquet",
      "Bas-paquetville",
      "Bass River",
      "Basswood Ridge",
      "Bath",
      "Bathurst",
      "Baxters Corner",
      "Bay Du Vin",
      "Bay View",
      "Bayfield",
      "Bayside",
      "Bayswater",
      "Beaconsfield",
      "Bear Island",
      "Beardsley",
      "Beaver Dam",
      "Beaver Harbour",
      "Beaverbrook Albert Co",
      "Beaverbrook",
      "Beckim Settlement",
      "Bedell",
      "Beechwood",
      "Beersville",
      "Belledune",
      "Bellefond",
      "Belleisle Creek",
      "Belleville",
      "Benjamin River",
      "Benoit",
      "Benton",
      "Beresford",
      "Berry Mills",
      "Berryton",
      "Bertrand",
      "Berwick",
      "Bethel",
      "Bettsburg",
      "Big Cove Queens Co",
      "Big Hole",
      "Big River",
      "Birch Ridge",
      "Birdton",
      "Black Point",
      "Black River Bridge",
      "Black River",
      "Black Rock",
      "Blackland Restigouche Co",
      "Blacks Harbour",
      "Blackville",
      "Blair Athol",
      "Blissfield",
      "Bloomfield Carleton Co",
      "Bloomfield Kings Co",
      "Bloomfield Ridge",
      "Blue Bell",
      "Blue Mountain Bend",
      "Bocabec",
      "Boiestown",
      "Bois-blanc",
      "Bois-gagnon",
      "Bon Accord",
      "Bonny River",
      "Boom Road",
      "Bouctouche Cove",
      "Bouctouche Reserve",
      "Bouctouche Sud",
      "Bouctouche",
      "Boudreau-ouest",
      "Boundary Creek",
      "Brantville",
      "Breadalbane",
      "Brewers Mill",
      "Briggs Corner Queens Co",
      "Bristol Junction",
      "British Settlement",
      "Brockway",
      "Bronson Settlement",
      "Brookville",
      "Browns Flat",
      "Browns Yard",
      "Brunswick Mines",
      "Bryenton",
      "Bubartown",
      "Bull Lake",
      "Bulls Creek",
      "Burnsville",
      "Burnt Church First Nation",
      "Burnt Church",
      "Burnt Hill",
      "Burntland Brook",
      "Burpee",
      "Burton",
      "Burtts Corner",
      "Cails Mills",
      "Cains River",
      "Caissie Road",
      "Caithness",
      "Calders Head",
      "Caledonia Mountain",
      "Calhoun",
      "California Settlement",
      "Cambridge-narrows",
      "Campbell Settlement York Co",
      "Campbell Settlement",
      "Campbellton",
      "Canaan Forks",
      "Canaan Station",
      "Canal",
      "Canisto",
      "Canobie",
      "Canoose",
      "Canterbury",
      "Canton Des Basques",
      "Cap-bateau",
      "Cap-pele",
      "Cape Enrage",
      "Cape Spear",
      "Cape Station",
      "Cape Tormentine",
      "Caraquet",
      "Cardigan",
      "Carlingford",
      "Carlisle",
      "Carlow",
      "Carrol Ridge",
      "Carrolls Crossing",
      "Carsonville",
      "Carters Point",
      "Cassidy Lake",
      "Cassilis",
      "Cedar Camp",
      "Central Blissville",
      "Central Greenwich",
      "Central Hainesville",
      "Central Hampstead",
      "Central Waterville",
      "Centre Village",
      "Centreville",
      "Chamberlain Settlement",
      "Chambers Settlement",
      "Chamcook",
      "Chance Harbour",
      "Chaplin Island Road",
      "Charleston",
      "Charlie Lake",
      "Charlo South",
      "Charlo",
      "Charters Settlement",
      "Chelmsford",
      "Cherry Burton",
      "Chiasson Office",
      "Childs Creek",
      "Chipman",
      "Chocolate Cove",
      "Clair",
      "Clairville",
      "Clarendon",
      "Clarks Corner",
      "Clarkville",
      "Clearview",
      "Clifton Royal",
      "Clifton",
      "Clover Hill",
      "Clover Valley",
      "Cloverdale",
      "Coal Branch",
      "Coal Creek",
      "Coburg",
      "Cocagne",
      "Codys",
      "Coldstream",
      "Coles Island Queens Co",
      "Collette",
      "Collina",
      "Colpitts Settlement",
      "Connell",
      "Cookville",
      "Cormier-village",
      "Cornhill",
      "Coteau Road",
      "Craig Flats",
      "Crocker Hill",
      "Crombie Settlement",
      "Cross Creek",
      "Cumberland Bay",
      "Cummings Cove",
      "Currie Siding",
      "Currieburg",
      "Curryville",
      "Curventon",
      "Dalhousie Junction",
      "Dalhousie",
      "Damascus",
      "Darlings Island",
      "Dauversiere",
      "Dawson Settlement",
      "Dawsonville",
      "Dead Creek",
      "Debec",
      "Deersdale",
      "Deerville",
      "Dennis Beach",
      "Derby Junction",
      "Derby",
      "Dewolfe",
      "Dieppe",
      "Digdeguash",
      "Dipper Harbour",
      "Divide",
      "Doaktown",
      "Dobson Corner",
      "Donegal",
      "Dorchester Cape",
      "Dorchester",
      "Dorrington Hill",
      "Douglas Harbour",
      "Douglas",
      "Dow Settlement",
      "Doyles Brook",
      "Drummond",
      "Drurys Cove",
      "Dsl De Drummond",
      "Dsl De Grand-sault/falls",
      "Dubee Settlement",
      "Dufferin Charlotte Co",
      "Dufferin Queens Co",
      "Dugas",
      "Duguayville",
      "Dumfries",
      "Dundas",
      "Dundee",
      "Dunlop",
      "Dunsinane",
      "Durham Bridge",
      "Dutch Valley",
      "East Branch",
      "East Brighton",
      "East Centreville",
      "East Coldstream",
      "East Newbridge",
      "Edgetts Landing",
      "Edmundston",
      "Eel Ground",
      "Eel River Bar First Nation",
      "Eel River Cove",
      "Eel River Crossing",
      "Eel River Lake",
      "Elgin",
      "Elm Hill",
      "Elmsville",
      "Elmwood",
      "Elsipogtog First Nation",
      "English Settlement",
      "Enterprise",
      "Erb Settlement",
      "Erbs Cove",
      "Escuminac",
      "Estey's Bridge",
      "Evandale",
      "Evangeline",
      "Evans Road",
      "Everett",
      "Exmoor",
      "Fairfield Westmorland Co",
      "Fairfield",
      "Fairhaven",
      "Fairisle",
      "Fawcett Hill",
      "Fielding",
      "Flatlands",
      "Flemington",
      "Florenceville-bristol",
      "Flowers Cove",
      "Flume Ridge",
      "Ford Bank",
      "Fords Mills",
      "Forest City",
      "Forest Glen",
      "Fosterville",
      "Four Corners",
      "Four Falls",
      "Four Roads",
      "Fredericksburg",
      "Fredericton Junction",
      "Fredericton",
      "Free Grant",
      "French Lake",
      "French Village Kings Co",
      "French Village-york",
      "Frosty Hollow",
      "Fundy National Park",
      "Gagetown",
      "Gallagher Ridge",
      "Galloway",
      "Gardiner Point",
      "Gardner Creek",
      "Garnett Settlement",
      "Gaspereau Forks",
      "Gauvreau",
      "Geary",
      "Germantown",
      "Giants Glen",
      "Gladeside",
      "Gladwyn",
      "Glassville",
      "Glencoe",
      "Glenlevit",
      "Glenvale",
      "Glenwood Kings Co",
      "Glenwood",
      "Glenwood.",
      "Gloucester Junction",
      "Good Corner",
      "Goodwin Mill",
      "Gordonsville",
      "Grafton",
      "Grand Bay-westfield",
      "Grand Falls",
      "Grand Lake Road",
      "Grand Manan",
      "Grand-barachois",
      "Grand-sault/grand Falls",
      "Grande-anse",
      "Grande-digue",
      "Gravel Hill",
      "Gray Rapids",
      "Greater Lakeburn",
      "Green Hill",
      "Green Mountain",
      "Green Road",
      "Greenfield",
      "Greenhill Lake",
      "Gregg Settlement",
      "Grove Hill",
      "Hacheyville",
      "Halcomb",
      "Hammondvale",
      "Hampstead",
      "Hampton",
      "Hamtown Corner",
      "Haneytown",
      "Hanford Brook",
      "Hanwell",
      "Harcourt",
      "Hardwicke",
      "Hardwood Ridge",
      "Harewood",
      "Hartfield",
      "Hartford",
      "Hartin Settlement",
      "Hartland",
      "Hartley Settlement",
      "Harvey Albert Co",
      "Harvey Station",
      "Harvey York Co",
      "Hatfield Point",
      "Haut-lameque",
      "Haut-paquetville",
      "Haut-riviere-du-portage",
      "Haut-saint-antoine",
      "Haut-sheila",
      "Haut-shippagan",
      "Haute-aboujagane",
      "Havelock",
      "Hawkins Corner",
      "Hawkshaw",
      "Hay Settlement",
      "Hayesville",
      "Hayman Hill",
      "Hazeldean",
      "Hazelton",
      "Head Of Millstream",
      "Heathland",
      "Hebert",
      "Hebron",
      "Henderson Settlement",
      "Hersonville",
      "Hibernia Cove",
      "Hicksville",
      "Highfield",
      "Hillandale",
      "Hillgrove",
      "Hillsborough West",
      "Hillsborough",
      "Hillsdale",
      "Hilltop",
      "Holmesville",
      "Holtville",
      "Honeydale",
      "Hopewell Cape",
      "Hopewell Hill",
      "Howard Brook",
      "Howard",
      "Howland Ridge",
      "Hoyt",
      "Hunters Home",
      "Immigrant Road",
      "Indian Island",
      "Indian Mountain",
      "Inkerman Ferry",
      "Inkerman",
      "Intervale",
      "Irish Settlement",
      "Irishtown",
      "Iron Bound Cove",
      "Island View",
      "Jackson Falls",
      "Jacksontown",
      "Jacksonville",
      "Janeville",
      "Jardineville",
      "Jeffries Corner",
      "Jemseg",
      "Jewetts Mills",
      "Johnson Settlement Charlotte C",
      "Johnson Settlement York Co",
      "Johnson's Mills",
      "Johnston Point",
      "Johnville",
      "Jolicure",
      "Jordan Mountain",
      "Juniper",
      "Kars",
      "Kedgwick Nord",
      "Kedgwick Ouest",
      "Kedgwick River",
      "Kedgwick Sud",
      "Kedgwick",
      "Keenans",
      "Kent Junction",
      "Keswick Ridge",
      "Keswick",
      "Kierstead Mountain",
      "Kiersteadville",
      "Kilburn",
      "Killams Mills",
      "Killarney Road",
      "Killoween",
      "Kincardine",
      "Kings Landing Historical Settl",
      "Kingsclear First Nation",
      "Kingsley",
      "Kingston",
      "Kinnear Settlement",
      "Kirkland",
      "Knightville",
      "Knowlesville",
      "Kouchibouguac National Park",
      "Kouchibouguac",
      "L'etete",
      "Lac Baker",
      "Lagaceville",
      "Lake Edward",
      "Lake George",
      "Lakeside",
      "Laketon",
      "Lakeville Carleton Co",
      "Lakeville Corner",
      "Lakeville-westmorland",
      "Lambert's Cove",
      "Lambertville",
      "Lameque",
      "Landry Office",
      "Lansdowne",
      "Laplante",
      "Lavillette",
      "Lawrence Station",
      "Le Goulet",
      "Lee Settlement",
      "Leech",
      "Leonard Colony",
      "Leonardville",
      "Lepreau",
      "Letang",
      "Leverville",
      "Lewis Mountain",
      "Limekiln",
      "Limestone",
      "Lincoln",
      "Lindsay",
      "Linton Corner",
      "Little Bartibog",
      "Little Lepreau",
      "Little Ridge",
      "Little River Albert Co",
      "Little River Gloucester Co",
      "Little River Hill",
      "Little Salmon River West",
      "Little Shemogue",
      "Lockstead",
      "Londonderry",
      "Long Creek",
      "Long Point",
      "Long Reach",
      "Long Settlement Kings Co",
      "Long Settlement",
      "Lord's Cove",
      "Lorne",
      "Losier Settlement",
      "Lower Brighton",
      "Lower Cambridge",
      "Lower Cape",
      "Lower Cove",
      "Lower Coverdale",
      "Lower Derby",
      "Lower Greenwich",
      "Lower Hainesville",
      "Lower Kingsclear",
      "Lower Kintore",
      "Lower Knoxford",
      "Lower Millstream",
      "Lower Newcastle",
      "Lower Norton",
      "Lower Queensbury",
      "Lower St Marys",
      "Lower Woodstock",
      "Ludlow",
      "Lugar",
      "Lutes Mountain",
      "Lynnfield",
      "Lyttleton",
      "Macdougall Settlement",
      "Maces Bay",
      "Maclaggan Bridge",
      "Mactaquac",
      "Madawaska Maliseet Frst Nation",
      "Madran",
      "Main River",
      "Mainstream",
      "Maisonnette",
      "Malden",
      "Maltempec",
      "Mann's Mountain",
      "Mannhurst",
      "Maple Glen",
      "Maple Grove",
      "Maple Ridge",
      "Maple View",
      "Mapledale",
      "Maplehurst",
      "Maplewood",
      "Maquapit Lake",
      "Markhamville",
      "Marne",
      "Marrtown",
      "Mascarene",
      "Mates Corner",
      "Matthews Settlement",
      "Maugerville",
      "Maxwell",
      "Mayfield",
      "Mazerolle Settlement",
      "Mcadam",
      "Mcgivney",
      "Mcintosh Hill",
      "Mckees Mills",
      "Mckenna",
      "Mckenzie Corner",
      "Mckinleyville",
      "Mclaughlin",
      "Mcleod Hill",
      "Mcleods",
      "Mcnamee",
      "Mcquade",
      "Meadow Brook",
      "Mechanic Settlement",
      "Medford",
      "Meductic",
      "Melrose",
      "Memramcook East",
      "Memramcook",
      "Menneval",
      "Middle Hainesville",
      "Middle River",
      "Middle Sackville",
      "Middleton",
      "Midgic",
      "Midland Kings Co",
      "Midland Queens Co",
      "Midway",
      "Mill Brook",
      "Mill Cove",
      "Millerton",
      "Millville",
      "Ministers Island",
      "Minto",
      "Miramichi Bay",
      "Miramichi Road",
      "Miramichi",
      "Miscou",
      "Mispec",
      "Mohannes",
      "Moncton",
      "Monquart",
      "Monteagle",
      "Monument",
      "Moores Mills",
      "Moose Mountain",
      "Moosehorn Creek",
      "Morrell Siding",
      "Morrisdale",
      "Mount Delight",
      "Mount Hebron",
      "Mount Hope",
      "Mount Pisgah",
      "Mount Pleasant",
      "Mt Middleton",
      "Mundleville",
      "Muniac",
      "Murray Corner",
      "Murray Settlement",
      "Musquash",
      "Nackawic",
      "Napadogan",
      "Napan",
      "Nash Creek",
      "Nashwaak Bridge",
      "Nashwaak Village",
      "Nasonworth",
      "Nauwigewauk",
      "Neguac",
      "Nelson Hollow",
      "Nepisiguit Falls",
      "Nerepis",
      "New Avon",
      "New Bandon Gloucester Co",
      "New Bandon Northumb Co",
      "New Canaan",
      "New Denmark",
      "New England Settlement",
      "New Horton",
      "New Jersey",
      "New Line",
      "New Market",
      "New Maryland",
      "New Mills",
      "New River Beach",
      "New Scotland",
      "New Zion",
      "Newbridge",
      "Newburg",
      "Newcastle Centre",
      "Newcastle Creek",
      "Newtown",
      "Nicholas Denys",
      "Nictau",
      "Nigadoo",
      "Noinville",
      "Noonan",
      "North Forks",
      "North Lake",
      "North Shannonvale",
      "North Tay",
      "North Tetagouche",
      "North View",
      "Northampton",
      "Northern Harbour",
      "Norton",
      "Nortondale",
      "Notre Dame De Lourdes",
      "Notre-dame",
      "Notre-dame-des-erables",
      "Oak Bay",
      "Oak Haven",
      "Oak Hill",
      "Oak Mountain",
      "Oak Point Kings Co",
      "Oak Point",
      "Oakland",
      "Oakville",
      "Odell",
      "Old Avon",
      "Old Ridge",
      "Orange Hill",
      "Oromocto",
      "Osborne Corner",
      "Otter Creek",
      "Oxbow",
      "Pabineau Falls",
      "Pabineau First Nation",
      "Paquetville",
      "Parker Ridge",
      "Parker Road",
      "Parkindale",
      "Parlee Brook",
      "Parleeville",
      "Passekeag",
      "Pearsonville",
      "Peel",
      "Pelerin",
      "Peltoma Settlement",
      "Pemberton Ridge",
      "Pembroke",
      "Pennfield",
      "Penniac",
      "Penobsquis",
      "Perry Settlement",
      "Perth-andover",
      "Petit Tracadie",
      "Petit-cap",
      "Petit-paquetville",
      "Petit-rocher",
      "Petit-rocher-nord",
      "Petit-rocher-ouest",
      "Petit-rocher-sud",
      "Petit-shippagan",
      "Petitcodiac East",
      "Petitcodiac",
      "Petite-lameque",
      "Petite-riviere-de-l'ile",
      "Picadilly",
      "Picketts Cove",
      "Piercemont",
      "Pigeon Hill",
      "Pine Glen",
      "Pine Ridge",
      "Pineville",
      "Plaster Rock",
      "Pleasant Ridge Char County",
      "Pleasant Ridge Kings Co",
      "Pleasant Villa",
      "Plumweseep",
      "Plymouth",
      "Pocologan",
      "Point De Bute",
      "Point La Nim",
      "Pointe A Bouleau",
      "Pointe A Tom",
      "Pointe Des Robichaud",
      "Pointe Dixon Point",
      "Pointe-alexandre",
      "Pointe-brule",
      "Pointe-canot",
      "Pointe-du-chene",
      "Pointe-sapin",
      "Pointe-sauvage",
      "Pointe-verte",
      "Poirier Subdivision",
      "Pokemouche",
      "Pokeshaw",
      "Pokesudie",
      "Pokiok",
      "Pole Hill",
      "Pollett River",
      "Pomeroy Ridge",
      "Pondstream",
      "Pont Lafrance",
      "Pont Landry",
      "Poodiac",
      "Port Elgin",
      "Portage St-louis",
      "Portage Vale",
      "Portage",
      "Porter Cove",
      "Priceville",
      "Prince Of Wales",
      "Prince William",
      "Princess Park",
      "Printz Cove",
      "Prosser Brook",
      "Public Landing",
      "Quaker Brook",
      "Quarryville",
      "Queenstown",
      "Quispamsis",
      "Rang-saint-georges",
      "Ratter Corner",
      "Red Bank Queens Co",
      "Red Bank Reserve",
      "Red Bank",
      "Red Bridge",
      "Red Rapids",
      "Red Rock",
      "Redmondville",
      "Renauds Mills",
      "Renous",
      "Rexton",
      "Riceville",
      "Richardson",
      "Richibouctou-village",
      "Richibucto Road",
      "Richibucto",
      "Richmond Corner",
      "Richmond Settlement",
      "Riley Brook",
      "Rio Grande",
      "Ripples",
      "Ritchie",
      "River De Chute",
      "River Glade",
      "Riverbank Carleton Co",
      "Riverbank Kings Co",
      "Riverbank South",
      "Riverside-albert",
      "Riverview",
      "Riviere A La Truite",
      "Riviere Du Nord",
      "Riviere-du-portage",
      "Riviere-verte",
      "Roachville",
      "Robertville",
      "Robichaud Settlement",
      "Robinsonville",
      "Rocheville",
      "Rockland",
      "Rockport",
      "Rogersville",
      "Rogersville-est",
      "Rogersville-ouest",
      "Rollingdam",
      "Rosaireville",
      "Rosedale",
      "Rosehill",
      "Rosevale",
      "Rossville",
      "Rothesay",
      "Rough Waters",
      "Rowena",
      "Rowley",
      "Royal Road",
      "Royalton",
      "Rusagonis",
      "Russellville",
      "Sackville Road",
      "Sackville",
      "Saint John",
      "Saint Pons",
      "Saint-amateur",
      "Saint-andre",
      "Saint-andre-leblanc",
      "Saint-antoine Sud",
      "Saint-antoine",
      "Saint-antoine-de-kent",
      "Saint-arthur",
      "Saint-basile",
      "Saint-charles",
      "Saint-damien",
      "Saint-edouard-de-kent",
      "Saint-francois-de-madawaska",
      "Saint-gregoire",
      "Saint-ignace",
      "Saint-irenee",
      "Saint-isidore",
      "Saint-jacques",
      "Saint-joseph-de-kent",
      "Saint-laurent Nord",
      "Saint-laurent",
      "Saint-leolin",
      "Saint-leonard",
      "Saint-leonard-parent",
      "Saint-louis",
      "Saint-louis-de-kent",
      "Saint-martin-de-restigouche",
      "Saint-maure",
      "Saint-maurice",
      "Saint-norbert",
      "Saint-paul",
      "Saint-philippe",
      "Saint-quentin",
      "Saint-sauveur",
      "Saint-simon",
      "Saint-thomas-de-kent",
      "Saint-wilfred",
      "Sainte Rose",
      "Sainte-anne Gloucester Co",
      "Sainte-anne-de-kent",
      "Sainte-anne-de-madawaska",
      "Sainte-cecile",
      "Sainte-louise",
      "Sainte-marie-de-kent",
      "Sainte-marie-saint-raphael",
      "Sainte-rosette",
      "Sainte-therese Sud",
      "Salem",
      "Salisbury West",
      "Salisbury",
      "Salmon Beach",
      "Salmon Creek",
      "Salmon River Road",
      "Salmon River",
      "Salt Springs",
      "Saumarez",
      "Savoie Landing",
      "Scotch Lake",
      "Scotch Ridge",
      "Scotch Settlement York Co",
      "Scotch Settlement",
      "Scotchtown",
      "Scott Siding",
      "Scoudouc Road",
      "Scoudouc",
      "Sea Side",
      "Searsville",
      "Second Falls",
      "Second North River",
      "Seeleys Cove",
      "Sevogle",
      "Shanklin",
      "Shannon",
      "Shediac Bridge",
      "Shediac Bridge-shediac River",
      "Shediac Cape",
      "Shediac River",
      "Shediac",
      "Sheffield",
      "Shemogue",
      "Shenstone",
      "Shepody Albert Co",
      "Shepody Kings Co",
      "Shippagan",
      "Siegas",
      "Sillikers",
      "Simonds",
      "Sisson Brook",
      "Sisson Ridge",
      "Six Roads",
      "Skiff Lake",
      "Slope",
      "Smith Crossing",
      "Smith's Corner",
      "Smithfield",
      "Smiths Creek",
      "Smithtown",
      "Snider Mountain",
      "Somerville",
      "Sormany",
      "South Branch Kent Co",
      "South Branch Kings Co",
      "South Canaan",
      "South Esk",
      "South Nelson",
      "South Oromocto Lake",
      "South Tetagouche",
      "Southampton",
      "Southfield",
      "Speerville",
      "Springdale",
      "Springfield Kings Co",
      "Springfield York Co",
      "Squaw Cap",
      "St Almo",
      "St Andrews",
      "St Croix",
      "St David Ridge",
      "St George",
      "St Margarets",
      "St Martins North",
      "St Martins",
      "St Stephen",
      "St Thomas",
      "St-antoine Nord",
      "St-hilaire",
      "St-jean-baptiste",
      "St-joseph-de-madawaska",
      "Stanley",
      "Staples Settlement",
      "Steeves Mountain",
      "Steeves Settlement",
      "Stickney",
      "Stilesville",
      "Stonehaven",
      "Stoney Creek",
      "Storeytown",
      "Strathadam",
      "Stuart Town",
      "Stymiest",
      "Summerfield Carleton Co",
      "Summerfield Kings Co",
      "Summerville",
      "Sunny Corner",
      "Sunnyside Beach",
      "Sussex Corner",
      "Sussex East",
      "Sussex South",
      "Sussex",
      "Swan Creek",
      "Sypher Cove",
      "Tabusintac",
      "Targettville",
      "Taxis River",
      "Tay Creek",
      "Tay Falls",
      "Taylor Village",
      "Taymouth",
      "Teeds Mills",
      "Temperance Vale",
      "Temple",
      "Tetagouche Falls",
      "The Glades",
      "Three Brooks",
      "Three Tree Creek",
      "Tide Head",
      "Tilley Road",
      "Tilley",
      "Timber River",
      "Tinker",
      "Titusville",
      "Tobique First Nation",
      "Tobique Narrows",
      "Tower Hill",
      "Tracadie Beach",
      "Tracadie-sheila",
      "Tracey Mills",
      "Tracy",
      "Tracyville",
      "Tremblay",
      "Trois-ruisseaux",
      "Trout Brook",
      "Trudel",
      "Turtle Creek",
      "Two Brooks",
      "Tynemouth Creek",
      "Union Corner",
      "Upham",
      "Upper Blackville",
      "Upper Brighton",
      "Upper Cape",
      "Upper Caverhill",
      "Upper Coverdale",
      "Upper Derby",
      "Upper Dorchester",
      "Upper Gagetown",
      "Upper Golden Grove",
      "Upper Hainesville",
      "Upper Hampstead",
      "Upper Kent",
      "Upper Keswick",
      "Upper Kingsclear",
      "Upper Kintore",
      "Upper Knoxford",
      "Upper Letang",
      "Upper Loch Lomond",
      "Upper Mills",
      "Upper Queensbury",
      "Upper Rexton",
      "Upper Rockport",
      "Upper Sackville",
      "Upper Salmon Creek",
      "Upper Tracy",
      "Upper Woodstock",
      "Upperton",
      "Upsalquitch",
      "Utopia",
      "Val Comeau",
      "Val-d'amour",
      "Val-doucet",
      "Valley Road",
      "Verret",
      "Vespra",
      "Victoria Corner",
      "Village Blanchard",
      "Village-des-poirier",
      "Village-saint-laurent",
      "Vinegar Hill",
      "Waasis",
      "Wakefield",
      "Walker Settlement",
      "Wapske",
      "Ward Settlement",
      "Wards Creek",
      "Warwick Settlement",
      "Waterborough",
      "Waterford",
      "Waterside",
      "Waterville Carleton Co",
      "Waterville-sunbury",
      "Waweig",
      "Wayerton",
      "Weaver Siding",
      "Weaver",
      "Weldon",
      "Welsford",
      "Welshpool",
      "West Branch",
      "West Florenceville",
      "West Quaco",
      "West River",
      "Westcock",
      "Weston",
      "Wheaton Settlement",
      "White Head Island",
      "White Head",
      "White Rapids",
      "Whites Brook",
      "Whites Cove",
      "Whites Mountain",
      "Whitney",
      "Wickham",
      "Wicklow",
      "Wiggins Mill",
      "Williamsburg",
      "Williamstown Carleton Co",
      "Williamstown",
      "Willow Grove",
      "Wilmot",
      "Wilsons Beach",
      "Windsor",
      "Wirral",
      "Wood Point",
      "Woodlands",
      "Woodmans Point",
      "Woodside",
      "Woodstock First Nation",
      "Woodstock",
      "Wuhrs Beach",
      "Wyers Brook",
      "Yoho",
      "Youngs Cove",
      "Zealand",
      "Zionville"
    ]
  },
  {
    "name": "Ontario",
    "key": "ON",
    "cities": [
      "Acton",
      "Addison",
      "Ahmic Harbour",
      "Ailsa Craig",
      "Ajax",
      "Akwesasne",
      "Alban",
      "Alberton",
      "Alexandria",
      "Alfred",
      "Algoma Mills",
      "Allanburg",
      "Allenford",
      "Alliston",
      "Alma",
      "Almonte",
      "Alton",
      "Alvinston",
      "Ameliasburg",
      "Amherstburg",
      "Amherstview",
      "Ancaster",
      "Angling Lake",
      "Angus",
      "Annan",
      "Appin",
      "Apple Hill",
      "Apsley",
      "Arden",
      "Ardoch",
      "Ariss",
      "Arkell",
      "Arkona",
      "Armstrong Station",
      "Arnprior",
      "Arnstein",
      "Aroland",
      "Arthur",
      "Arva",
      "Ashburn",
      "Ashton",
      "Astorville",
      "Astra",
      "Athens",
      "Atikokan",
      "Attawapiskat",
      "Atwood",
      "Auburn",
      "Aurora",
      "Avonmore",
      "Aylmer",
      "Ayr",
      "Ayton",
      "Azilda",
      "Baden",
      "Badjeros",
      "Bailieboro",
      "Bainsville",
      "Bala",
      "Balderson",
      "Baldwin",
      "Ballinafad",
      "Balmertown",
      "Baltimore",
      "Bancroft",
      "Barrie",
      "Barrys Bay",
      "Barwick",
      "Batawa",
      "Batchawana Bay",
      "Bath",
      "Battersea",
      "Bayfield",
      "Baysville",
      "Beachburg",
      "Beachville",
      "Beamsville",
      "Bear Island",
      "Beardmore",
      "Bearskin Lake",
      "Beaverton",
      "Beeton",
      "Belfountain",
      "Belgrave",
      "Belle Ewart",
      "Belle River",
      "Belle Vallee",
      "Belleville",
      "Belmont",
      "Belwood",
      "Berkeley",
      "Berwick",
      "Bethany",
      "Bewdley",
      "Big Trout Lake",
      "Binbrook",
      "Birch Island",
      "Biscotasing",
      "Bissett Creek",
      "Blackstock",
      "Blenheim",
      "Blezard Valley",
      "Blind River",
      "Bloomfield",
      "Bloomingdale",
      "Blue Mountains",
      "Bluevale",
      "Blyth",
      "Blytheswood",
      "Bobcaygeon",
      "Bognor",
      "Bolsover",
      "Bolton",
      "Bond Head",
      "Bonfield",
      "Borden",
      "Bornholm",
      "Bothwell",
      "Boulter",
      "Bourget",
      "Bowmanville",
      "Bracebridge",
      "Bradford",
      "Braeside",
      "Brampton",
      "Branchton",
      "Brantford",
      "Brechin",
      "Breslau",
      "Bridgenorth",
      "Brigden",
      "Bright",
      "Bright's Grove",
      "Brighton",
      "Brinston",
      "Britt",
      "Brockville",
      "Brodhagen",
      "Brougham",
      "Brownsville",
      "Bruce Mines",
      "Brucefield",
      "Brunner",
      "Brussels",
      "Buckhorn",
      "Burford",
      "Burgessville",
      "Burks Falls",
      "Burlington",
      "Burnstown",
      "Burnt River",
      "Burritts Rapids",
      "Byng Inlet",
      "Cache Bay",
      "Caesarea",
      "Caistor Centre",
      "Calabogie",
      "Caledon East",
      "Caledon Village",
      "Caledon",
      "Caledonia",
      "Callander",
      "Cambray",
      "Cambridge",
      "Camden East",
      "Cameron",
      "Camlachie",
      "Campbellcroft",
      "Campbellford",
      "Campbellville",
      "Campden",
      "Canfield",
      "Cannifton",
      "Cannington",
      "Capreol",
      "Caramat",
      "Cardiff",
      "Cardinal",
      "Cargill",
      "Carleton Place",
      "Carlisle",
      "Carlsbad Springs",
      "Carnarvon",
      "Carp",
      "Carrying Place",
      "Cartier",
      "Casselman",
      "Castleton",
      "Cat Lake",
      "Cathcart",
      "Cavan",
      "Cayuga",
      "Cedar Point",
      "Cedar Springs",
      "Cedar Valley",
      "Centralia",
      "Centreville",
      "Chalk River",
      "Chapleau",
      "Chaput Hughes",
      "Charing Cross",
      "Charlton",
      "Chatham",
      "Chatsworth",
      "Chelmsford",
      "Cheltenham",
      "Chepstow",
      "Cherry Valley",
      "Chesley",
      "Chesterville",
      "Churchill",
      "Chute A Blondeau",
      "Claremont",
      "Clarence Creek",
      "Clarendon Station",
      "Clarksburg",
      "Clayton",
      "Clear Creek",
      "Clearwater Bay",
      "Clifford",
      "Clinton",
      "Cloyne",
      "Coatsworth Station",
      "Cobalt",
      "Cobden",
      "Coboconk",
      "Cobourg",
      "Cochenour",
      "Cochrane",
      "Codrington",
      "Coe Hill",
      "Colborne",
      "Coldwater",
      "Collingwood",
      "Collins",
      "Comber",
      "Combermere",
      "Commanda",
      "Concord",
      "Conestogo",
      "Coniston",
      "Conn",
      "Connaught",
      "Consecon",
      "Constance Lake",
      "Cookstown",
      "Copetown",
      "Copper Cliff",
      "Corbeil",
      "Corbyville",
      "Cormac",
      "Cornwall",
      "Corunna",
      "Cottam",
      "Courtice",
      "Courtland",
      "Courtright",
      "Crediton",
      "Creemore",
      "Croton",
      "Crysler",
      "Crystal Beach",
      "Crystal Falls",
      "Cumberland Beach",
      "Cumberland",
      "Curran",
      "Curve Lake",
      "Cutler",
      "Dacre",
      "Dalkeith",
      "Dashwood",
      "Deep River",
      "Deer Lake",
      "Delaware",
      "Delhi",
      "Delta",
      "Demorestville",
      "Denbigh",
      "Denfield",
      "Desbarats",
      "Desboro",
      "Deseronto",
      "Deux Rivieres",
      "Devlin",
      "Dinorwic",
      "Dobbinton",
      "Dobie",
      "Dorchester",
      "Dorion",
      "Dorset",
      "Douglas",
      "Douro",
      "Dover Centre",
      "Dowling",
      "Drayton",
      "Dresden",
      "Driftwood",
      "Drumbo",
      "Dryden",
      "Duart",
      "Dublin",
      "Dubreuilville",
      "Dunchurch",
      "Dundalk",
      "Dundas",
      "Dungannon",
      "Dunnville",
      "Dunrobin",
      "Dunsford",
      "Duntroon",
      "Dunvegan",
      "Durham",
      "Dutton",
      "Dwight",
      "Eabamet Lake",
      "Eagle Lake",
      "Eagle River",
      "Ear Falls",
      "Earlton",
      "East Gwillimbury",
      "East York",
      "Echo Bay",
      "Eden Mills",
      "Eden",
      "Edwards",
      "Eganville",
      "Egbert",
      "Egmondville",
      "Eldorado",
      "Elgin",
      "Elginburg",
      "Elizabethtown",
      "Elk Lake",
      "Elliot Lake",
      "Elmira",
      "Elmvale",
      "Elmwood",
      "Elora",
      "Embro",
      "Embrun",
      "Emeryville",
      "Emo",
      "Emsdale",
      "Englehart",
      "Ennismore",
      "Enterprise",
      "Erieau",
      "Erin",
      "Erinsville",
      "Espanola",
      "Essex",
      "Ethel",
      "Etobicoke",
      "Evansville",
      "Everett",
      "Exeter",
      "Falconbridge",
      "Fauquier",
      "Fenelon Falls",
      "Fenwick",
      "Fergus",
      "Feversham",
      "Field",
      "Finch",
      "Fingal",
      "Fisherville",
      "Fitzroy Harbour",
      "Flesherton",
      "Flinton",
      "Floradale",
      "Florence",
      "Foleyet",
      "Fonthill",
      "Fordwich",
      "Forest",
      "Foresters Falls",
      "Formosa",
      "Fort Albany",
      "Fort Erie",
      "Fort Frances",
      "Fort Severn",
      "Fournier",
      "Foxboro",
      "Foymount",
      "Frankford",
      "Frankville",
      "Fraserville",
      "Frederickhouse",
      "Freelton",
      "Fullarton",
      "Gads Hill Station",
      "Gananoque",
      "Garden River",
      "Garden Village",
      "Garson",
      "Gatineau",
      "Georgetown",
      "Geraldton",
      "Gilford",
      "Gilmour",
      "Glen Huron",
      "Glen Morris",
      "Glen Robertson",
      "Glenburnie",
      "Glencairn",
      "Glencoe",
      "Gloucester",
      "Goderich",
      "Godfrey",
      "Gogama",
      "Golden Lake",
      "Golden Valley",
      "Gooderham",
      "Goodwood",
      "Gore Bay",
      "Gores Landing",
      "Gormley",
      "Gorrie",
      "Goulais River",
      "Gowanstown",
      "Gowganda",
      "Grafton",
      "Graham",
      "Grand Bend",
      "Grand Valley",
      "Grande Pointe",
      "Granton",
      "Grassie",
      "Grassy Narrows",
      "Gravenhurst",
      "Greely",
      "Green Valley",
      "Greenbank",
      "Greenwood",
      "Griffith",
      "Grimsby",
      "Guelph",
      "Gull Bay",
      "Hagar",
      "Hagersville",
      "Haileybury",
      "Haley Station",
      "Haliburton",
      "Hallebourg",
      "Hamilton",
      "Hammond",
      "Hampton",
      "Hanmer",
      "Hannon",
      "Hanover",
      "Harcourt",
      "Harley",
      "Harrietsville",
      "Harriston",
      "Harrow",
      "Harrowsmith",
      "Hartington",
      "Harty",
      "Harwood",
      "Hastings",
      "Havelock",
      "Hawk Junction",
      "Hawkesbury",
      "Hawkestone",
      "Hawkesville",
      "Hay",
      "Haydon",
      "Hearst",
      "Heathcote",
      "Heidelberg",
      "Hensall",
      "Hepworth",
      "Heron Bay",
      "Hickson",
      "Highgate",
      "Highland Grove",
      "Hilliardton",
      "Hillier",
      "Hillsburgh",
      "Hillsdale",
      "Hilton Beach",
      "Holland Centre",
      "Holland Landing",
      "Holstein",
      "Holtyre",
      "Holyrood",
      "Honey Harbour",
      "Honeywood",
      "Hornby",
      "Hornell Heights",
      "Hornepayne",
      "Hornings Mills",
      "Hudson",
      "Hunta",
      "Huntsville",
      "Huron Park",
      "Ignace",
      "Ilderton",
      "Indian River",
      "Ingersoll",
      "Ingleside",
      "Inglewood",
      "Ingolf",
      "Inkerman",
      "Innerkip",
      "Innisfil",
      "Inverary",
      "Inwood",
      "Iona Station",
      "Iron Bridge",
      "Irondale",
      "Iroquois Falls A",
      "Iroquois Falls",
      "Iroquois",
      "Jacksons Point",
      "Janetville",
      "Jarvis",
      "Jasper",
      "Jellicoe",
      "Jerseyville",
      "Jogues",
      "Jordan Station",
      "Joyceville",
      "Juniper Island",
      "Kagawong",
      "Kakabeka Falls",
      "Kaladar",
      "Kaministiquia",
      "Kanata",
      "Kapuskasing",
      "Kars",
      "Kasabonika",
      "Kashabowie",
      "Kashechewan",
      "Katrine",
      "Kearney",
      "Kearns",
      "Keene",
      "Keewatin",
      "Keewaywin",
      "Kejick",
      "Kemble",
      "Kemptville",
      "Kenabeek",
      "Kendal",
      "Kenilworth",
      "Kenmore",
      "Kenora",
      "Kent Bridge",
      "Kerwood",
      "Keswick",
      "Kettleby",
      "Killaloe",
      "Killarney",
      "Kilworthy",
      "Kimberley",
      "Kinburn",
      "Kincardine",
      "King City",
      "King Kirkland",
      "Kingfisher Lake",
      "Kingston",
      "Kingsville",
      "Kinmount",
      "Kintore",
      "Kippen",
      "Kirkfield",
      "Kirkland Lake",
      "Kirkton",
      "Kitchener",
      "Kleinburg",
      "Komoka",
      "L'amable",
      "L'orignal",
      "La Salette",
      "Lac Seul",
      "Lake Of Bays",
      "Lake St Peter",
      "Lakefield",
      "Lakehurst",
      "Lakeside",
      "Lanark",
      "Lancaster",
      "Langton",
      "Lansdowne House",
      "Lansdowne",
      "Larder Lake",
      "Lasalle",
      "Latchford",
      "Laurel",
      "Lavigne",
      "Leamington",
      "Leaskdale",
      "Lefaivre",
      "Lefroy",
      "Leith",
      "Levack",
      "Limehouse",
      "Limoges",
      "Lindsay",
      "Linwood",
      "Lions Head",
      "Lisle",
      "Listowel",
      "Little Britain",
      "Little Current",
      "Lively",
      "Lochlin",
      "Locust Hill",
      "Lombardy",
      "Londesborough",
      "London",
      "Long Sault",
      "Longbow Lake",
      "Longford Mills",
      "Longlac",
      "Loretto",
      "Loring",
      "Lowbanks",
      "Lucan",
      "Lucknow",
      "Lunenburg",
      "Lyn",
      "Lynden",
      "Lyndhurst",
      "M'chigeeng",
      "Maberly",
      "Macdiarmid",
      "Mackey",
      "Mactier",
      "Madawaska",
      "Madoc",
      "Madsen",
      "Magnetawan",
      "Maidstone",
      "Maitland",
      "Mallorytown",
      "Manilla",
      "Manitouwadge",
      "Manitowaning",
      "Manotick",
      "Mansfield",
      "Maple Leaf",
      "Maple",
      "Mar",
      "Marathon",
      "Markdale",
      "Markham",
      "Markstay",
      "Marlbank",
      "Marmora",
      "Marten River",
      "Martintown",
      "Maryhill",
      "Marysville",
      "Massey",
      "Matachewan",
      "Matheson",
      "Mattawa",
      "Mattice",
      "Maxville",
      "Maxwell",
      "Maynooth",
      "Mcarthurs Mills",
      "Mcdonalds Corners",
      "Mcgregor",
      "Mckellar",
      "Mckenzie Island",
      "Mckerrow",
      "Meaford",
      "Melbourne",
      "Meldrum Bay",
      "Merlin",
      "Merrickville",
      "Metcalfe",
      "Midhurst",
      "Midland",
      "Migisi Sahgaigan",
      "Mildmay",
      "Milford Bay",
      "Milford",
      "Millbank",
      "Millbrook",
      "Miller Lake",
      "Millgrove",
      "Milton",
      "Milverton",
      "Minaki",
      "Mindemoya",
      "Minden",
      "Mine Centre",
      "Minesing",
      "Minett",
      "Minnitaki",
      "Missanabie",
      "Mississauga",
      "Mississippi Station",
      "Mitchell",
      "Mobert",
      "Moffat",
      "Monetville",
      "Monkland",
      "Monkton",
      "Monteith",
      "Montreal River Harbour",
      "Moonbeam",
      "Moonstone",
      "Moorefield",
      "Mooretown",
      "Moose Creek",
      "Moose Factory",
      "Moosonee",
      "Morewood",
      "Morpeth",
      "Morrisburg",
      "Morriston",
      "Morson",
      "Mossley",
      "Mount Albert",
      "Mount Brydges",
      "Mount Elgin",
      "Mount Forest",
      "Mount Hope",
      "Mount Pleasant",
      "Mountain Grove",
      "Mountain",
      "Muirkirk",
      "Muncey",
      "Munster",
      "Murillo",
      "Muskrat Dam",
      "Nairn Centre",
      "Nakina",
      "Nanticoke",
      "Napanee",
      "Naughton",
      "Navan",
      "Neebing",
      "Nepean",
      "Nestleton Station",
      "Nestor Falls",
      "Neustadt",
      "New Dundee",
      "New Hamburg",
      "New Liskeard",
      "New Lowell",
      "Newboro",
      "Newburgh",
      "Newbury",
      "Newcastle",
      "Newington",
      "Newmarket",
      "Newton",
      "Newtonville",
      "Niagara Falls",
      "Niagara On The Lake",
      "Nipigon",
      "Nipissing",
      "Nobel",
      "Nobleton",
      "Noelville",
      "Nolalu",
      "Norland",
      "North Augusta",
      "North Bay",
      "North Buxton",
      "North Cobalt",
      "North Gower",
      "North Lancaster",
      "North Spirit Lake",
      "North York",
      "Northbrook",
      "Norval",
      "Norwich",
      "Norwood",
      "Nottawa",
      "Novar",
      "Oakland",
      "Oakville",
      "Oakwood",
      "Oba",
      "Odessa",
      "Ogoki",
      "Ohsweken",
      "Oil City",
      "Oil Springs",
      "Oldcastle",
      "Omemee",
      "Ompah",
      "Onaping",
      "Opasatika",
      "Orangeville",
      "Orillia",
      "Orleans",
      "Oro Station",
      "Oro",
      "Orono",
      "Orton",
      "Osgoode",
      "Oshawa",
      "Osnaburgh House",
      "Ottawa",
      "Otterville",
      "Owen Sound",
      "Oxdrift",
      "Oxford Mills",
      "Oxford Station",
      "Pain Court",
      "Paisley",
      "Pakenham",
      "Palgrave",
      "Palmer Rapids",
      "Palmerston",
      "Parham",
      "Paris",
      "Parkhill",
      "Parry Sound",
      "Pass Lake",
      "Pawitik",
      "Pays Plat",
      "Peawanuck",
      "Pefferlaw",
      "Pelee Island",
      "Pembroke",
      "Penetanguishene",
      "Perkinsfield",
      "Perrault Falls",
      "Perth Road",
      "Perth",
      "Petawawa",
      "Peterborough",
      "Petersburg",
      "Petrolia",
      "Phelpston",
      "Pickerel",
      "Pickering",
      "Pickle Lake",
      "Picton",
      "Pikangikum",
      "Pinewood",
      "Plainfield",
      "Plantagenet",
      "Plattsville",
      "Plevna",
      "Point Edward",
      "Pointe Aux Roches",
      "Pointe-au-baril-station",
      "Pontypool",
      "Poole",
      "Poplar Hill",
      "Porcupine",
      "Porquis Junction",
      "Port Alma",
      "Port Burwell",
      "Port Carling",
      "Port Colborne",
      "Port Dover",
      "Port Elgin",
      "Port Franks",
      "Port Hope",
      "Port Lambton",
      "Port Loring",
      "Port Mcnicoll",
      "Port Perry",
      "Port Robinson",
      "Port Rowan",
      "Port Sandfield",
      "Port Severn",
      "Port Stanley",
      "Port Sydney",
      "Portland",
      "Powassan",
      "Prescott",
      "Priceville",
      "Prince Albert",
      "Princeton",
      "Proton Station",
      "Providence Bay",
      "Puslinch",
      "Putnam",
      "Quadeville",
      "Queenston",
      "Queensville",
      "Rainy River",
      "Raith",
      "Rama",
      "Ramore",
      "Ramsayville",
      "Ramsey",
      "Ravenna",
      "Reaboro",
      "Red Lake",
      "Red Rock",
      "Redbridge",
      "Redditt",
      "Renfrew",
      "Restoule",
      "Richards Landing",
      "Richmond Hill",
      "Richmond",
      "Rideau Ferry",
      "Ridgetown",
      "Ridgeville",
      "Ridgeway",
      "Ripley",
      "River Drive Park",
      "River Valley",
      "Roblin",
      "Roches Point",
      "Rockcliffe",
      "Rockland",
      "Rockport",
      "Rockton",
      "Rockwood",
      "Rodney",
      "Rolphton",
      "Rosemont",
      "Roseneath",
      "Roslin",
      "Rosseau",
      "Rossport",
      "Rostock",
      "Round Lake Centre",
      "Ruscom Station",
      "Russell",
      "Rutherglen",
      "Ruthven",
      "Sachigo Lake",
      "Saint-pascal-baylon",
      "Salford",
      "Sandford",
      "Sandy Lake",
      "Sarnia",
      "Sarsfield",
      "Sauble Beach",
      "Sault Ste. Marie",
      "Savant Lake",
      "Scarborough",
      "Schomberg",
      "Schreiber",
      "Schumacher",
      "Scotland",
      "Seaforth",
      "Seagrave",
      "Searchmont",
      "Sebright",
      "Sebringville",
      "Seeleys Bay",
      "Selby",
      "Selkirk",
      "Serpent River",
      "Sesekinika",
      "Severn Bridge",
      "Shakespeare",
      "Shallow Lake",
      "Shannonville",
      "Shanty Bay",
      "Sharbot Lake",
      "Sharon",
      "Shebandowan",
      "Shedden",
      "Sheffield",
      "Sheguiandah",
      "Shelburne",
      "Sherkston",
      "Sheshegwaning",
      "Shining Tree",
      "Silver Water",
      "Simcoe",
      "Singhampton",
      "Sioux Lookout",
      "Sioux Narrows",
      "Skead",
      "Slate Falls",
      "Sleeman",
      "Smiths Falls",
      "Smithville",
      "Smooth Rock Falls",
      "Snow Road Station",
      "Sombra",
      "South Baymouth",
      "South Gillies",
      "South Lancaster",
      "South Mountain",
      "South Porcupine",
      "South River",
      "South Woodslee",
      "Southampton",
      "Southwold",
      "Spanish",
      "Sparta",
      "Spencerville",
      "Spragge",
      "Spring Bay",
      "Springbrook",
      "Springfield",
      "Springford",
      "Sprucedale",
      "St Agatha",
      "St Albert",
      "St Andrews West",
      "St Anns",
      "St Bernardin",
      "St Catharines",
      "St Charles",
      "St Clements",
      "St Davids",
      "St Eugene",
      "St George Brant",
      "St Isidore",
      "St Jacobs",
      "St Joachim",
      "St Marys",
      "St Pauls Station",
      "St Thomas",
      "St Williams",
      "Staffa",
      "Staples",
      "Stayner",
      "Ste Anne De Prescott",
      "Stella",
      "Stevensville",
      "Stirling",
      "Stittsville",
      "Stokes Bay",
      "Stonecliffe",
      "Stoney Creek",
      "Stouffville",
      "Straffordville",
      "Stratford",
      "Strathroy",
      "Stratton",
      "Strickland",
      "Sturgeon Falls",
      "Sudbury",
      "Sultan",
      "Summer Beaver",
      "Summerstown",
      "Sunderland",
      "Sundridge",
      "Sutton West",
      "Swastika",
      "Sydenham",
      "Talbotville Royal",
      "Tamworth",
      "Tara",
      "Tarzwell",
      "Tavistock",
      "Tecumseh",
      "Teeswater",
      "Teeterville",
      "Tehkummah",
      "Temagami",
      "Terra Cotta",
      "Terrace Bay",
      "Thamesford",
      "Thamesville",
      "Thedford",
      "Thessalon",
      "Thomasburg",
      "Thornbury",
      "Thorndale",
      "Thorne",
      "Thornhill",
      "Thornloe",
      "Thornton",
      "Thorold",
      "Thunder Bay",
      "Tichborne",
      "Tilbury",
      "Tilden Lake",
      "Tillsonburg",
      "Timmins",
      "Tiverton",
      "Tobermory",
      "Toledo",
      "Toronto",
      "Torrance",
      "Tory Hill",
      "Tottenham",
      "Townsend",
      "Trent River",
      "Trenton",
      "Trout Creek",
      "Troy",
      "Tunis",
      "Tupperville",
      "Turkey Point",
      "Tweed",
      "Udora",
      "Union",
      "Unionville",
      "Upper Canada Village",
      "Upsala",
      "Utopia",
      "Utterson",
      "Uxbridge",
      "Val Caron",
      "Val Cote",
      "Val Gagne",
      "Val Rita",
      "Val Therese",
      "Vanessa",
      "Vanier",
      "Vankleek Hill",
      "Varna",
      "Vars",
      "Vaughan",
      "Vermilion Bay",
      "Verner",
      "Vernon",
      "Verona",
      "Victoria Harbour",
      "Vienna",
      "Vineland Station",
      "Vineland",
      "Virgil",
      "Virginiatown",
      "Vittoria",
      "Wabigoon",
      "Wahnapitae",
      "Wainfleet",
      "Waldhof",
      "Walford Station",
      "Walkerton",
      "Wallaceburg",
      "Wallacetown",
      "Wallenstein",
      "Walsingham",
      "Walters Falls",
      "Walton",
      "Wardsville",
      "Warkworth",
      "Warminster",
      "Warren",
      "Warsaw",
      "Wasaga Beach",
      "Washago",
      "Waterdown",
      "Waterford",
      "Waterloo",
      "Watford",
      "Waubaushene",
      "Wawa",
      "Weagamow Lake",
      "Webbwood",
      "Webequie",
      "Welland",
      "Wellandport",
      "Wellesley",
      "Wellington",
      "Wendover",
      "West Guilford",
      "West Lorne",
      "West Montrose",
      "Westbrook",
      "Westmeath",
      "Westport",
      "Westwood",
      "Wheatley",
      "Whitby",
      "White Lake",
      "White River",
      "Whitedog",
      "Whitefish Falls",
      "Whitefish",
      "Whitevale",
      "Whitney",
      "Wiarton",
      "Wikwemikong",
      "Wilberforce",
      "Wilkesport",
      "Williamsburg",
      "Williamsford",
      "Williamstown",
      "Willow Beach",
      "Wilmot",
      "Wilno",
      "Wilsonville",
      "Winchester Springs",
      "Winchester",
      "Windermere",
      "Windham Centre",
      "Windsor",
      "Wingham",
      "Wolfe Island",
      "Woodbridge",
      "Woodham",
      "Woodlawn",
      "Woodstock",
      "Woodview",
      "Woodville",
      "Wooler",
      "Worthington",
      "Wroxeter",
      "Wunnumin Lake",
      "Wyebridge",
      "Wyevale",
      "Wyoming",
      "Yarker",
      "York",
      "York.",
      "Youngs Point",
      "Zephyr",
      "Zurich"
    ]
  },
  {
    "name": "Nova Scotia",
    "key": "NS",
    "cities": [
      "Admiral Rock",
      "Advocate Harbour",
      "Afton Station",
      "Albert Bridge",
      "Alder Point",
      "Alton",
      "Amherst",
      "Annapolis Royal",
      "Antigonish",
      "Antrim",
      "Arcadia",
      "Arichat",
      "Askilton",
      "Aspen",
      "Auburn",
      "Auburndale",
      "Avonport",
      "Aylesford",
      "Baccaro",
      "Baddeck",
      "Baker Settlement",
      "Bald Rock",
      "Balls Creek",
      "Barneys River Station",
      "Barr Settlement",
      "Barra Glen",
      "Barrachois",
      "Barrington Passage",
      "Barrington",
      "Barss Corner",
      "Barton",
      "Bass River",
      "Bateston",
      "Bayside",
      "Bear Cove",
      "Bear River",
      "Beaver Bank",
      "Beaver Brook",
      "Beaver Cove",
      "Bedford",
      "Beechmont",
      "Beechville",
      "Belle Cote",
      "Belliveau Cove",
      "Belmont",
      "Belnan",
      "Ben Eoin",
      "Benacadie",
      "Berwick",
      "Bible Hill",
      "Bickerton West",
      "Big Beach",
      "Big Bras D'or",
      "Big Lake",
      "Big Pond Centre",
      "Big Pond",
      "Big Ridge",
      "Birch Grove",
      "Birch Hill",
      "Black Brook",
      "Black Point",
      "Black Rock",
      "Blacketts Lake",
      "Blandford",
      "Blind Bay",
      "Blockhouse",
      "Boisdale",
      "Boularderie Center",
      "Boularderie East",
      "Boutiliers Point",
      "Boylston",
      "Branch Lahave",
      "Bras D'or",
      "Brentwood",
      "Bridgetown",
      "Bridgewater",
      "Brookfield",
      "Brooklyn",
      "Brookside",
      "Brookside.",
      "Broughton",
      "Buckfield",
      "Caledonia",
      "Cambridge",
      "Camden",
      "Camperdown",
      "Canning",
      "Canso",
      "Cape Dauphin",
      "Cape Negro",
      "Cape North",
      "Capstick",
      "Caribou Marsh",
      "Carleton",
      "Carrolls Corner",
      "Castle Bay",
      "Catalone Gut",
      "Catalone",
      "Central North River",
      "Central Onslow",
      "Centre Burlington",
      "Centreville",
      "Chaswood",
      "Chelsea",
      "Cherry Brook",
      "Chester Basin",
      "Chester",
      "Cheticamp",
      "Cheverie",
      "Christmas Island",
      "Church Point",
      "Clam Point",
      "Clarks Harbour",
      "Clementsport",
      "Clementsvale",
      "Cleveland",
      "Clifton",
      "Cloverdale",
      "Clyde River",
      "Coldbrook",
      "Coldstream",
      "Cole Harbour",
      "Collingwood Corner",
      "Conquerall Bank",
      "Conquerall Mills",
      "Cooks Brook",
      "Cookville",
      "Cornwallis",
      "Cow Bay",
      "Coxheath",
      "Craigmore",
      "Crandall Road",
      "Creignish",
      "Cross Roads Country Harbour",
      "Crouses Settlement",
      "Crousetown",
      "Crowell",
      "Crowes Mills",
      "Currys Corner",
      "D'escousse",
      "Dalem Lake",
      "Danesville",
      "Dartmouth",
      "Dayspring",
      "Debert",
      "Deep Brook",
      "Densmores Mills",
      "Digby",
      "Diligent River",
      "Dingwall",
      "Dominion",
      "Donkin",
      "Duncans Cove",
      "Dutch Brook",
      "Dutch Settlement",
      "East Baccaro",
      "East Bay",
      "East Clifford",
      "East Dover",
      "East Gore",
      "East Lahave",
      "East Lawrencetown",
      "East Mountain",
      "East Pennant",
      "East Preston",
      "East Stewiacke",
      "Eastern Passage",
      "Economy",
      "Edwardsville",
      "Elderbank",
      "Ellershouse",
      "Elmsdale",
      "Enfield",
      "Englishtown",
      "Enon",
      "Eskasoni",
      "Eureka",
      "Fall River",
      "Falmouth",
      "Fergusons Cove",
      "Fishermans Harbour",
      "Five Islands",
      "Fletchers Lake",
      "Florence",
      "Fort Ellis",
      "Fortress Of Louisbourg",
      "Fourchu",
      "Framboise Intervale",
      "Framboise",
      "Frankville",
      "Freeport",
      "French Road",
      "French Village",
      "Frenchvale",
      "Gabarus Lake",
      "Gabarus",
      "Gardiner Mines",
      "Gays River",
      "Georges River",
      "Gillis Lake",
      "Gillis Point",
      "Glace Bay",
      "Glen Haven",
      "Glen Margaret",
      "Glenwood",
      "Goffs",
      "Goldboro",
      "Goodwood",
      "Goshen",
      "Grand Etang",
      "Grand Lake Road",
      "Grand Lake",
      "Grand Mira North",
      "Grand Mira South",
      "Grand Narrows",
      "Grand Pre",
      "Grand River",
      "Granville Ferry",
      "Grass Cove",
      "Great Village",
      "Green Bay",
      "Green Creek",
      "Green Oaks",
      "Greenfield",
      "Greenfield.",
      "Greenwood",
      "Grimms Settlement",
      "Groves Point",
      "Guysborough",
      "Hacketts Cove",
      "Halibut Bay",
      "Halifax",
      "Hammonds Plains",
      "Hampton",
      "Hantsport",
      "Hardwood Lands",
      "Harmony",
      "Harrietsfield",
      "Hatchet Lake",
      "Havre Boucher",
      "Head Of Chezzetcook",
      "Head Of Jeddore",
      "Head Of St Margarets Bay",
      "Heatherton",
      "Hebbs Cross",
      "Hebbville",
      "Hebron",
      "Herring Cove",
      "Highland Hill",
      "Hillside Boularderie",
      "Homeville",
      "Hopewell",
      "Howie Center",
      "Hubbards",
      "Hubley",
      "Huntington",
      "Hunts Point",
      "Indian Brook 14",
      "Indian Harbour",
      "Indian Path",
      "Ingomar",
      "Ingonish Beach",
      "Ingonish",
      "Ingramport",
      "Inverness",
      "Iona",
      "Irish Cove",
      "Irishvale",
      "Ironville",
      "Isaacs Harbour",
      "Islandview",
      "Italy Cross",
      "Jamesville",
      "Jeddore Oyster Ponds",
      "Joggins",
      "Jordan Falls",
      "Judique",
      "Juniper Mountain",
      "Kempt Head",
      "Kemptown",
      "Kemptville",
      "Kennetcook",
      "Kentville",
      "Ketch Harbour",
      "Kingston",
      "Kinsac",
      "L'ardoise",
      "La Have",
      "Labelle",
      "Laconia",
      "Lake Charlotte",
      "Lake Echo",
      "Lake Egmont",
      "Lake Uist",
      "Lakeside",
      "Lakeview",
      "Lanesville",
      "Lantz",
      "Lapland",
      "Larrys River",
      "Lawrencetown",
      "Lawrencetown.",
      "Leitches Creek",
      "Lewis Lake",
      "Lexington",
      "Lingan Road",
      "Lingan",
      "Liscomb",
      "Little Bras D'or",
      "Little Brook",
      "Little Dover",
      "Little Lorraine",
      "Little Narrows",
      "Little Pond",
      "Little River",
      "Little Tancook",
      "Liverpool",
      "Loch Lomond",
      "Lockeport",
      "Londonderry",
      "Long Island",
      "Long Point",
      "Louisbourg",
      "Louisdale",
      "Lower Branch",
      "Lower East Pubnico",
      "Lower Five Islands",
      "Lower L'ardoise",
      "Lower Northfield",
      "Lower Onslow",
      "Lower Prospect",
      "Lower Sackville",
      "Lower Truro",
      "Lower Washabuck",
      "Lower Wedgeport",
      "Lower West Pubnico",
      "Lower Woods Harbour",
      "Lucasville",
      "Lunenburg",
      "Lydgate",
      "Mabou",
      "Macadams Lake",
      "Maccan",
      "Mackay Siding",
      "Mackdale",
      "Mackinnons Harbour",
      "Macphees Corner",
      "Mahone Bay",
      "Main-a-dieu",
      "Maitland Bridge",
      "Maitland",
      "Malagash",
      "Manganese Mines",
      "Maple Grove",
      "Margaree Centre",
      "Margaree Forks",
      "Margaree Harbour",
      "Margaree Valley",
      "Margaree",
      "Margaretsville",
      "Marie Joseph",
      "Marion Bridge",
      "Mavillette",
      "Mccallum Settlement",
      "Mcgraths Cove",
      "Mcgray",
      "Meaghers Grant",
      "Melville",
      "Membertou",
      "Merigomish",
      "Meteghan Centre",
      "Meteghan River",
      "Meteghan",
      "Micmac",
      "Middle Cape",
      "Middle Lahave",
      "Middle Musquodoboit",
      "Middle Porters Lake",
      "Middle Sackville",
      "Middle Stewiacke",
      "Middle West Pubnico",
      "Middleton",
      "Middlewood",
      "Midville Branch",
      "Milford Station",
      "Milford",
      "Mill Creek",
      "Mill Village",
      "Mill Village.",
      "Millbrook",
      "Millville",
      "Milton",
      "Mineville",
      "Mira Gut",
      "Mira Road",
      "Molega Lake",
      "Monastery",
      "Montague Gold Mines",
      "Mooseland",
      "Moser River",
      "Mount Uniacke",
      "Mulgrave",
      "Murray Siding",
      "Musquodoboit Harbour",
      "Nappan",
      "Neils Harbour",
      "New Campbellton",
      "New Canada",
      "New Germany",
      "New Glasgow",
      "New Harris",
      "New Haven",
      "New Minas",
      "New Ross",
      "New Victoria",
      "New Waterford",
      "Newcombville",
      "Newport Station",
      "Newport",
      "Newtown",
      "Nine Mile River",
      "Noel",
      "North East Margaree",
      "North East Point",
      "North Preston",
      "North River",
      "North Salem",
      "North Sydney",
      "North West Arm",
      "Northport",
      "Northside East Bay",
      "Nuttby",
      "Oakfield",
      "Oakhill",
      "Old Barns",
      "Oldham",
      "Onslow Mountain",
      "Orangedale",
      "Osborne Harbour",
      "Ottawa Brook",
      "Oxford Junction",
      "Oxford",
      "Paradise",
      "Parrsboro",
      "Peggys Cove",
      "Petit De Grat",
      "Petit Etang",
      "Petite Riviere",
      "Pictou Island",
      "Pictou",
      "Pine Grove",
      "Pine Grove.",
      "Pinehurst",
      "Pipers Cove",
      "Pleasant Bay",
      "Pleasant Hill",
      "Pleasantville",
      "Plympton",
      "Point Aconi",
      "Point Edward",
      "Point Tupper",
      "Port Caledonia",
      "Port Clyde",
      "Port Dufferin",
      "Port Greville",
      "Port Hastings",
      "Port Hawkesbury",
      "Port Hood",
      "Port Howe",
      "Port Joli",
      "Port La Tour",
      "Port Maitland",
      "Port Malcolm",
      "Port Medway",
      "Port Morien",
      "Port Mouton",
      "Port Williams",
      "Portage",
      "Porters Lake",
      "Portuguese Cove",
      "Prime Brook",
      "Princeport",
      "Prospect Bay",
      "Prospect Village",
      "Prospect",
      "Pubnico",
      "Pugwash Junction",
      "Pugwash",
      "Queensville",
      "Red Point",
      "Reserve Mines",
      "Rhodes Corner",
      "Rines Creek",
      "River Bourgeois",
      "River Denys",
      "River Hebert East",
      "River Hebert",
      "River John",
      "River Philip",
      "River Ryan",
      "Riverport",
      "Riversdale",
      "Riverside",
      "Rock Elm",
      "Rose Bay",
      "Ross Ferry",
      "Round Island",
      "Sable River",
      "Salmon River",
      "Salmon River.",
      "Salt Springs",
      "Sambro Creek",
      "Sambro Head",
      "Sambro",
      "Sampson Cove",
      "Sandfield",
      "Sandy Cove",
      "Saulnierville",
      "Scotch Lake",
      "Scotch Village",
      "Scotchtown",
      "Scotsburn",
      "Scotsville",
      "Seabright",
      "Shad Bay",
      "Shag Harbour",
      "Shearwater",
      "Sheet Harbour",
      "Shelburne",
      "Shenacadie",
      "Sherbrooke",
      "Shortts Lake",
      "Shubenacadie East",
      "Shubenacadie",
      "Smiths Cove",
      "Smithsville",
      "South Bar",
      "South Brookfield",
      "South Head",
      "South Maitland",
      "South Ohio",
      "South West Margaree",
      "Southampton",
      "Southside Boularderie",
      "Spectacle Lakes",
      "Springfield",
      "Springhill",
      "St Andrews Channel",
      "St Andrews",
      "St Columba",
      "St Margaret Village",
      "St Peters",
      "St-joseph-du-moine",
      "Ste Croix",
      "Ste-anne-du-ruisseau",
      "Stellarton",
      "Stewiacke",
      "Stillwater Lake",
      "Stirling",
      "Stoney Island",
      "Sugar Camp",
      "Summerville",
      "Sunnybrae",
      "Sydney Forks",
      "Sydney Mines",
      "Sydney River",
      "Sydney",
      "Tancook Island",
      "Tangier",
      "Tantallon",
      "Tatamagouche",
      "Terence Bay",
      "Thomasville",
      "Thorburn",
      "Timberlea",
      "Tiverton",
      "Tower Road",
      "Trenton",
      "Troy",
      "Truro Heights",
      "Truro",
      "Tusket",
      "Upper Branch",
      "Upper Brookside",
      "Upper Chelsea",
      "Upper Grand Mira",
      "Upper Hammonds Plains",
      "Upper Kennetcook",
      "Upper Lahave",
      "Upper Leitches Creek",
      "Upper Musquodoboit",
      "Upper Nine Mile River",
      "Upper North River",
      "Upper North Sydney",
      "Upper Northfield",
      "Upper Onslow",
      "Upper Port La Tour",
      "Upper Rawdon",
      "Upper Sackville",
      "Upper Stewiacke",
      "Upper Tantallon",
      "Upper Washabuck",
      "Urbania",
      "Valley",
      "Victoria Mines",
      "Wagmatcook",
      "Wallace",
      "Walton",
      "Washabuck Centre",
      "Waterloo",
      "Waterville",
      "Waverley",
      "Wedgeport",
      "Wellington",
      "Wentworth",
      "Wentzells Lake",
      "West Arichat",
      "West Bay Road",
      "West Bay",
      "West Clifford",
      "West Dover",
      "West Indian Road",
      "West Northfield",
      "West Pennant",
      "West Porters Lake",
      "West Pubnico",
      "West River Station",
      "West St Andrews",
      "Westchester Station",
      "Western Shore",
      "Westmount",
      "Westphal",
      "Westport",
      "Westville",
      "Weymouth",
      "Whites Lake",
      "Whycocomagh",
      "Whynotts Settlement",
      "Wileville",
      "Williamswood",
      "Wilmot Station",
      "Windsor Junction",
      "Windsor",
      "Wittenburg",
      "Wolfville",
      "Wyses Corner",
      "Yarmouth"
    ]
  },
  {
    "name": "Newfoundland and Labrador",
    "key": "NL",
    "cities": [
      "Aguathuna",
      "Anchor Point",
      "Aquaforte",
      "Arnolds Cove",
      "Aspen Cove",
      "Avondale",
      "Badger",
      "Badgers Quay",
      "Baie Verte",
      "Baine Harbour",
      "Barachois Brook",
      "Bartletts Harbour",
      "Bauline",
      "Bay Bulls",
      "Bay De Verde",
      "Bay L'argent",
      "Bay Roberts",
      "Baytona",
      "Beachside",
      "Beaumont",
      "Bell Island Front",
      "Bell Island",
      "Bellburns",
      "Belleoram",
      "Bellevue",
      "Benoits Cove",
      "Benton",
      "Bide Arm",
      "Birchy Bay",
      "Birchy Head",
      "Bird Cove",
      "Bishops Falls",
      "Black Duck Cove",
      "Black Duck Siding",
      "Black Tickle",
      "Blaketown",
      "Bloomfield",
      "Boat Harbour West",
      "Bonavista",
      "Bonne Bay Pond",
      "Bonne Bay",
      "Botwood",
      "Boyds Cove",
      "Branch",
      "Brents Cove",
      "Bridgeport",
      "Brighton",
      "Brigus Junction",
      "Brigus",
      "Broad Cove Bdv",
      "Brookfield",
      "Brownsdale",
      "Buchans Junction",
      "Buchans",
      "Bunyans Cove",
      "Burgeo",
      "Burgoynes Cove",
      "Burin Bay Arm",
      "Burin",
      "Burlington",
      "Burnside",
      "Burnt Islands Blp",
      "Burnt Point Bdv",
      "Calvert",
      "Campbellton",
      "Cannings Cove",
      "Cape Broyle",
      "Cape Freels North",
      "Cape Ray",
      "Cape St George",
      "Caplin Cove Bdv",
      "Cappahayden",
      "Carbonear",
      "Carmanville",
      "Carters Cove",
      "Cartwright",
      "Cartyville",
      "Castors River",
      "Catalina",
      "Cavendish",
      "Chance Cove",
      "Change Islands",
      "Channel-port-aux-basques",
      "Chapel Arm",
      "Chapel Cove",
      "Charleston",
      "Charlottetown Lab",
      "Charlottetown",
      "Churchill Falls",
      "Clarenville",
      "Clarkes Beach",
      "Coachmans Cove",
      "Codroy",
      "Coleys Point South",
      "Colinet",
      "Colliers Riverhead",
      "Come By Chance",
      "Comfort Cove-newstead",
      "Conception Bay South",
      "Conception Harbour",
      "Conche",
      "Conne River",
      "Cooks Harbour",
      "Cormack",
      "Corner Brook",
      "Cottlesville",
      "Cottrells Cove",
      "Cow Head",
      "Coxs Cove",
      "Creston North",
      "Creston",
      "Croque",
      "Cupids",
      "Daniels Harbour",
      "Deadmans Bay",
      "Deep Bay",
      "Deer Lake",
      "Dildo",
      "Dover",
      "Doyles",
      "Duntara",
      "Dunville",
      "Durrell",
      "Eastport",
      "Eddies Cove West",
      "Eddies Cove",
      "Elliston",
      "Embree",
      "Englee",
      "English Harbour East",
      "English Harbour West",
      "Epworth",
      "Fair Haven",
      "Fermeuse",
      "Ferryland",
      "Flatrock",
      "Fleur De Lys",
      "Flowers Cove",
      "Fogo",
      "Forteau",
      "Fortune",
      "Fox Harbour Pb",
      "Francois",
      "Frederickton",
      "Frenchmans Cove Boi",
      "Frenchmans Cove Fb",
      "Freshwater Pb",
      "Gallants",
      "Gambo South",
      "Gambo",
      "Gander Bay South",
      "Gander Bay",
      "Gander",
      "Garden Cove Pb",
      "Garnish",
      "Gaultois",
      "Glenwood",
      "Glovertown South",
      "Glovertown",
      "Gooseberry Cove",
      "Goulds",
      "Grand Bank",
      "Grand Bay East",
      "Grand Beach",
      "Grand Bruit",
      "Grand Falls-windsor",
      "Grand Le Pierre",
      "Grates Cove",
      "Green Island Brook",
      "Green Island Cove",
      "Greens Harbour",
      "Greenspond",
      "Grey River",
      "Hampden",
      "Hants Harbour",
      "Happy Valley-goose Bay",
      "Harbour Breton",
      "Harbour Grace South",
      "Harbour Grace",
      "Harbour Main",
      "Harbour Mille",
      "Harbour Round",
      "Hare Bay Bb",
      "Harrys Harbour",
      "Hawkes Bay",
      "Head Bay D'espoir",
      "Heart's Content",
      "Heart's Delight",
      "Heart's Desire",
      "Heatherton",
      "Hermitage",
      "Herring Neck",
      "Hickmans Harbour",
      "Highlands",
      "Hillgrade",
      "Hillview",
      "Hodges Cove",
      "Holyrood",
      "Hopeall",
      "Hopedale",
      "Horwood",
      "Howley",
      "Indian Bay Bb",
      "Island Harbour",
      "Isle-aux-morts",
      "Islington",
      "Jacksons Arm",
      "Jacksons Cove",
      "Jeffreys",
      "Jerseyside",
      "Jobs Cove",
      "Joe Batts Arm",
      "Keels",
      "Kilbride",
      "Kings Cove",
      "Kings Point",
      "Kippens",
      "Knights Cove",
      "L'anse Au Clair",
      "L'anse Au Loup",
      "La Poile",
      "La Scie",
      "Labrador City",
      "Ladle Cove",
      "Lamaline",
      "Lance Cove",
      "Lark Harbour",
      "Laurenceton",
      "Lawn",
      "Leading Tickles",
      "Lethbridge",
      "Lewins Cove",
      "Lewisporte",
      "Little Bay East",
      "Little Bay Islands",
      "Little Bay Ndb",
      "Little Bay Pb",
      "Little Burnt Bay",
      "Little Catalina",
      "Little Harbour East Pb",
      "Little Hearts Ease",
      "Little St Lawrence",
      "Lodge Bay",
      "Logy Bay",
      "Long Harbour",
      "Loon Bay",
      "Lourdes",
      "Lower Island Cove",
      "Lumsden",
      "Main Brook",
      "Main Point",
      "Makinsons",
      "Makkovik",
      "Marys Harbour",
      "Marystown",
      "Marysvale",
      "Massey Drive",
      "Mccallum",
      "Melrose",
      "Middle Arm Gb",
      "Middle Cove",
      "Miles Cove",
      "Millertown",
      "Milltown",
      "Mings Bight",
      "Mobile",
      "Monkstown",
      "Moretons Harbour",
      "Mount Arlington Heights",
      "Mount Carmel",
      "Mount Moriah",
      "Mount Pearl",
      "Mud Lake",
      "Musgrave Harbour",
      "Musgravetown",
      "Nain",
      "Natuashish",
      "New Chelsea",
      "New Harbour Tb",
      "New Melbourne",
      "New Perlican",
      "Newmans Cove",
      "Newtown",
      "Nippers Harbour",
      "Noels Pond",
      "Normans Cove",
      "Norris Arm Northside",
      "Norris Arm",
      "Norris Point",
      "North Harbour Pb",
      "North Harbour Smb",
      "North Valley",
      "North West Brook",
      "North West River",
      "Northern Bay",
      "Ochre Pit Cove",
      "Old Perlican",
      "Old Shop",
      "Open Hall",
      "Outer Cove",
      "Pacquet",
      "Paradise River",
      "Paradise",
      "Parkers Cove",
      "Parsons Pond",
      "Pasadena",
      "Peterview",
      "Petit Forte",
      "Petty Harbour",
      "Pilleys Island",
      "Placentia",
      "Plate Cove East",
      "Plate Cove West",
      "Plum Point",
      "Point Leamington",
      "Point Of Bay",
      "Pollards Point",
      "Pools Cove",
      "Pools Island",
      "Port Albert",
      "Port Anson",
      "Port Au Choix",
      "Port Au Port",
      "Port Blandford",
      "Port De Grave",
      "Port Hope Simpson",
      "Port Rexton",
      "Port Saunders",
      "Port Union",
      "Portland Creek",
      "Portugal Cove-st Philips",
      "Postville",
      "Pouch Cove",
      "Pound Cove",
      "Princeton",
      "Pynn's Brook",
      "Raleigh",
      "Ramea",
      "Rattling Brook",
      "Red Bay",
      "Red Harbour Pb",
      "Red Head Cove",
      "Reefs Harbour",
      "Reidville",
      "Rencontre East",
      "Renews",
      "Rigolet",
      "River Of Ponds",
      "Riverhead Harbour Grace",
      "Roberts Arm",
      "Robinsons",
      "Rocky Harbour",
      "Roddickton",
      "Rodgers Cove",
      "Rose Blanche",
      "Round Harbour Gb",
      "Rushoon",
      "Sallys Cove",
      "Salmon Cove Bdv",
      "Salvage",
      "Sandringham",
      "Sandy Cove",
      "Seal Cove Fb",
      "Seal Cove Wb",
      "Seldom",
      "Shalloway Cove",
      "Shea Heights",
      "Shearstown",
      "Ship Harbour Pb",
      "Shoe Cove",
      "Snooks Arm",
      "Sops Arm",
      "South Branch",
      "South Brook Gb",
      "South Dildo",
      "South East Bight",
      "South River",
      "Southern Bay",
      "Southern Harbour Pb",
      "Spaniards Bay",
      "Springdale",
      "St Albans",
      "St Andrews",
      "St Anthony East",
      "St Bernards-jacques Fontaine",
      "St Brendans",
      "St Brides",
      "St Chads",
      "St Davids",
      "St Fintans",
      "St Georges",
      "St Josephs Sal",
      "St Judes",
      "St Juliens",
      "St Lawrence",
      "St Lewis",
      "St Lunaire-griquet",
      "St Marys",
      "St Pauls",
      "St Shotts",
      "St Vincents",
      "St. Anthony",
      "St. John's",
      "Stag Harbour",
      "Stephenville Crossing",
      "Stephenville",
      "Stoneville",
      "Summerford",
      "Summerville",
      "Sunnyside",
      "Sweet Bay",
      "Swift Current",
      "Templeman",
      "Terrenceville",
      "Tickle Cove",
      "Tilting",
      "Tizzards Harbour",
      "Torbay",
      "Tors Cove",
      "Traytown",
      "Trepassey",
      "Trinity Bb",
      "Trinity Tb",
      "Triton",
      "Trout River",
      "Turks Cove",
      "Twillingate",
      "Upper Island Cove",
      "Valley Pond",
      "Victoria Cb",
      "Victoria Cove",
      "Wabush",
      "Wareham-centreville",
      "Wesleyville",
      "West Bay Centre",
      "West St Modeste",
      "Western Bay",
      "Westport",
      "Whitbourne",
      "Whiteway",
      "Wild Cove Wb",
      "Williams Harbour",
      "Wiltondale",
      "Wings Point",
      "Winterland",
      "Winterton",
      "Witless Bay",
      "Woodfords",
      "Woodstock",
      "York Harbour"
    ]
  },
  {
    "name": "Northwest Territories",
    "key": "NT",
    "cities": [
      "Aklavik",
      "Behchoko",
      "Colville Lake",
      "Deline",
      "Enterprise",
      "Fort Good Hope",
      "Fort Liard",
      "Fort Mcpherson",
      "Fort Providence",
      "Fort Resolution",
      "Fort Simpson",
      "Fort Smith",
      "Gameti",
      "Hay River",
      "Inuvik",
      "Lutselk'e",
      "Norman Wells",
      "Paulatuk",
      "Sachs Harbour",
      "Trout Lake",
      "Tsiigehtchic",
      "Tuktoyaktuk",
      "Tulita",
      "Ulukhaktok",
      "Wekweti",
      "Whati",
      "Wrigley",
      "Yellowknife"
    ]
  },
  {
    "name": "Prince Edward Island",
    "key": "PE",
    "cities": [
      "Albany",
      "Alberton",
      "Bedeque",
      "Belfast",
      "Belle River",
      "Bloomfield Station",
      "Bonshaw",
      "Borden-carleton",
      "Breadalbane",
      "Cardigan",
      "Central Bedeque",
      "Charlottetown",
      "Coleman",
      "Cornwall",
      "Crapaud",
      "Ellerslie",
      "Elmira",
      "Elmsdale",
      "Freetown",
      "Georgetown",
      "Green Gables",
      "Kensington",
      "Kinkora",
      "Lennox Island",
      "Miminegash",
      "Miscouche",
      "Montague",
      "Morell",
      "Mount Stewart",
      "Murray Harbour",
      "Murray River",
      "New Glasgow",
      "North Rustico",
      "North Wiltshire",
      "O'leary",
      "Pownal",
      "Richmond",
      "Slemon Park",
      "Souris",
      "St-louis",
      "St-peters Bay",
      "Stratford",
      "Summerside",
      "Tignish",
      "Tyne Valley",
      "Vernon Bridge",
      "Victoria",
      "Wellington Station",
      "Winsloe",
      "York"
    ]
  },
  {
    "name": "Manitoba",
    "key": "MB",
    "cities": [
      "Alexander",
      "Alonsa",
      "Altamont",
      "Altona",
      "Amaranth",
      "Angusville",
      "Anola",
      "Arborg",
      "Arden",
      "Argyle",
      "Arnaud",
      "Arnes",
      "Arrow River",
      "Ashern",
      "Ashville",
      "Aubigny",
      "Austin",
      "Bagot",
      "Baldur",
      "Balmoral",
      "Barrows",
      "Beaconia",
      "Beausejour",
      "Belair",
      "Belleview",
      "Belmont",
      "Benito",
      "Berens River",
      "Bethany",
      "Beulah",
      "Binscarth",
      "Birch River",
      "Birnie",
      "Birtle",
      "Bissett",
      "Bloodvein",
      "Blumenort",
      "Boggy Creek",
      "Boissevain",
      "Bowsman",
      "Bradwardine",
      "Brandon",
      "Broad Valley",
      "Brochet",
      "Brookdale",
      "Brunkild",
      "Bruxelles",
      "Buffalo Point",
      "Camp Morton",
      "Camperville",
      "Carberry",
      "Cardale",
      "Carlowrie",
      "Carman",
      "Carroll",
      "Cartier",
      "Cartwright",
      "Cayer",
      "Channing",
      "Chatfield",
      "Churchill",
      "Clandeboye",
      "Clanwilliam",
      "Clearwater",
      "Cormorant",
      "Coulter",
      "Cowan",
      "Cranberry Portage",
      "Crandall",
      "Crane River",
      "Cromer",
      "Cross Lake",
      "Crystal City",
      "Cypress River",
      "Dacotah",
      "Dakota Tipi",
      "Dallas",
      "Darlingford",
      "Dauphin",
      "Decker",
      "Deleau",
      "Deloraine",
      "Domain",
      "Dominion City",
      "Douglas",
      "Dropmore",
      "Duck Bay",
      "Dufresne",
      "Dufrost",
      "Dugald",
      "Dunrea",
      "Durban",
      "East Braintree",
      "East Selkirk",
      "East St Paul",
      "Easterville",
      "Ebb And Flow",
      "Eddystone",
      "Eden",
      "Edwin",
      "Elgin",
      "Elie",
      "Elkhorn",
      "Elm Creek",
      "Elma",
      "Elphinstone",
      "Emerson",
      "Erickson",
      "Eriksdale",
      "Ethelbert",
      "Fairfax",
      "Fairford",
      "Falcon Beach",
      "Fannystelle",
      "Faulkner",
      "Fisher Branch",
      "Flin Flon",
      "Fork River",
      "Forrest Station",
      "Fort Alexander",
      "Foxwarren",
      "Franklin",
      "Fraserwood",
      "Gardenton",
      "Garland",
      "Garson",
      "Gilbert Plains",
      "Gillam",
      "Gimli",
      "Ginew",
      "Giroux",
      "Gladstone",
      "Glenboro",
      "Glenella",
      "Glenlea",
      "Glenora",
      "Gods Lake Narrows",
      "Gods River",
      "Goodlands",
      "Grahamdale",
      "Grand Marais",
      "Grand Rapids",
      "Grande Pointe",
      "Grandview",
      "Graysville",
      "Great Falls",
      "Green Ridge",
      "Gretna",
      "Griswold",
      "Grosse Isle",
      "Grunthal",
      "Gunton",
      "Gypsumville",
      "Hadashville",
      "Halbstadt",
      "Hamiota",
      "Harding",
      "Hargrave",
      "Hartney",
      "Haywood",
      "Hazelridge",
      "Headingley",
      "High Bluff",
      "Hilbre",
      "Hodgson",
      "Holland",
      "Holmfield",
      "Homewood",
      "Horndean",
      "Howden",
      "Ile Des Chenes",
      "Ilford",
      "Inglis",
      "Inwood",
      "Isabella",
      "Island Lake",
      "Justice",
      "Kelwood",
      "Kenton",
      "Kenville",
      "Killarney",
      "Kinosota",
      "Kirkella",
      "Kleefeld",
      "Kola",
      "Komarno",
      "Koostatak",
      "La Barriere",
      "La Broquerie",
      "La Riviere",
      "La Salle",
      "Lac Brochet",
      "Lac Du Bonnet",
      "Lake Audy",
      "Lake Francis",
      "Lake Manitoba First Nation",
      "Lakeland",
      "Landmark",
      "Langruth",
      "Lauder",
      "Laurier",
      "Lavenham",
      "Leaf Rapids",
      "Lenore",
      "Letellier",
      "Libau",
      "Little Bullhead",
      "Little Grand Rapids",
      "Lockport",
      "Lorette",
      "Lowe Farm",
      "Lundar",
      "Lyleton",
      "Lynn Lake",
      "Macdonald",
      "Macgregor",
      "Mafeking",
      "Makinak",
      "Malonton",
      "Manigotagan",
      "Manitou",
      "Manson",
      "Marchand",
      "Margaret",
      "Mariapolis",
      "Marius",
      "Marquette",
      "Mather",
      "Matheson Island",
      "Matlock",
      "Mcauley",
      "Mccreary",
      "Meadow Portage",
      "Medora",
      "Meleb",
      "Melita",
      "Menisino",
      "Menzie",
      "Miami",
      "Middlebro",
      "Miniota",
      "Minitonas",
      "Minnedosa",
      "Minto",
      "Mitchell",
      "Moose Lake",
      "Moosehorn",
      "Morden",
      "Morris",
      "Mountain Road",
      "Mulvihill",
      "Napinka",
      "Narcisse",
      "Neepawa",
      "Negginan",
      "Nelson House",
      "Nesbitt",
      "New Bothwell",
      "Newdale",
      "Newton Siding",
      "Ninette",
      "Ninga",
      "Niverville",
      "Norway House",
      "Notre Dame De Lourdes",
      "O'hanly",
      "Oak Bluff",
      "Oak Lake",
      "Oak Point",
      "Oak River",
      "Oakbank",
      "Oakburn",
      "Oakview",
      "Oakville",
      "Ochre River",
      "Olha",
      "Onanole",
      "Opaskwayak",
      "Otterburne",
      "Overstoneville",
      "Oxford House",
      "Pansy",
      "Pauingassi",
      "Peguis",
      "Pelican Rapids",
      "Petersfield",
      "Pierson",
      "Pikwitonei",
      "Pilot Mound",
      "Pinawa",
      "Pine Falls",
      "Pine River",
      "Piney",
      "Pipestone",
      "Plum Coulee",
      "Plumas",
      "Pointe Du Bois",
      "Polonia",
      "Poplar Point",
      "Poplarfield",
      "Portage La Prairie",
      "Powerview",
      "Pratt",
      "Princess Harbour",
      "Pukatawagan",
      "Randolph",
      "Rapid City",
      "Rathwell",
      "Red Sucker Lake",
      "Reinfeld",
      "Rennie",
      "Renwer",
      "Reston",
      "Richer",
      "Ridgeville",
      "Riding Mountain",
      "River Hills",
      "Rivers",
      "Riverton",
      "Roblin",
      "Rock Ridge",
      "Roland",
      "Rorketon",
      "Rosa",
      "Roseau River",
      "Roseisle",
      "Rosenfeld",
      "Rosenort",
      "Rossburn",
      "Rossendale",
      "Rosser",
      "Russell",
      "San Clara",
      "Sandilands",
      "Sandy Hook",
      "Sandy Lake",
      "Sanford",
      "Sarto",
      "Scanterbury",
      "Schanzenfeld",
      "Seddons Corner",
      "Selkirk",
      "Seven Sisters Falls",
      "Shamattawa",
      "Shellmouth",
      "Sherridon",
      "Shilo",
      "Shoal Lake",
      "Shortdale",
      "Sidney",
      "Sifton",
      "Silver Ridge",
      "Silver",
      "Sinclair",
      "Skownan",
      "Snow Lake",
      "Snowflake",
      "Solsgirth",
      "Somerset",
      "Souris",
      "South Indian Lake",
      "South Junction",
      "Southport",
      "Sperling",
      "Split Lake",
      "Sprague",
      "Springfield",
      "Springstein",
      "St Adolphe",
      "St Alphonse",
      "St Ambroise",
      "St Andrews",
      "St Claude",
      "St Clements",
      "St Eustache",
      "St Francois Xavier",
      "St Germain South",
      "St Jean Baptiste",
      "St Joseph",
      "St Laurent",
      "St Lazare",
      "St Leon",
      "St Malo",
      "St Marks",
      "St Martin",
      "St Pierre Jolys",
      "St Theresa Point",
      "St. Georges",
      "Starbuck",
      "Ste Agathe",
      "Ste Anne",
      "Ste Rose Du Lac",
      "Stead",
      "Steep Rock",
      "Steinbach",
      "Stephenfield",
      "Stevenson Island",
      "Stockton",
      "Stonewall",
      "Stony Mountain",
      "Strathclair",
      "Stuartburn",
      "Sundown",
      "Swan Lake",
      "Swan River",
      "Tadoule Lake",
      "Teulon",
      "The Pas",
      "Thicket Portage",
      "Thompson",
      "Thornhill",
      "Tilston",
      "Tolstoi",
      "Tourond",
      "Toutes Aides",
      "Traverse Bay",
      "Treherne",
      "Tyndall",
      "Valley River",
      "Vassar",
      "Vermette",
      "Victoria Beach",
      "Virden",
      "Vista",
      "Vita",
      "Vogar",
      "Wabowden",
      "Waldersee",
      "Wanipigow",
      "Wanless",
      "Warren",
      "Wasagamack",
      "Wasagaming",
      "Waskada",
      "Waterhen",
      "Wawanesa",
      "Waywayseecappo",
      "Wellwood",
      "West St Paul",
      "Westbourne",
      "Whitemouth",
      "Whiteshell",
      "Winkler",
      "Winnipeg Beach",
      "Winnipeg",
      "Winnipegosis",
      "Woodlands",
      "Woodmore",
      "Woodridge",
      "Woodside",
      "York Landing",
      "Zhoda"
    ]
  },
  {
    "name": "Nunavut",
    "key": "NU",
    "cities": [
      "Arctic Bay",
      "Arviat",
      "Baker Lake",
      "Bay Chimo",
      "Cambridge Bay",
      "Cape Dorset",
      "Chesterfield Inlet",
      "Clyde River",
      "Coral Harbour",
      "Eureka",
      "Gjoa Haven",
      "Grise Fiord",
      "Hall Beach",
      "Igloolik",
      "Iqaluit",
      "Kimmirut",
      "Kugaaruk",
      "Kugluktuk",
      "Pangnirtung",
      "Pond Inlet",
      "Qikiqtarjuaq",
      "Rankin Inlet",
      "Repulse Bay",
      "Resolute",
      "Sanikiluaq",
      "Taloyoak",
      "Whale Cove"
    ]
  },
  {
    "name": "Yukon",
    "key": "YT",
    "cities": [
      "Beaver Creek",
      "Burwash Landing",
      "Carcross",
      "Carmacks",
      "Dawson",
      "Destruction Bay",
      "Elsa",
      "Faro",
      "Haines Junction",
      "Marsh Lake",
      "Mayo",
      "Old Crow",
      "Pelly Crossing",
      "Ross River",
      "Tagish",
      "Teslin",
      "Watson Lake",
      "Whitehorse"
    ]
  }
]
