import {StyleSheet, View, Text, Platform, ActivityIndicator, Dimensions} from "react-native";
import {useEffect, useState} from "react";
import Icon from "@expo/vector-icons/MaterialIcons";
import Shadow from "../Files/Custom UI/Shadow";
import {Themes} from "../Files/Constants/ThemeColours";
import CustomLink from "../Files/Custom UI/CustomLink";
import {useTranslation} from "react-i18next";
import {useAuthentication} from "../Files/Contexts/Authentication";
import {useLocalSearchParams, useRouter} from "expo-router";
import {errorMessageFromServerError} from "../Files/Helpers/Helpers";

// For mobile support:
//
// https://github.com/Bounceapp/iframe/blob/main/src/Iframe.tsx
// https://developer.moneris.com/livedemo/checkout/client_side/guide/dotnet
// https://docs.expo.dev/versions/latest/sdk/webview/
// https://github.com/react-native-webview/react-native-webview

export default function Payment(){
  
  // Instance Variables
  
  const {t} = useTranslation();
  const {api, colorScheme, isLoggedIn, themedStyle, toastMessage} = useAuthentication();
  
  const params = useLocalSearchParams();
  const router = useRouter();
  const {signedId} = params;
  const styles = themedStyle(allStyles);
  
  const paymentStatuses = {error:'error', complete:'complete', cancelled:'cancelled', purchasing:'purchasing', addCreditCard:'addCreditCard'};
  
  const [width, setWidth] = useState(Dimensions.get('window').width);
  const [height, setHeight] = useState(Dimensions.get('window').height);
  const [message, setMessage] = useState('');
  const [myCheckout, setMyCheckout] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(paymentStatuses.addCreditCard);
  const [signedIdValid, setSignedIdValid] = useState(true);
  
  // Effects
  
  useEffect(() => {
    const script = document.createElement("script");
    console.log('process.env.PRODUCTION', process.env.PRODUCTION)
    
    if(process.env.PRODUCTION === 'TRUE'){
      script.src = 'https://gateway.moneris.com/chktv2/js/chkt_v2.00.js';
    }
    else{
      script.src = 'https://gatewayt.moneris.com/chktv2/js/chkt_v2.00.js';
    }
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  
  useEffect(() => {
    setTimeout(() => {
      setMyCheckout(new monerisCheckout());
    }, 2000);
  }, [setMyCheckout]);
  
  useEffect(() => {
    if(signedId && signedId.length > 0){
      api.monerisPreloadRequest(signedId).then((newResult) => {
        let newAppointment = newResult.data;
        setAppointment(newAppointment);
        
        if(newAppointment && newAppointment.isPaid){
          setPaymentStatus(paymentStatuses.complete);
        }
      }, (newError) => {
        setSignedIdValid(false);
        setPaymentStatus(paymentStatuses.error);
        toastMessage(t('PAYMENT_ERROR', {aError:errorMessageFromServerError(newError)}));
      });
    }
  }, [signedId, setAppointment, setSignedIdValid]);
  
  useEffect(() => {
    if(signedId && signedId.length > 0){
      if(!signedIdValid){
        setMessage('PAYMENT_SIGNED_ID_ERROR');
      }
      else if(appointment && appointment.isPaid){
        setMessage('PAYMENT_COMPLETED');
      }
      else if(paymentStatus === paymentStatuses.cancelled){
        setMessage('PAYMENT_CANCELLED');
      }
      else if(paymentStatus === paymentStatuses.error){
        setMessage('PAYMENT_FAILED');
      }
    }
    else{
      setMessage('PAYMENT_SIGNED_ID_ERROR');
    }
  }, [signedId, setMessage, appointment, paymentStatus, signedIdValid]);
  
  const paymentCompleted = (aResponse) => {
    setPaymentStatus(paymentStatuses.complete);
    api.monerisReceiptRequest(signedId, appointment.monerisTicket).then((newResult) => {
      setAppointment(newResult.data);
  
      if(isLoggedIn()){
        router.push({pathname:'/patients'});
      }
    }, (newError) => {
      setSignedIdValid(false);
      setPaymentStatus(paymentStatuses.error);
      toastMessage(t('PAYMENT_ERROR', {aError:errorMessageFromServerError(newError)}));
    });
  }
  
  const myPageLoad = (aResponse) => {
  
  }
  
  const myCancelTransaction = (aResponse) => {
    setPaymentStatus(paymentStatuses.cancelled);
  }
  
  const myErrorEvent = (aResponse) => {
    setPaymentStatus(paymentStatuses.error);
  }
  
  const myPaymentReceipt = (aResponse) => {
    paymentCompleted(aResponse);
  }
  
  const myPaymentComplete = (aResponse) => {
    paymentCompleted(aResponse);
  }
  
  useEffect(() => {
    if(myCheckout && appointment && !appointment.isPaid && paymentStatus === paymentStatuses.addCreditCard){
      setPaymentStatus(paymentStatuses.purchasing);

      if(process.env.PRODUCTION === 'TRUE') {
        myCheckout.setMode("prod");
      }
      else{
        myCheckout.setMode("qa");
      }
      myCheckout.setCheckoutDiv("monerisCheckout");
      myCheckout.setCallback("page_loaded", myPageLoad);
      myCheckout.setCallback("cancel_transaction", myCancelTransaction);
      myCheckout.setCallback("error_event", myErrorEvent);
      myCheckout.setCallback("payment_receipt", myPaymentReceipt);
      myCheckout.setCallback("payment_complete", myPaymentComplete);
      myCheckout.startCheckout(appointment.monerisTicket);
    }
  }, [myCheckout, appointment, paymentStatus]);
  
  // Render
  
  return (
    <View style={styles.container}
          onLayout={() => {
            setWidth(Dimensions.get('window').width);
            setHeight(Dimensions.get('window').height);
          }}>
      {message && message.length > 0 ?
        <Shadow style={styles.shadow}>
          <View style={styles.messageContainer}>
            <Text style={styles.text}>
              {t(message)}
            </Text>
  
            {paymentStatus === paymentStatuses.complete ?
              <CustomLink style={styles.link}
                          href="https://www.labtician.com/prgf-information/"
                          label={t('PRGF')}
                          colour={Themes.light.primary}
                          external={true}
              />
              :
              signedIdValid === false || !(signedId && signedId.length > 0) ?
                <CustomLink style={styles.link}
                            href="mailto:ldockray@labtician.com"
                            label={t('EMAIL_ADMIN')}
                            colour={Themes.light.primary}
                            external={true}
                />
                :
                null
            }
            
            <Icon name={(appointment && appointment.isPaid) ? 'check-circle-outline' : 'error-outline'}
                  size={100}
                  style={styles.icon}
                  color={(appointment && appointment.isPaid) ? Themes[colorScheme].success : Themes[colorScheme].failure}
            />
          </View>
        </Shadow>
        :
        <View>
          {!myCheckout ?
            <ActivityIndicator size="large"
                               color={Themes[colorScheme].primary}
            />
            :
            (Platform.OS === 'web' ?
                <div style={{top:Themes.styles.navBarHeight, width:width, height:(height - Themes.styles.navBarHeight - Themes.styles.footerHeight)}}
                     id="outerDiv">
                  <div id="monerisCheckout"></div>
                </div>
                :
                null
            )
          }
        </View>
      }
    </View>
  )
}

const allStyles = StyleSheet.create({
  icon:{
    marginLeft:'auto',
    marginRight:'auto',
    marginBottom:10
  },
  link:{
    color:Themes.light.primary,
    width:'100%',
    marginTop:10,
    textAlign:'center',
    marginBottom:10
  },
  text:{
    padding:10,
    fontSize:19,
    textAlign:'center',
    fontWeight:'bold'
  },
  shadow:{
    width:500,
    padding:20
  },
  container:{
    flex:1,
    alignItems:'center',
    justifyContent:'center'
  },
  messageContainer:{
    padding:10,
    justifyContent:'space-between'
  }
});
