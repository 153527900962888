import { Text, View, StyleSheet, Pressable, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import {useCallback, useState} from "react";
import {Themes} from "../../Constants/ThemeColours";

const ColouredButton = ({style, title, colour, onPress, textColour, pressedColour, highlightedColour, accessibilityLabel,
                          height = 40, disabled = false}) => {
  
  // Instance Variables
  
  const [hovered, setHovered] = useState(false);
  
  // Methods
  
  
  
  // Render
  
  return (
    <Pressable style={({pressed}) => [
      styles.button,
      {height:height},
      style,
      {backgroundColor:pressed ? pressedColour : (hovered ? highlightedColour : colour)}
    ]}
               onPress={(aEvent) => {
                 aEvent.preventDefault();
                 aEvent.stopPropagation();
                 onPress(aEvent);
               }}
               disabled={disabled}
               onHoverIn={(aEvent) => setHovered(true)}
               onHoverOut={(aEvent) => setHovered(false)}
               accessibilityLabel={accessibilityLabel}>
      <View>
        <Text style={[styles.text, {color:textColour}]}>
          {title}
        </Text>
      </View>
    </Pressable>
  );
}

export default ColouredButton

const styles = StyleSheet.create({
  button: {
    maxHeight:50,
    paddingLeft:13,
    borderRadius:Themes.styles.borderRadius,
    paddingRight:13,
    justifyContent:'center'
  },
  text:{
    color:Themes.light.white,
    fontSize:18,
    textAlign:'center',
    fontWeight:'bold'
  }
});
