// Constants

// https://reactnavigation.org/docs/themes/

export const Themes = {
  // TODO: Uncomment when theming is complete.
  // dark:{
  //   text:'#000000',
  //   black:'white',
  //   white:'black',
  //   border:'#FFFFFF',
  //   shadow:'#FFFFFF33',
  //   failure:'#FF0000',
  //   primary:'#770077',
  //   success:'#4AA90C',
  //   warning:'#FFCC00',
  //   lightGray:'#111111',
  //   secondary:'#770077',
  //   background:'#000000',
  //   mediumGray:'#BBBBBB'
  // },
  dark:{
    text:'#FFFFFF',
    black:'black',
    white:'white',
    border:'#000000',
    shadow:'#00000033',
    failure:'#FF0000',
    primary:'#EB5CFF',
    success:'#4AA90C',
    warning:'#FFCC00',
    secondary:'#770077',
    lightGray:'#EEEEEE',
    background:'#FFFFFF',
    mediumGray:'#444444'
  },
  light:{
    text:'#FFFFFF',
    black:'black',
    white:'white',
    border:'#000000',
    shadow:'#00000033',
    failure:'#FF0000',
    primary:'#EB5CFF',
    success:'#4AA90C',
    warning:'#FFCC00',
    secondary:'#770077',
    lightGray:'#EEEEEE',
    background:'#FFFFFF',
    mediumGray:'#444444'
  },
  styles:{
    paddingTop:10,
    paddingLeft:10,
    borderRadius:7,
    footerHeight:30,
    navBarHeight:64,
    paddingRight:10,
    paddingBottom:10
  }
};
