import fr from 'date-fns/locale/fr';
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import frenchLocales from "./Files/Assets/Locales/frenchLocales.json";
import englishLocales from "./Files/Assets/Locales/englishLocales.json";
import {getData, localeKey} from "./Files/Helpers/LocalStorageHelpers";
import {registerLocale, setDefaultLocale} from "react-datepicker";

registerLocale('fr', fr);

getData(localeKey).then((aLocale) => {
  loadTranslationsWithLocale(aLocale);
}, (newError) => {
  loadTranslationsWithLocale();
});

const loadTranslationsWithLocale = (aLocale = 'en') => {
  i18n
  .use(initReactI18next)
  .init({
    resources:{
      en:{translation:englishLocales},
      fr:{translation:frenchLocales}
    },
    lng:aLocale,
    fallbackLng:"en",
    interpolation:{escapeValue:false}
  });
  setDefaultLocale(i18n.language);
}
