import LabeledInput from "./LabeledInput";

const LabeledPhoneNumber = ({error, label, change, submit, warning, inputRef, placeholder, keyboardType, placeholderIcon,
                              addButtonClicked, accessibilityLabel, placeholderIconColour, text = '',
                              width = '100%', disabled = false, marginTop = 0, fontWeight= 'bold',
                              marginLeft = 0, marginBottom = 20, inputMarginTop = 0}) => {
  
  // Methods
  
  const formatPhoneNumber = (aText) => {
    // TODO: This only works for Canadian numbers currently. Rework to support international numbers later. Visit:
    //       https://catamphetamine.gitlab.io/libphonenumber-js/
    
    let returnValue = '';

    if(aText) {
      const phoneNumber = aText.replace(/\D/g, '').substring(0, 10);

      if (phoneNumber.length > 6) {
        returnValue = '(' + phoneNumber.substring(0, 3).toString() + ') ' + phoneNumber.substring(3, 6).toString() + ' - ' + phoneNumber.substring(6, 10).toString();
      } else if (phoneNumber.length > 3) {
        returnValue = '(' + phoneNumber.substring(0, 3).toString() + ') ' + phoneNumber.substring(3, 6).toString();
      } else if (phoneNumber.length > 0) {
        returnValue = '(' + phoneNumber.substring(0, 3).toString();
      }
    }
    return returnValue;
  };
  
  // Render
  
  return (
    <LabeledInput text={formatPhoneNumber(text)}
                  error={error}
                  label={label}
                  width={width}
                  change={(aText) => change(aText.replace(/\D/g, '').substring(0, 10))}
                  submit={submit}
                  warning={warning}
                  disabled={disabled}
                  inputRef={inputRef}
                  marginTop={marginTop}
                  validator={formatPhoneNumber}
                  fontWeight={fontWeight}
                  marginLeft={marginLeft}
                  placeholder={placeholder}
                  keyboardType={keyboardType}
                  marginBottom={marginBottom}
                  inputMarginTop={inputMarginTop}
                  placeholderIcon={placeholderIcon}
                  addButtonClicked={addButtonClicked}
                  accessibilityLabel={accessibilityLabel}
                  placeholderIconColour={placeholderIconColour}
    />
  );
}

export default LabeledPhoneNumber
