import {Pressable, View, ActivityIndicator} from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import CustomMenu from "../CustomMenu";

const MenuButton = ({style, menuItemsArray, colour = '#00FF00', loading = false}) => {
  
  // Render
  
  return (
    <View style={style}>
      {loading ?
        <ActivityIndicator size="small"
                           color={colour}
        />
        :
        <CustomMenu trigger={(triggerProps) => {
          return (
            <Pressable {...triggerProps}>
              <Icon name="more-vert"
                    size={20}
                    color={colour}
              />
            </Pressable>
          );
        }}
                    menuItemsArray={menuItemsArray}
        />
      }
    </View>
  );
}

export default MenuButton
